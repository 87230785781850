import { parseCategories, parseDishes } from 'app/utils/basketSchema';
import { getHiddenElements } from 'app/utils/url';
import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';


/* ЧЕКЕРЫ */
export const isPreassembledBasket = (props) => {
    const { location } = props;
    return (Boolean(parseDishes(location)) || Boolean(parseCategories(location)));
};

export const isLoadingBasket = (props) => {
    const {
        basketQuery,
        menuDishesQuery,
        abTestDataContext: { loading: abTestLoading },
        basketFilledStatus: { isBasketFilled },
    } = props;

    const { loading: basketDishesLoading, cart, error } = basketQuery ?? { loading: true };
    const { loading: menuDishesLoading } = menuDishesQuery ?? { loading: true };
    const isBasketFillingLoading = !isBasketFilled;

    const hasBasketNoData = !cart && !error;

    return [
        menuDishesLoading,
        (basketDishesLoading || hasBasketNoData),
        abTestLoading,
        isBasketFillingLoading,
    ].includes(true);
};

export const isBasketEmpty = (props) => Boolean(!props?.basketQuery?.cart?.sections?.length);

export const isControlLargeDishBasket = (props) => {
    const { location } = props;
    return (Boolean(parseDishes(location)) || Boolean(parseCategories(location)));
};

export const isTrialBasket = (props) => {
    const is5x2Trial = getTrialScenarioFromUrl() === 's_5x2';
    return Boolean(isControlLargeDishBasket(props) && is5x2Trial);
};

export const isBasketEditable = () => {
    const hiddenElements = getHiddenElements();
    return !hiddenElements.edit_basket;
};
