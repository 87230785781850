import React from 'react';
import PropTypes from 'prop-types';

import colors from 'global/uikit/uiTokens/colorUiTokens';
import StarIcon from './star-icon.component.svg';

import './badge.scss';


export default function Badge(props) {
    const {
        hit,
        discountValue,
        backgroundColor,
        color,
    } = props;

    const rootStyle = { backgroundColor };
    const valueStyle = {
        color,
        stroke: color,
        fill: color,
    };
    const valueMinusStyle = {
        backgroundColor: color,
    };

    return (
        <div style={rootStyle} styleName="badge__root">
            {hit
                ? (
                    <p style={valueStyle} styleName="badge__value">
                        <span styleName="badge__value-star-container">
                            <StarIcon />
                        </span>
                        Hit
                    </p>
                )
                : (
                    <p style={valueStyle} styleName="badge__value">
                        <span style={valueMinusStyle} styleName="badge__value-minus" />
                        {discountValue}
                        %
                    </p>
                )
            }
        </div>
    );
}

Badge.propTypes = {
    hit: PropTypes.bool,
    discountValue: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
};

Badge.defaultProps = {
    hit: false,
    discountValue: null,
    backgroundColor: colors.hering,
    color: colors.cuttlefish,
};
