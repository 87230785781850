import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { MobilePeriodSelectContainer } from 'app/components/Select';
import { selectedPeriodVar } from 'app/apollo/reaction';
import { delay } from 'app/utils/common';
import css from './sets5dishes.module.scss';
import { Sets5DishesCard } from './Sets5DishesCard';

// import TooltopIcon from './img/info.component.svg';
import { TCardKey } from '../sets.record';
import { sliderCardContentMapV2 } from './V2CardsContent.record';
import { Sets5DishesCardStub } from './Sets5DishesCardStub';

interface IABSets5Dishes {
    isMenuEmpty: boolean
    cardsChain: TCardKey[]
    // eslint-disable-next-line no-unused-vars
    handleClickToSliderCard: (I: MouseEvent, II: TCardKey) => void
}

const ANIMATION_DURATION = 700;

const ABSets5Dishes: FC<IABSets5Dishes> = ({
    isMenuEmpty,
    cardsChain,
    handleClickToSliderCard,
}: IABSets5Dishes) => {
    const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [hasNoDishes, setHasNoDishes] = useState(true);

    const listClasses = classNames({
        [css.gridList]: isMenuEmpty,
        [css.sliderList]: !isMenuEmpty,
        [css.listContainerForTwoSets]: cardsChain.length <= 2,
        [css.listContainerForMoreThreeSets]: cardsChain.length >= 3,
    });

    const uddCardClasses = classNames({
        [css.stubContainer]: !isMenuEmpty,
    });

    return (
        <>
            <div
                className={css.root}
                aria-label="ABSets5DishesRoot"
            >
                <div className={css.sliderContainer}>
                    <div className={listClasses}>
                        {cardsChain.map((cardKey) => {
                            const card = sliderCardContentMapV2.get(cardKey);
                            return (
                                <Sets5DishesCard
                                    mode="default"
                                    key={cardKey}
                                    cardKey={cardKey}
                                    // @ts-ignore
                                    onClick={(e: MouseEvent) => {
                                        setIsButtonDisabled(true);
                                        delay(ANIMATION_DURATION).then(() => {
                                            setIsButtonDisabled(false);
                                        });
                                        handleClickToSliderCard(e, cardKey);
                                    }}
                                    setHasNoDishes={setHasNoDishes}
                                    disabled={isButtonDisabled}
                                    {...card}
                                />
                            );
                        })}
                        {/*  */}
                        <div className={uddCardClasses}>
                            {!hasNoDishes && (
                                <Sets5DishesCardStub
                                    isDynamicPrice={false}
                                    setType=""
                                    disabled={isButtonDisabled}
                                    onClick={() => {
                                        setIsButtonDisabled(true);
                                        delay(ANIMATION_DURATION).then(() => {
                                            setIsButtonDisabled(false);
                                        });
                                        setIsDateSelectOpen(true);
                                    }}
                                />
                            )}
                            {/* Костыль для safari, т.к. этот браузер по-другому работает с правым отступом при overflow scroll */}
                            {!isMenuEmpty && <div className={css.safariStub} />}
                        </div>
                    </div>
                </div>
            </div>
            <MobilePeriodSelectContainer
                isSelectOpened={isDateSelectOpen}
                onClickPeriod={(nextSelectedPeriod: string) => {
                    selectedPeriodVar(nextSelectedPeriod);
                }}
                onClickClose={() => setIsDateSelectOpen(false)}
            />
        </>
    );
};

export default React.memo(ABSets5Dishes);
