export const dishes = [
    '4134',
    '4132',
    '4130',
    '4133',
    '4619',
    '3093',
];

export const periods = [
    '2024-08-26',
    '2024-09-02',
];
