/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UIHeading } from 'app/components/ui';
import { SPAContext } from 'app/containers/SPAProvider';
import { LocaleContext } from 'app/containers/LocaleProvider';
import Link, { LINK_TYPE_NAV, LINK_TYPE_SIMPLE } from 'app/components/Link';

import Star from './icons/star.component.svg';
import Percent from './icons/percent.component.svg';
import Tree from './icons/tree.component.svg';

import './mobile-site-nav-ordinary-links.scss';


const Badge = ({ type }) => {
    switch (type) {
        case 'percent': return (
            <span styleName="mobile-nav__badge-container persent">
                <Percent />
            </span>
        );
        case 'star': return (
            <span styleName="mobile-nav__badge-container star">
                <Star />
            </span>
        );
        case 'tree': return (
            <span styleName="mobile-nav__badge-container tree">
                <Tree />
            </span>
        );

        default: return null;
    }
};
Badge.propTypes = {
    type: PropTypes.oneOf(['', 'percent', 'star', 'tree']).isRequired,
};

const OrdinaryLinkContent = (props) => {
    const {
        children,
        badge,
        Component,
    } = props;

    // const contentContainer = classNames({
    //     'mobile-nav__ordinary-link__content-container': true,
    //     'with-badge': Boolean(badge),
    // });

    return (
        <div styleName="burgerMenuV2LinkItem">
            {children}
        </div>


    // <div styleName={contentContainer}>
    //     <UIHeading level="4.1" inheritColors>
    //         <Badge type={badge} />
    //         {Component
    //             ? (
    //                 <Component>
    //                     {children}
    //                 </Component>
    //             )
    //             : children}
    //     </UIHeading>
    // </div>
    );
};
OrdinaryLinkContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    Component: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    badge: PropTypes.string.isRequired,
};
OrdinaryLinkContent.defaultProps = {
    Component: null,
};


export const OrdinaryLink = ({
    type,
    href,
    badge,
    handler,
    linkAttrs,
    titleTrKey,
    TitleComponent,
    trackId,
    title: defaultTitle,
    inconspicuous,
}) => {
    const spaContext = useContext(SPAContext);
    const { translate } = useContext(LocaleContext);

    const title = titleTrKey ? translate(titleTrKey) : defaultTitle;

    const linkClasses = classNames({
        'mobile-nav__ordinary-link': true,
        [type]: true,
        inconspicuous,
    });

    if (type === 'button') {
        return (
            <button
                styleName={linkClasses}
                onClick={handler}
                type="button"
                aria-label={title}
                data-track-id={trackId}
            >
                <OrdinaryLinkContent badge={badge} Component={TitleComponent}>
                    {title}
                </OrdinaryLinkContent>
            </button>
        );
    }

    const linkTypeFromConfig = type === 'navLink' ? LINK_TYPE_NAV : LINK_TYPE_SIMPLE;
    const linkType = spaContext.mode === 'off'
        ? LINK_TYPE_SIMPLE
        : linkTypeFromConfig;

    const origin = process.env.ORIGIN;

    const isUrlWithHost = !href.startsWith('/');
    const hrefWithOrigin = isUrlWithHost ? href : `${origin}${href}`;

    return (
        <Link
            type={linkType}
            href={hrefWithOrigin}
            styleName={linkClasses}
            onClick={handler}
            data-track-id={trackId}
            {...linkAttrs}
        >
            <OrdinaryLinkContent badge={badge} Component={TitleComponent}>
                {title}
            </OrdinaryLinkContent>
        </Link>
    );
};

OrdinaryLink.propTypes = {
    type: PropTypes.oneOf(['button', 'link', 'navLink']),
    href: PropTypes.string,
    badge: PropTypes.string,
    handler: PropTypes.func,
    linkAttrs: PropTypes.shape({}),
    titleTrKey: PropTypes.string,
    TitleComponent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    trackId: PropTypes.string,
    title: PropTypes.string,
    inconspicuous: PropTypes.bool,
};

OrdinaryLink.defaultProps = {
    type: 'link',
    href: '',
    badge: '',
    handler: () => {},
    linkAttrs: null,
    titleTrKey: '',
    TitleComponent: null,
    trackId: '',
    title: null,
    inconspicuous: false,
};
