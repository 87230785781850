/* eslint-disable camelcase */
import { normalizeDeliveryIntervalsTime } from './selivery.utils';

export const deliveryPolicies = {
    normalizedTimeInterval: {
        read(_, { readField }) {
            const time_from = readField('time_from');
            const time_to = readField('time_to');
            const normalizedTime = normalizeDeliveryIntervalsTime(time_from, time_to);
            return normalizedTime;
        },
    },
};
