import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './heading.scss';


function Heading(props) {
    const {
        level,
        children,
        inheritColors,
        className,
    } = props;

    const normalizedLevel = level.replace(/\./gi, '_');

    const classes = classNames({
        'ui-heading': true,
        [`ui-heading--level-${normalizedLevel}`]: level !== undefined,
        'inherit-colors': inheritColors,
    });

    return (
        <p styleName={classes} className={className}>
            {children}
        </p>
    );
}

/**
 * @note
 */

const adaptUIKitVersion = (props) => {
    if (!props.version) {
        return props;
    }
    return {
        ...props,
        level: `${props.level}-${props.version}`,
    };
};

const HeadingAdapter = (props) => {
    const adapteProps = useMemo(() => adaptUIKitVersion(props), [props]);
    return <Heading {...adapteProps} />;
};

HeadingAdapter.propTypess = {
    version: PropTypes.string,
};

HeadingAdapter.defaultProps = {
    version: '',
};
Heading.propTypes = {
    level: PropTypes.string.isRequired,
    inheritColors: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

Heading.defaultProps = {
    inheritColors: false,
    className: null,
};

export default HeadingAdapter;
