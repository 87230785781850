import { gql } from '@apollo/client';
import { deliveryFragment } from '../fragments/delivery.fragment.graphql';
import { FULL_USER_PERSONAL_PROMO_FRAGMENT } from '../fragments/personalPromo.fragment';

export default gql`
    query UserQuery {
        user {
            id
            name
            phone
            email
            customerJourney
            filterTags
            customizationTags
            lastCustomizationComment
            customizationTagsDetailed {
                id
                title
            }
            personalPromo {
                ...fullUserPersonalPromoFragment
            }
            hasPendingDelivery
            hasApplication
            hasFeedback
            successDeliveriesCount
            lastSuccessDelivery {
                id
                date
                price
                dishes_count
                address_id
            }
            region
            addressBook {
                id
                addresses {
                    id
                    city
                    district
                    street
                    house
                    entrance
                    floor
                    flat
                    comment
                    country_subdivision
                    beltway_distance
                    type
                }
                contacts {
                    id
                    name
                    phone
                }
                lastOrderedAddress {
                    id
                    country_subdivision
                    city
                    district
                    street
                    house
                    entrance
                    floor
                    flat
                    type
                }
                lastDeliveredAddress {
                    id
                    city
                    district
                    country_subdivision
                    street
                    house
                    entrance
                    floor
                    flat
                    type
                }
            }
            deliveries {
                ...deliveryFragment
                isDelivered
                dishes {
                    id
                }
            }
            referralProgram {
                link
                referrals_count
                balance
                available_withdrawal_amount
                review {
                    reviewsCount {
                        name
                        count
                    }
                }
            }
            ab_groups
            isNewbie @client
        }
    }
    ${deliveryFragment}
    ${FULL_USER_PERSONAL_PROMO_FRAGMENT}
`;
