import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { useFragment } from '@apollo/client';

import { ADDITIONAL_DISHES_CATEGORIES, CATEGORIES_WITH_PORTION_ALIAS } from 'app/const/categories';
import { SPB_CODES } from 'app/const/location';

import formatPrice from 'app/utils/price';
import {
    menuImageSize,
    menuImageSizeMobile,
    getImageUrl,
    createShelfLifeData,
    formatCookUntil,
} from 'app/utils/dish';

import { withLocaleContext } from 'app/containers/LocaleProvider';

import DishImage from 'app/components/DishImage';
import PartnersComment from 'app/components/PartnersConmment';
import { DishImageShield } from 'app/components/ui/Elements/DishImageShield/DishImageShield';
import { ADDRESS_SUBDIVISION_FRAGMENT } from 'app/graphql/address/subdivision.fragment.graphql';

import XBigIcon from 'assets/svg/x_big.component.svg';
import ClockDetail from 'assets/svg/clock_detail.svg';
import PortionDetail from 'assets/svg/portion_detail.svg';
import CalendarDetail from 'assets/svg/calendar_detail.svg';

import { locales } from './mobileDishDetails.locales';

import UIParterLogoShield, { TEFAL_DISHES_ID } from '../../components/ui/Elements/PartnerLogoShield';
import { DishAdLabel } from '../../components/ui/Elements/DishAdLable/DishAdLabel';

import './mobile-dish-details.scss';


function CookUntil(props) {
    const {
        isShelfLife,
        cookUntil,
        l,
    } = props;

    const { data: { country_subdivision } } = useFragment({
        fragment: ADDRESS_SUBDIVISION_FRAGMENT,
        from: {
            __ref: 'address:session_address',
        },
    }) || 'RU-MOS';

    console.log(country_subdivision, 'country_subdivision');

    const cookUntilData = useMemo(() => {
        const formattedData = isShelfLife
            ? createShelfLifeData(cookUntil, l.shelfLifeText, l.shelfLifeDays)
            : formatCookUntil(cookUntil, l.cookUntilText, l.cookUnitlDays);

        if (SPB_CODES.includes(country_subdivision)) {
            return {
                ...formattedData,
                number: formattedData.number - 1,
            };
        }

        return formattedData;
    }, [isShelfLife, cookUntil, l, country_subdivision]);

    const { text, number, days } = cookUntilData;
    const cookText = `${text} ${number} ${days}`;

    return (
        <div styleName="dish-details__cooking">
            <div styleName="dish-details__cooking-icon"><CalendarDetail /></div>
            <div styleName="dish-details__cooking-text">{cookText}</div>
        </div>
    );
}


class MobileDishDetails extends React.Component {
    handleClose = () => {
        const { onClose } = this.props;
        onClose();
    };

    renderHeader = () => (
        <div styleName="basket__mobile-header">
            <button
                type="button"
                aria-label="Закрыть"
                styleName="dish-details__mobile-header-back"
                onClick={this.handleClose}
            >
                <XBigIcon />
            </button>
            <div styleName="basket__mobile-header-temp" />
            <div styleName="basket__mobile-header-title" />
            <div styleName="basket__mobile-header-temp" />
        </div>
    );

    renderDish = (item) => {
        const { localeContext: { locale } } = this.props;

        const {
            isSubscriptionActivated,
            portions,
            price,
            discount_price: discountPrice,
            promoDiscountPrice,
            dish: {
                categoryId,
                umAlias: umAliasProp,
                previewImage,
                previewMobileImage,
                title,
                caption,
                id,
            },
        } = item;
        const l = locales[locale];

        const titles = {
            ru: title,
            en: title,
        };
        const captions = {
            ru: caption,
            en: caption,
        };

        const umAlias = umAliasProp || ['г', 'г', 'г', 'г'];

        const isPromoDiscountPriceActive = price !== promoDiscountPrice && typeof promoDiscountPrice === 'number';
        const dishPrice = isSubscriptionActivated ? discountPrice : price;
        const basketPrice = isPromoDiscountPriceActive ? promoDiscountPrice : dishPrice;

        const portionPrice = dishPrice / portions;

        const useCustomPortionAlias = umAlias[0] !== 'г';
        const isDishWithPortionAlias = CATEGORIES_WITH_PORTION_ALIAS.includes(categoryId);

        const portionText = {
            ru: isDishWithPortionAlias && useCustomPortionAlias ? umAlias[1] : locales[locale].priceDescriptionPortion,
            en: locales[locale].priceDescriptionPortion,
        };

        const imageUrl = getImageUrl({ url: previewImage, ...menuImageSize });
        const imageUrlMobile = getImageUrl({ url: previewMobileImage, ...menuImageSizeMobile });

        return (
            <Transition
                in
                timeout={100}
                appear
            >
                {(state) => (
                    <div styleName={`dish-details__card-container dish-details__info-block ${state}`}>
                        <div styleName="dish-details__dish-card">
                            <div styleName="dish-details__image--container">
                                <div styleName="dish-details__image">
                                    {TEFAL_DISHES_ID.includes(id) && <UIParterLogoShield type="details" />}
                                    <DishImageShield dishId={id} />
                                    <DishAdLabel id={id} />
                                    <DishImage
                                        url={imageUrlMobile}
                                        fallbackUrl={imageUrl}
                                        alt={title}
                                    />
                                </div>
                            </div>
                            <div styleName="dish-details__wrap">
                                <div styleName="dish-details__content dish-details__content--title dish-details__info-block">
                                    <div styleName="dish-details__title-text">
                                        {titles[locale]}
                                    </div>
                                    <div styleName="dish-details__caption-text">
                                        {captions[locale]}
                                    </div>
                                </div>
                                <div styleName="dish-details__content dish-details__content--price dish-details__info-block">
                                    <div styleName="dish-details__basket-info">
                                        <div styleName="dish-details__basket-info-text">
                                            {l.portionsTitle}
                                        </div>
                                        <div styleName="dish-details__basket-portions">
                                            <div styleName="dish-details__basket-portions-number">
                                                <span styleName="num">{portions}</span>
                                            </div>
                                            <div styleName="dish-details__basket-portions-text">
                                                &nbsp;
                                                {portionText[locale]}
                                            </div>
                                            <div styleName="dish-details__basket-portions-x">&nbsp;&nbsp;x&nbsp;&nbsp;</div>
                                            <div styleName="dish-details__basket-portions-price">
                                                {locale === 'en' && <>₽&nbsp;</>}
                                                <span styleName="num">{formatPrice(portionPrice)}</span>
                                                {locale === 'ru' && <>&nbsp;₽</>}
                                            </div>
                                        </div>
                                    </div>
                                    <div styleName="dish-details__total">
                                        {locale === 'en' && <>₽&nbsp;</>}
                                        <span styleName="num">{formatPrice(basketPrice)}</span>
                                        {locale === 'ru' && <>&nbsp;₽</>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Transition>
        );
    };

    renderDishInfo = (item) => {
        const { localeContext: { locale } } = this.props;
        const l = locales[locale];

        const {
            dish: {
                categoryId,
                cooking_time: cookingTime,
                weight,
                cook_until: cookUntil,
                composition,
                nutrition,
                partnersComment,
            },
        } = item;

        const roundNutritions = Object.entries(nutrition)
            .filter(([_, { value }]) => Boolean(value)) // eslint-disable-line no-unused-vars
            .map(([key, { value, ...other }]) => {
                const title = l[key];
                const unit = key === 'calories' ? l.unitCalories : l.unitNutrition;

                return {
                    ...other,
                    title,
                    unit,
                    value: Math.round(value),
                };
            });

        const isShelfLife = ADDITIONAL_DISHES_CATEGORIES.includes(String(categoryId));

        return (
            <Transition
                in
                timeout={200}
                appear
            >
                {(containerState) => (
                    <div styleName={`dish-details__info-container dish-details__info-block ${containerState}`}>
                        <Transition
                            in
                            timeout={200}
                            appear
                        >
                            {(state) => (
                                <div styleName={`dish-details__info-block ${state}`}>
                                    <div styleName="dish-details__cooktime-portion">
                                        <div styleName="dish-details__cooktime">
                                            <div styleName="dish-details__text--container">
                                                <div styleName="dish-details__cooktime-icon"><ClockDetail /></div>
                                                <div>
                                                    <div styleName="dish-details__cooktime-text">{l.cookTimeText1}</div>
                                                    <div styleName="dish-details__cooktime-text">{l.cookTimeText2}</div>
                                                </div>
                                            </div>
                                            <div styleName="dish-details__cooktime-value-container">
                                                {cookingTime > 0 ? (
                                                    <>
                                                        <div styleName="dish-details__cooktime-value">{cookingTime / 60}</div>
                                                        <div styleName="dish-details__cooktime-unit">
                                                            {l.cookTimeUnit}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div styleName="dish-details__cooktime-value">&nbsp;</div>
                                                        <div styleName="dish-details__cooktime-unit">{l.cookTimeReady}</div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div styleName="dish-details__portion">
                                            <div styleName="dish-details__text--container">
                                                <div styleName="dish-details__portion-icon"><PortionDetail /></div>
                                                <div>
                                                    <div styleName="dish-details__portion-text">{l.weightDescription1}</div>
                                                    <div styleName="dish-details__portion-text">{l.weightDescription2}</div>
                                                </div>
                                            </div>
                                            <div styleName="dish-details__portion-value-container">
                                                <div styleName="dish-details__portion-value">{weight}</div>
                                                <div styleName="dish-details__portion-unit">
                                                    {l.priceDescriptionWeight}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                        {cookUntil && (
                            <Transition
                                in
                                timeout={300}
                                appear
                            >
                                {(state) => (
                                    <div styleName={`dish-details__info-block ${state}`}>
                                        <CookUntil
                                            l={l}
                                            isShelfLife={isShelfLife}
                                            cookUntil={cookUntil}
                                        />
                                    </div>
                                )}
                            </Transition>
                        )}
                        {(composition || partnersComment) && (
                            <Transition
                                in
                                timeout={400}
                                appear
                            >
                                {(state) => (
                                    <div styleName={`dish-details__info-block ${state}`}>
                                        <div styleName="dish-details__composition">
                                            {partnersComment && (
                                                <div styleName="dish-details__composition-title">
                                                    <PartnersComment comment={partnersComment} />
                                                    <br />
                                                    <br />
                                                </div>
                                            )}

                                            {composition && (
                                                <>
                                                    <div styleName="dish-details__composition-title">{l.whatsInside}</div>
                                                    <div styleName="dish-details__composition-text">{composition}</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Transition>
                        )}
                        <Transition
                            in
                            timeout={500}
                            appear
                        >
                            {(state) => (
                                <div styleName={`dish-details__info-block ${state}`}>
                                    <div styleName="dish-details__nutrition">
                                        <div styleName="dish-details__nutrition-title">
                                            {l.nutritionTitle}
                                            {l.nutritionSubtitle && (
                                                <>
                                                    <br />
                                                    {l.nutritionSubtitle}
                                                </>
                                            )}
                                        </div>
                                        <div styleName="dish-details__nutrition-list">
                                            {roundNutritions.map(({ value, unit, title }) => (
                                                <div styleName="dish-details__nutrition-item" key={title}>
                                                    <div styleName="dish-details__nutrition-item-title">{title}</div>
                                                    <div styleName="dish-details__nutrition-item-space" />
                                                    <div styleName="dish-details__nutrition-value-container">
                                                        <div styleName="dish-details__nutrition-item-value">{Math.ceil(value)}</div>
                                                        &nbsp;
                                                        <div styleName="dish-details__nutrition-item-unit">{unit}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Transition>
                    </div>
                )}
            </Transition>
        );
    };

    render() {
        const {
            info: { item },
        } = this.props;

        return (
            <div styleName="dish-details-container">
                {this.renderHeader()}
                <div
                    styleName="dish-details-scroll"
                    // id="touch-scrolllock-details" note: deprecated
                >
                    {this.renderDish(item)}
                    {this.renderDishInfo(item)}
                </div>
            </div>
        );
    }
}


export default withLocaleContext(MobileDishDetails);


MobileDishDetails.propTypes = {
    onClose: PropTypes.func.isRequired,
    info: PropTypes.shape({
        item: PropTypes.shape({
            portions: PropTypes.number,
            price: PropTypes.number,
            dish: PropTypes.shape({
                previewImage: PropTypes.string,
                title: PropTypes.string,
                caption: PropTypes.string,
                price: PropTypes.number,
                partnersComment: PropTypes.string,
            }),
        }),
    }).isRequired,
};
