import React, {
    useContext, useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';

import LINKS from 'app/const/links';

import FILTER_QUERY from 'app/graphql/network/filter';
import USER_QUERY from 'app/graphql/network/auth/userQuery';
import { MenuDatesCustomizationQuery } from 'app/graphql/network/menuDates';

import { getCustomizationSource } from 'app/customizationSource/customizationSource';

import { LocationContext } from 'app/containers/LocationProvider';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';

import { checkLinkAvailability, getAvailablePeriods } from 'app/utils/links';

import { FETCH_EE_AVAILABLE_DATES } from './graphlq/availableLinksOperations.graphql';


const INITIAL_STATE = {};


export const AvailableLinksContext = React.createContext(INITIAL_STATE);


/**
 * @description AvailableLinksContext фильтрует и передает потребителям
 * все доступные ссылки для конкретного пользователя.
 */
export const AvailableLinksProvider = ({ children }) => {
    const [links, setLinks] = useState(INITIAL_STATE);

    const { locationKey } = useContext(LocationContext);

    const filterQuery = useQuery(FILTER_QUERY, {
        context: {
            step: '&:AvailableLinksProvider',
        },
    });

    const { data: customizationData } = useQuery(MenuDatesCustomizationQuery);
    const customization = customizationData ? getCustomizationSource(customizationData) : null;

    // TODO: добавить сюда тоже
    const { data: menuDatesData } = useQuery(FETCH_EE_AVAILABLE_DATES, {
        variables: {
            filtersModified: Boolean(customization),
        },
        context: {
            step: 'app:read:AvailableLinksProvider',
        },
        skip: !customizationData,
    },
    );

    const enabledDatesData = menuDatesData?.menuDates?.dates;

    const periods = filterQuery?.data?.menuFilter?.periods;


    const { selectedPeriod } = useSelectedFilters();

    const userQuery = useQuery(USER_QUERY, {
        context: {
            step: 'TODO:fetch:AvailableLinksProvider',
        },
    });

    const user = userQuery?.data?.user;

    /**
     * @description getAllAvailableLinks возвращает ссылки, доступные для текущего пользователя.
     * @param {Object} data
     * @param {Array} data.availablePeriods - периоды, открытые (не отключенные) для заказов.
     * @param {string} data.currentSubdivision - текущая городская локация пользователя.
     * @param {string} data.datesType - тип специфичной даты доставки (everydayAvailableDate, trialAvailableDate, ...).
     * @param {Array} data.enabledDatesData - все специфичные даты доставки.
     * @param {Object} data.user - объект user.
    */
    const getAllAvailableLinks = useCallback((context) => {
        const linkIds = Object.keys(LINKS);
        /*
            Проверка общих условий отображения ссылок
            (например, TRIAL_FIRST_ORDER не может отображаться, если у пользователя уже есть доставки).
        */
        const checkedLinks = linkIds
            .filter((id) => checkLinkAvailability({ ...context, ...LINKS[id] }))
            .reduce((acc, id) => ({ ...acc, [id]: LINKS[id] }), {});

        return checkedLinks;
    }, []);

    /**
     * @description Сохраняет в стейт ссылки, доступные для текущего пользователя.
     * user может быть null (незалогиненный), поэтому проверка на 'undefined'.
     * Проверка нужна, чтобы пользователь не видел скрытие возможных ссылок, а только появление.
     */
    useEffect(() => {
        if (user === undefined) return;
        if (!periods) return;
        if (!enabledDatesData) return;

        const availablePeriods = getAvailablePeriods(periods);

        const availableLinks = getAllAvailableLinks({
            // currentSubdivision,
            enabledDatesData: enabledDatesData.length ? enabledDatesData : [],
            availablePeriods,
            selectedPeriod,
            user,
        });

        const linkIds = Object.keys(links);
        const availableIds = Object.keys(availableLinks);

        if (!isEqual(linkIds, availableIds)) {
            setLinks(availableLinks);
        }
    }, [
        getAllAvailableLinks, user, links, locationKey, enabledDatesData, periods, selectedPeriod,
    ]);

    return (
        <AvailableLinksContext.Provider value={links}>
            {children}
        </AvailableLinksContext.Provider>
    );
};

export const withAvailableLinks = (WrappedComponent) => (forwardedProps) => {
    const data = useContext(AvailableLinksContext);

    return (
        <WrappedComponent
            linksContext={data}
            {...forwardedProps}
        />
    );
};


AvailableLinksProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
};
