export const isCategoryExpanded = (id, expandedCategories) => expandedCategories.includes(id);
export const isCategoryInvisible = (id, invisibleCategories) => invisibleCategories.includes(id);


const isDishVisible = (dish, invisibleDishes) => !invisibleDishes.includes(dish.dish_id);

export const isOneItemInCategory = (category, invisibleDishes) => {
    const visibleDishesInCategory = category.items.filter(i => isDishVisible(i, invisibleDishes));
    return visibleDishesInCategory.length === 1;
};
