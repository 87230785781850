/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'lodash/flowRight';
import Raven from 'raven-js';

import {
    APPLIED,
    HAS_OLD_COMMENT_ONLY,
} from 'app/utils/customizationComment';
import Typograf from 'typograf';
import { Mobile, Desktop } from 'app/components/Responsive';

import TipIcon from 'assets/svg/tip_2.svg';

import { withLocaleContext } from 'app/containers/LocaleProvider';

import {
    COMMENT_FORM_ROLE_FORM,
    COMMENT_FORM_ROLE_BUTTON,
    COMMENT_PLACEMENT_DEFAULT,
    COMMENT_PLACEMENT_FILTERS,
    SUBTITILE_COMMENT_DECORATIONS_DEFAULT,
    SUBTITILE_COMMENT_DECORATIONS_QUOTE,
} from './consts';

import { CustomizationCommentFormAnimationWrap } from './CustomizationCommentForm';
import CustomizationCommentRoleButton from './CustomizationCommentRoleButton';
import { locales } from './сustomizationComment.locales';

import './customization-comment.scss';


const tp = new Typograf({ locale: ['ru', 'en-US'] });

const SUBTITLE_COMMENT_ROWS_COUNT = 2;
const MAX_MOBILE_HEADER_HEIGHT = 60; // in px

const TOP = 'top';
const BOTTOM = 'bottom';


class CustomizationComment extends Component {
    constructor(props) {
        super(props);

        this.commentFormOpenButtonRef = React.createRef();
        this.subtitleCommentRef = React.createRef();
        this.observer = null;
    }

    state = {
        isClippingFinished: false,
    };

    componentDidMount() {
        const {
            fetchedComment,
            fetchedCommentStatus,
            subtitleCommentValue,
            setSubtitleCommentValue,
            isNeedTooltip,
        } = this.props;

        this.createSubtitleCommentIntersectionObserver();

        if (isNeedTooltip) document.body.addEventListener('click', this.handleCloseTooltip);

        const isShownSubtitleComment = fetchedCommentStatus === APPLIED;
        if (isShownSubtitleComment && !subtitleCommentValue) setSubtitleCommentValue(fetchedComment);
    }

    componentDidUpdate(prevProps) {
        const {
            fetchedCommentStatus,
            subtitleCommentValue,
        } = this.props;

        const {
            subtitleCommentValue: prevSubtitleCommentValue,
        } = prevProps;

        const isShownSubtitleComment = fetchedCommentStatus === APPLIED;
        const isSubtitleCommentChanged = subtitleCommentValue !== prevSubtitleCommentValue;
        if (isShownSubtitleComment && isSubtitleCommentChanged) this.setShortSubtitleCommentText();

        const subtitleCommentEl = this.subtitleCommentRef.current;
        if (subtitleCommentEl) this.observer.observe(subtitleCommentEl);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.handleCloseTooltip);

        if (this.observer) this.observer.disconnect();
    }

    // Handlers ================================================================

    handleCloseTooltip = (e) => {
        const { onCloseTooltip } = this.props;

        const hasTooltipButtonInBubling = e.target.closest('#customizationCommentTooltipButton');
        const hasTooltipContentInBubling = e.target.closest('#customizationCommentTooltipContent');

        if (hasTooltipButtonInBubling || hasTooltipContentInBubling) return;

        onCloseTooltip();
    }


    createSubtitleCommentIntersectionObserver() {
        const { collapseSubtitleComment } = this.props;

        const intersectionObserverOptions = {
            root: null,
            rootMargin: `-${MAX_MOBILE_HEADER_HEIGHT}px 0px 0px 0px`,
            threshold: 0,
        };

        this.observer = new IntersectionObserver(collapseSubtitleComment, intersectionObserverOptions);
    }

    setShortSubtitleCommentText() {
        const {
            onTooLongSubtitleComment,
            subtitleCommentRowsCount,
        } = this.props;

        try {
            const el = this.subtitleCommentRef.current;
            const { height } = el.getBoundingClientRect();

            const lineHeight = parseFloat(window
                .getComputedStyle(el, null)
                .getPropertyValue('line-height'));

            const isSubtitleCommentShort = height <= lineHeight * subtitleCommentRowsCount;
            if (isSubtitleCommentShort) {
                this.setState({ isClippingFinished: true });
                return;
            }

            onTooLongSubtitleComment();
        } catch (e) {
            Raven.captureException(e);
        }
    }

    handleClickTooltip = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        const { onToggleToolTip } = this.props;

        onToggleToolTip();
    }

    handleOpenCommentForm = async () => {
        const { toggleCommentArea } = this.props;

        toggleCommentArea();
    }

    // Render ==================================================================
    renderCommentFormOpenButton() {
        const { fetchedComment, localeContext: { locale } } = this.props;
        const { initilaDesktopPlaceholder, initilaMobilePlaceholder } = locales[locale];

        const fakeInputStyle = classNames({
            'fake-input': true,
            comment: Boolean(fetchedComment),
        });
        // const placeholder = isDesktop() ? initilaDesktopPlaceholder : initilaDesktopPlaceholder;
        const initialPlaceholder = window.innerWidth <= 320 ? initilaMobilePlaceholder : initilaDesktopPlaceholder;
        const value = fetchedComment || initialPlaceholder;

        return (
            <div styleName="comment-form-open-button-container">
                <div styleName={fakeInputStyle}>
                    {value}
                </div>

                <input
                    styleName="comment-form-open-button"
                    type="text"
                    aria-label="Открыть форму ввода комментария"
                    ref={this.commentFormOpenButtonRef}
                    onClick={this.handleOpenCommentForm}
                    readOnly
                />
            </div>
        );
    }

    renderToolTip() {
        const {
            isToolTipShown, tooltipPosition, localeContext: { locale },
        } = this.props;

        // Locale content data
        const { tooltipTitle, tooltipDesriptionOne, tooltipDesriptionTwo } = locales[locale];

        const toolTipButtonStyle = classNames({
            'tooltip-button': true,
            active: isToolTipShown,
        });
        const toolTipIconStyle = classNames({
            'tooltip-icon': true,
            active: isToolTipShown,
        });
        const toolTipContentContainer = classNames({
            'tooltip-content-container': true,
            top: tooltipPosition === TOP,
            active: isToolTipShown,
        });

        return (
            <>
                <button
                    styleName={toolTipButtonStyle}
                    type="button"
                    aria-label="Показать подсказку"
                    onClick={this.handleClickTooltip}
                    id="customizationCommentTooltipButton"
                >
                    <TipIcon styleName={toolTipIconStyle} />
                </button>

                <div styleName={toolTipContentContainer} id="customizationCommentTooltipContent">
                    <h3 styleName="tooltip-content-title">
                        {tooltipTitle}
                    </h3>

                    <div styleName="tooltip-content-wrapper">
                        <p styleName="tooltip-content-text">
                            {tooltipDesriptionOne}
                        </p>

                        <p styleName="tooltip-content-text">
                            {tooltipDesriptionTwo}
                        </p>
                    </div>
                </div>
            </>
        );
    }

    renderSubtitle() {
        const {
            subtitleCommentDecorations,
            placement,
            fetchedComment,
            fetchedCommentStatus,
            isClippedComment,
            subtitleCommentValue,
            isShownFullSubtitleComment,
            onToggleShowingFullSubtitleComment,
            statusText,
            canEditComment,
        } = this.props;

        const { isClippingFinished } = this.state;

        const isShownProcessing = fetchedCommentStatus !== APPLIED;
        const isShownSuccess = fetchedCommentStatus === APPLIED;

        const text = isShownFullSubtitleComment ? fetchedComment : subtitleCommentValue;

        const subtitleCommentStyle = classNames({
            'subtitle-comment': true,
            clipped: isClippedComment && !isShownFullSubtitleComment,
            visible: isClippingFinished,
            full: isShownFullSubtitleComment,
            [subtitleCommentDecorations]: true,
        });

        const subtitleClasses = classNames({
            'notification-text': true,
            [placement]: placement,
        });

        return (
            <div styleName="subtitle-container">
                {canEditComment ? (
                    <button
                        styleName="show-comment-button"
                        type="button"
                        aria-label="Показать полный комментарий"
                        onClick={onToggleShowingFullSubtitleComment}
                    >
                        <p styleName={subtitleCommentStyle} ref={this.subtitleCommentRef}>
                            {`${text}\u2002`}
                        </p>
                    </button>
                ) : (
                    <p styleName="subtitle-comment visible full" ref={this.subtitleCommentRef}>
                        {fetchedComment}
                    </p>
                )}
                {isShownProcessing && (
                    <p styleName={subtitleClasses}>
                        {tp.execute(statusText)}
                    </p>
                )}
                {isShownSuccess && (
                    <p styleName="success">{tp.execute(statusText)}</p>
                )}
            </div>
        );
    }

    render() {
        const {
            isNeedTooltip,
            canEditComment,
            fetchedComment,
            fetchedCommentStatus,
            commentFormRole,
            commentFormRoleButtonClampDelay,
            localeContext: { locale },
        } = this.props;

        const isShownSubtitle = fetchedComment
            || fetchedCommentStatus === HAS_OLD_COMMENT_ONLY
            || fetchedCommentStatus === APPLIED;

        return (
            <div styleName="root">
                {canEditComment && (
                    <>
                        <Desktop>
                            {commentFormRole === COMMENT_FORM_ROLE_FORM ? (
                                <div styleName="inputs-container" data-test-id="customization-comment__inputs">
                                    {this.renderCommentFormOpenButton()}
                                    {isNeedTooltip && this.renderToolTip()}
                                </div>
                            ) : (
                                <CustomizationCommentRoleButton
                                    clampDelay={commentFormRoleButtonClampDelay}
                                    commentValue={fetchedComment}
                                    onClick={this.handleOpenCommentForm}
                                    locale={locale}
                                />
                            )}
                        </Desktop>

                        <Mobile>
                            <div styleName="inputs-container" data-test-id="customization-comment__inputs">
                                {this.renderCommentFormOpenButton()}
                                <CustomizationCommentFormAnimationWrap
                                    {...this.props}
                                    locale={locale}
                                    commentFormOpenButtonEl={this.commentFormOpenButtonRef.current}
                                />
                                {isNeedTooltip && this.renderToolTip()}
                            </div>
                        </Mobile>
                    </>
                )}
                {isShownSubtitle && this.renderSubtitle()}
            </div>
        );
    }
}


CustomizationComment.propTypes = {
    isClippedComment: PropTypes.bool.isRequired,
    isToolTipShown: PropTypes.bool.isRequired,
    isShownFullSubtitleComment: PropTypes.bool.isRequired,
    isNeedTooltip: PropTypes.bool,
    canEditComment: PropTypes.bool,

    subtitleCommentDecorations: PropTypes.oneOf([
        SUBTITILE_COMMENT_DECORATIONS_DEFAULT,
        SUBTITILE_COMMENT_DECORATIONS_QUOTE,
    ]),
    subtitleCommentRowsCount: PropTypes.number,
    placement: PropTypes.oneOf([COMMENT_PLACEMENT_DEFAULT, COMMENT_PLACEMENT_FILTERS]),
    commentFormRole: PropTypes.oneOf([COMMENT_FORM_ROLE_FORM, COMMENT_FORM_ROLE_BUTTON]),
    commentValue: PropTypes.string,
    fetchedComment: PropTypes.string,
    fetchedCommentStatus: PropTypes.number.isRequired,
    subtitleCommentValue: PropTypes.string,
    tooltipPosition: PropTypes.string,
    statusText: PropTypes.string.isRequired,
    commentFormRoleButtonClampDelay: PropTypes.number,

    onSubmit: PropTypes.func.isRequired,
    onToggleToolTip: PropTypes.func.isRequired,
    onTooLongSubtitleComment: PropTypes.func.isRequired,
    onToggleShowingFullSubtitleComment: PropTypes.func.isRequired,
    onCloseTooltip: PropTypes.func.isRequired,
    setSubtitleCommentValue: PropTypes.func.isRequired,
    collapseSubtitleComment: PropTypes.func.isRequired,
    toggleCommentArea: PropTypes.func.isRequired,
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
};

CustomizationComment.defaultProps = {
    subtitleCommentDecorations: SUBTITILE_COMMENT_DECORATIONS_DEFAULT,
    subtitleCommentRowsCount: SUBTITLE_COMMENT_ROWS_COUNT,
    placement: COMMENT_PLACEMENT_DEFAULT,
    commentFormRole: COMMENT_FORM_ROLE_FORM,
    commentValue: null,
    subtitleCommentValue: '',
    fetchedComment: null,
    tooltipPosition: BOTTOM, // top, bottom
    commentFormRoleButtonClampDelay: null,

    isNeedTooltip: false,
    canEditComment: true,
};


export default compose(
    withLocaleContext,
)(CustomizationComment);
