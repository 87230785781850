/* eslint-disable global-require */

// const isBaseeMealConstructor = (trialScenario) => (
//     trialScenario !== 's_5x2'
//     && trialScenario !== 's_4x2'
//     && trialScenario !== 's_s5x2'
//     && trialScenario !== 's_family'
//     && trialScenario !== 's_bwlite'
//     && trialScenario !== 's_bwcharity'
// );

export const wasaPeriods = [
    '2024-05-13',
    '2024-05-20',
];

export const wasaGift = {
    id: 'wasa',
    check: ({
        selectedPeriod,
        // subdivision,
    }) => wasaPeriods.includes(selectedPeriod),
    dishData: {
        dish_id: 'wasa',
        type: 'gift',
        portions: 1,
        dish: {
            id: 'wasa',
            title: '',
            titleEn: '',
            caption: 'Комплимент от Wasa при заказе блюд из меню GREEN с 13 по 26 мая.',
            captionEn: 'Комплимент от Wasa при заказе блюд из меню GREEN с 13 по 26 мая.',
            previewMobileImage: require('./wasa_2024.jpg'),
            previewImage: require('./wasa_2024.jpg'),
        },
    },
};


// export const hansaPeriods = ['2023-10-02'];

// export const hansaGift = {
//     id: 'hansa',
//     check({ selectedPeriod }) {
//         return hansaPeriods.includes(selectedPeriod);
//     },
//     dishData: {
//         dish_id: 'hansa',
//         type: 'gift',
//         portions: 1,
//         dish: {
//             id: 'hansa',
//             title: 'Розыгрыш',
//             titleEn: 'Розыгрыш',
//             caption: 'варочной панели от Hansa',
//             captionEn: 'варочной панели от Hansa',
//             previewMobileImage: require('./hansa.img.svg'),
//             previewImage: require('./hansa.img.svg'),
//         },
//     },
// };

export const bariallPeriods = ['2023-11-13'];

export const barillaGift = {
    id: 'barilla',
    check({ selectedPeriod, subdivision }) {
        const isMsk = ['RU-MOW', 'RU-MOS'].includes(subdivision);
        const isPeriod = bariallPeriods.includes(selectedPeriod);
        return isMsk && isPeriod;
    },
    dishData: {
        dish_id: 'barilla',
        type: 'gift',
        portions: 1,
        dish: {
            id: 'barilla',
            title: 'При заказе с 13 по 19 ноября получите подарок -',
            titleEn: 'При заказе с 13 по 19 ноября получите подарок -',
            caption: 'Соус Barilla Pesto alla Genovese с базиликом',
            captionEn: 'Соус Barilla Pesto alla Genovese с базиликом',
            previewMobileImage: require('./barilla.jpg'),
            previewImage: require('./barilla.jpg'),
        },
    },
};


export const customGiftsConfig = [
    barillaGift,
    // wasaGift,
];
