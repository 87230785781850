/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
import cloneDeep from 'lodash/cloneDeep';

import { CATEGORY_TITLES } from 'app/const/categories';
import DishGiftList from 'app/components/DishGiftList';
import { UIButton } from 'app/components/ui';

import { isDesktop } from 'app/utils/resolution';
import Dishes from '../BasketDishes';
import SetsBasketCategoryHeader from '../SetsBasket/SetBasketCategoryHeader';

import {
    isCategoryInvisible,
    isOneItemInCategory,
} from './basket-categories-utils';
import BasketCategoryHeader from './BasketCategoryHeader';
import { customGiftsConfig } from './customGifts/customGifts';

import './basket-categories.scss';


const DELETING_DISH_TIMEOUT = 20000; // timeout for completing animations before 'addToBasket' function calls
const CLOSING_CATEGORY_TIMEOUT = 900; // 100ms - delay, 800ms - animation


const locales = {
    ru: {
        // renderAddMoreButton
        addMoreText: 'Добавить блюда',
    },
    en: {
        // renderAddMoreButton
        addMoreText: 'Add dishes',
    },
};

const COMMONBASKET = [
    'common_desktop_basket',
    'control_small_basket_with_promo',
    'control_small_basket_without_promo',
    'add_collections_small_with_promo',
];

export class BasketCategories extends React.Component {
    constructor(props) {
        super(props);
        this.scrollbarsRef = React.createRef();
    }
    // Render ==================================================================

    renderGifrSection(giftSection) {
        const {
            preparedBasketAreas,
        } = this.props;

        const hasGifts = giftSection && giftSection.items.length > 0;
        if (!hasGifts) return null;

        const giftDishesClasses = classNames({
            basket__dishes: Boolean(hasGifts),
            'with-gifts': Boolean(hasGifts),
        });

        return (
            <div styleName={giftDishesClasses}>
                <div styleName="basket__dishes-type">
                    <DishGiftList
                        preparedBasketAreas={preparedBasketAreas}
                        key={giftSection.id}
                        items={giftSection.items}
                    />
                </div>
            </div>
        );
    }

    renderDishes({ items, id }, isOneItem) {
        const {
            abTestData,
            isEditEnabled,
            isSubscriptionActivated,
            onChangePortion,
            onClickRemoveDish,
            onClickMobilePortions,
            onClickMobileDishDetails,
            locale,
            preparedBasketAreas,
            subscriptionType,
        } = this.props;

        return (
            <Dishes
                subscriptionType={subscriptionType}
                preparedBasketAreas={preparedBasketAreas}
                items={items}
                sectionId={id}
                isEditEnabled={isEditEnabled}
                isSubscriptionActivated={isSubscriptionActivated}
                onChangePortion={onChangePortion}
                onRemoveClick={onClickRemoveDish}
                onClickMobilePortions={onClickMobilePortions}
                onClickMobileDishDetails={onClickMobileDishDetails}
                scrollbarsRef={this.scrollbarsRef}
                isOneItemInCategory={isOneItem}
                locale={locale}
            />
        );
    }

    renderAddMoreButton({ id }) {
        const {
            onClickAddMoreButton,
            locale,
            preparedBasketAreas: { basketStyleModifier },
        } = this.props;
        const { addMoreText } = locales[locale];

        const isAddMoreButtonHidden = basketStyleModifier === 'new_upsell';

        if (isAddMoreButtonHidden) {
            return null;
        }

        return (
            <div styleName={`basket-dishes__add-more ${basketStyleModifier}`}>
                <UIButton
                    onClick={() => onClickAddMoreButton(id)}
                    colorType="secondary"
                    aria-label="Добавить блюда"
                >
                    {addMoreText}
                </UIButton>
            </div>
        );
    }

    renderCategory(category, transitionState, dishesCategories) {
        const {
            isEditEnabled, showAddMoreButton, invisibleDishes, locale,
            preparedBasketAreas: {
                basketStyleModifier,
                list: { titleType },
            },
            isShownUpsell,
        } = this.props;

        const isShowCategoryHeader = !isShownUpsell && !isDesktop();

        const { id } = category;

        const isOneItem = isOneItemInCategory(category, invisibleDishes);

        const isAddMoreButtonShown = showAddMoreButton && isEditEnabled;

        const categoryClasses = classNames({
            'basket__dishes-type': true,
            open: true,
            small: true,
            [transitionState]: true,
            [basketStyleModifier]: true,
        });

        const title = CATEGORY_TITLES[locale][id];

        const categoryHeader = (titleType === 'set' ? (
            <SetsBasketCategoryHeader
                dishesCategories={dishesCategories}
                title={title}
                count={category.items.length}
                locale={locale}
            />
        ) : (
            <BasketCategoryHeader
                title={title}
                basketStyleModifier={basketStyleModifier}
            />
        ));

        return (
            <div
                styleName={categoryClasses}
                key={id}
            >
                {!isShowCategoryHeader && categoryHeader}
                {/* DISHES LIST */}
                {this.renderDishes(category, isOneItem)}
                {/* ADD MORE BUTTON */}
                {isAddMoreButtonShown
                    // && COMMONBASKET.includes(basketStyleModifier)
                    && this.renderAddMoreButton(category)
                }
            </div>
        );
    }

    renderDishesCategories(dishesCategories) {
        const {
            invisibleCategories,
            preparedBasketAreas: {
                basketStyleModifier,
                list: { isBasketDishesListFlat },
            },
            abTestData,
        } = this.props;

        const isMobileResolution = !isDesktop();

        const basketDishesClasses = classNames({
            basket__dishes: true,
            'basket-dishes--with-top-padding': isMobileResolution,
            'basket-dishes--with-shadow': COMMONBASKET.includes(basketStyleModifier),
            [basketStyleModifier]: true,
        });

        const categories = isBasketDishesListFlat
            ? dishesCategories.reduce((acc, category) => {
                acc[0].items = acc[0].items.concat(category.items);
                return acc;
            }, [{
                id: 'all',
                gift_category: false,
                title: 'Ваш заказ',
                items: [],
                __typeName: 'cartSection',
            }])
            : dishesCategories;

        return (
            <div styleName={basketDishesClasses}>
                {categories.map((category) => {
                    const { id: categoryId } = category;
                    const timeout = DELETING_DISH_TIMEOUT + CLOSING_CATEGORY_TIMEOUT;
                    return (
                        <Transition
                            in={!isCategoryInvisible(categoryId, invisibleCategories)}
                            appear
                            unmountOnExit
                            timeout={timeout}
                            key={categoryId}
                        >
                            {(transitionState) => this.renderCategory(category, transitionState, categories)}
                        </Transition>
                    );
                })}
            </div>
        );
    }

    renderCategories(categories) {
        const {
            selectedPeriod,
            menuDatesState,
            deliveryBasketDate,
            subdivision,
            dynamicCustomGifts,
        } = this.props;

        let giftSection = categories.find((s) => s.gift_category) || { items: [] };
        const trialScenario = getTrialScenarioFromUrl();

        const customGifts = [...customGiftsConfig, ...dynamicCustomGifts]
            .filter((giftData) => giftData.check({
                selectedPeriod,
                trialScenario,
                menuDatesState,
                subdivision,
                deliveryBasketDate,
            }))
            .map((giftData) => giftData.dishData);

        giftSection = cloneDeep(giftSection);
        giftSection.items = [
            ...giftSection.items,
            ...customGifts,
        ];

        const dishesCategories = categories.filter((s) => !s.gift_category);

        const isDishesCategories = dishesCategories.length > 0;

        return (
            <>
                {giftSection && this.renderGifrSection(giftSection)}
                {isDishesCategories && this.renderDishesCategories(dishesCategories)}
            </>
        );
    }

    render() {
        const { categories } = this.props;

        return (<div ref={this.scrollbarsRef}>{this.renderCategories(categories)}</div>);
    }
}

BasketCategories.propTypes = {
    isShownUpsell: PropTypes.string,
    selectedPeriod: PropTypes.string.isRequired,
    menuDatesState: PropTypes.shape({
        selectedDate: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        }),
    ).isRequired,
    invisibleCategories: PropTypes.arrayOf(
        PropTypes.string,
    ),
    invisibleDishes: PropTypes.arrayOf(
        PropTypes.string,
    ),
    isEditEnabled: PropTypes.bool,
    isSubscriptionActivated: PropTypes.bool.isRequired,
    showAddMoreButton: PropTypes.bool,
    onChangePortion: PropTypes.func.isRequired,
    onClickRemoveDish: PropTypes.func.isRequired,
    onClickMobilePortions: PropTypes.func.isRequired,
    onClickMobileDishDetails: PropTypes.func.isRequired,
    onClickAddMoreButton: PropTypes.func,
    locale: PropTypes.string.isRequired,
    preparedBasketAreas: PropTypes.shape({
        basketStyleModifier: PropTypes.string,
        list: PropTypes.shape({
            titleType: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,

    subdivision: PropTypes.string,
    deliveryBasketDate: PropTypes.string,

    dynamicCustomGifts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    subscriptionType: PropTypes.string.isRequired,
};

BasketCategories.defaultProps = {
    subdivision: '',
    menuDatesState: {
        selectedDate: null,
    },
    isEditEnabled: true,
    showAddMoreButton: false,
    onClickAddMoreButton: () => { },
    invisibleCategories: [],
    invisibleDishes: [],

    deliveryBasketDate: null,
    isShownUpsell: true,
};
