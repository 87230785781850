import { errorService } from 'global/services';


const TEXT_PAYMENT_DESCRIPTION = 'Оплата в elementaree.ru';
const TEXT_PAYMENT_SUBSCRIPTION = 'Оформление подписки в elementaree.ru';


export const cloudPayments = {
    id: 'cloudPayments',
    src: 'https://widget.cloudpayments.ru/bundles/cloudpayments',
    paymentMethods: [
        'recurrent',
        'online',
    ],

    showWidget: (options) => {
        const {
            invoiceId,
            accountId,
            price,
            needСreateSubscription,
            onSuccess,
            onFail,
        } = options;

        // eslint-disable-next-line no-undef
        const widget = new cp.CloudPayments({ sbpSupport: !needСreateSubscription });

        const data = needСreateSubscription ? {
            cloudPayments: {
                recurrent: {
                    interval: 'Day',
                    period: 7,
                },
            },
        } : {};

        widget.charge({
            publicId: process.env.CLOUD_PAYMENTS_PUBLIC_KEY,
            description: needСreateSubscription ? TEXT_PAYMENT_SUBSCRIPTION : TEXT_PAYMENT_DESCRIPTION,
            amount: price,
            currency: 'RUB',
            invoiceId,
            accountId,
            data,
        },
            () => onSuccess(),
            (reason) => {
                errorService.log({
                    text: `CloudPayments error: ${reason}`,
                    source: 'cloudPayments',
                    extra: {
                        reason,
                    },
                });
                onFail(reason);
            });
    },
};
