import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from 'app/components/Form/Checkbox2';
import { locales } from './menuFilterOption.locales';

import './menu-filter-option.scss';


export default class MenuFilterOption extends React.Component {
    handleClick = (e) => {
        const {
            id,
            title,
            isSelected,
            onAdd,
            onRemove,
        } = this.props;

        e.preventDefault();

        const args = { id, title };

        if (isSelected) {
            onRemove(args);
        } else {
            onAdd(args);
        }
    }

    render() {
        const {
            id,
            title: titleProp,
            count,
            isSelected,
            disabled: isDisabledProp,
            locale,
        } = this.props;

        const disabled = isDisabledProp || count === 0;
        const title = locales[locale][id] || titleProp;

        const optionButtomClasses = classNames({
            'menu-filter-option__button': true,
            disabled,
        });

        return (
            <div styleName="menu-filter-option">
                <button
                    styleName={optionButtomClasses}
                    type="button"
                    onClick={this.handleClick}
                    disabled={disabled}
                >
                    <Checkbox
                        name="menu-filter-option"
                        id={`menu-filter-option-${id}`}
                        checked={isSelected}
                        onChange={this.handleClick}
                        disabled={disabled}
                    />
                    <span styleName="menu-filter-option__title">
                        {title}
                    </span>
                    <span styleName="menu-filter-option__count">
                        {count}
                    </span>
                </button>
            </div>
        );
    }
}


MenuFilterOption.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    count: PropTypes.number.isRequired,
    isSelected: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    locale: PropTypes.string.isRequired,
};

MenuFilterOption.defaultProps = {
    isSelected: false,
    disabled: false,
};
