/* eslint-disable global-require */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFragment } from '@apollo/client';
import {
    menuImageSizeMobile,
    getImageUrl,
    createShelfLifeData,
    formatCookUntil,
} from 'app/utils/dish';

import { SPB_CODES } from 'app/const/location';
import { BOXES_IDS_COLLECTION } from 'app/const/dishesWithBoxes';
import { ADDITIONAL_DISHES_CATEGORIES } from 'app/const/categories';
import { ADDRESS_SUBDIVISION_FRAGMENT } from 'app/graphql/address/subdivision.fragment.graphql';

import stubSrc from 'app/components/DishImage/stub-image/stub-m.jpg';
import classNames from 'classnames';
import closeIconPath from './img/close.img.svg';

import { ALL_RENDERING_CONTEXTS, RENDERING_CONTEXT_DEFAULT } from './const';
import { NEW_DISH_DETAILS_CARD_FRAGMENT } from './graphql/dishDetailsWithCustom.graphql';
import { PortionsButtonGuard, PortionsButtonGroup } from './components/PortionsButtonGroup';

import { DishDetailsSetDescription } from './components/DishDetailsSetDescription/DishDetailsSetDescription';
import SetDescriptionCollection from './components/DishDetailsSetDescription/setDescription.record';

import './dishDetailsWithCustom.scss';


const overrideImages = {
    4252: [
        require('./img/4252-m-1.jpg'),
        require('./img/4252-m-2.jpg'),
    ],
    2808: [
        require('./img/2808-m-1.jpg'),
        require('./img/2808-m-2.jpg'),
    ],
    3418: require('./img/3418.jpg'),
};


/* COMPONENTS */

const NewDishDetailsComposition = React.memo(({ id }) => {
    const { data: dishItem } = useFragment({
        fragment: NEW_DISH_DETAILS_CARD_FRAGMENT,
        from: {
            __ref: `menuDish:${id}`,
        },
    });

    const { composition } = dishItem;

    return (
        <div styleName="new-details-composition-root">
            <div styleName="new-details-composition-title">
                Состав блюда
            </div>
            <div styleName="new-details-composition-text">
                {composition}
            </div>
        </div>
    );
});

NewDishDetailsComposition.propTypes = {
    id: PropTypes.string.isRequired,
};


const NutritionLine = React.memo(({ nutrition }) => {
    const {
        fat, calories, carbohydrate, protein,
    } = nutrition;

    return (
        <div styleName="new-details-card-info-line">
            <div styleName="new-details-card-info-line-one">
                На
                {' '}
                <span styleName="new-details-card-info-digit-value">100</span>
                г /
                {' '}
                <span styleName="new-details-card-info-digit-value">{Math.round(calories.value)}</span>
                {' '}
                {calories.unit}
            </div>
            <div styleName="new-details-card-info-line-two basket-with--dots" />
            {/* TODO: Исправить отступы */}
            <div styleName="new-details-card-info-line-three">
                <span styleName="divider">
                    Б
                    <span styleName="new-details-card-info-digit-value">{Math.round(protein.value)}</span>
                    ,
                </span>
                <span styleName="divider">
                    Ж
                    <span styleName="new-details-card-info-digit-value">{Math.round(fat.value)}</span>
                    ,
                </span>
                <span>
                    У
                    <span styleName="new-details-card-info-digit-value">{Math.round(carbohydrate.value)}</span>
                </span>
            </div>
        </div>
    );
});

NutritionLine.propTypes = {
    nutrition: PropTypes.shape({
        calories: PropTypes.shape({
            value: PropTypes.number.isRequired,
            unit: PropTypes.string.isRequired,
        }).isRequired,
        protein: PropTypes.shape({
            value: PropTypes.number.isRequired,
        }).isRequired,
        fat: PropTypes.shape({
            value: PropTypes.number.isRequired,
        }).isRequired,
        carbohydrate: PropTypes.shape({
            value: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
};


const NewDishDetailsCompositionBox = ({ id }) => {
    const descriptionRecordItem = SetDescriptionCollection[id];
    const { descriptionContent: { composition } } = descriptionRecordItem;

    return (
        <div styleName="new-details-card-info box">
            {composition.map((e) => {
                const firstLineClasses = classNames({
                    'new-details-card-info-line-one': true,
                    limited: e.hasLimitedWidth,

                });
                return (
                    <div
                        styleName="new-details-card-info-line"
                        key={e.value}
                    >
                        <div styleName={firstLineClasses}>
                            {e.title}
                        </div>
                        <div styleName="new-details-card-info-line-two basket-with--dots" />
                        <div styleName="new-details-card-info-line-three">
                            <span styleName="new-details-card-info-digit-value">{e.value}</span>
                            <span>
                                {' '}
                                {e.unit}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

NewDishDetailsCompositionBox.propTypes = {
    id: PropTypes.string.isRequired,
};


// TODO: reuse old image?
const DishImage = React.memo((props) => {
    const { src: srcProp, alt } = props;
    const [isLoadingError, setIsLoadingError] = useState(false);
    const src = isLoadingError ? stubSrc : srcProp;

    return (
        <img
            src={src}
            alt={alt}
            onError={() => setIsLoadingError(true)}
            styleName="new-details-card-img__item"
        />
    );
});

DishImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};


const NewDishDetailsCard = React.memo(({ id }) => {
    const { data: dishItem } = useFragment({
        fragment: NEW_DISH_DETAILS_CARD_FRAGMENT,
        from: {
            __ref: `menuDish:${id}`,
        },
    });


    const { data: { country_subdivision: cs } } = useFragment({
        fragment: ADDRESS_SUBDIVISION_FRAGMENT,
        from: {
            __ref: 'address:session_address',
        },
    }) || 'RU-MOS';


    const {
        title, caption, weight, previewMobileImage, nutrition,
        cook_until: cookUntil,
        cooking_time: cookingTime,
        categoryId,
    } = dishItem;

    const {
        untilTitle,
        untilDaysPrefix,
        untilDays,
        untilText,
    } = useMemo(() => {
        const isShelfLife = ADDITIONAL_DISHES_CATEGORIES.includes(String(categoryId));

        const { number, days: cookUntilDays } = formatCookUntil(cookUntil);
        const { days: shelfLifeDays } = createShelfLifeData(cookUntil);

        return {
            untilTitle: isShelfLife ? 'Срок хранения' : 'Приготовить',
            untilDaysPrefix: isShelfLife ? '' : 'на ',
            untilDays: SPB_CODES.includes(cs) ? number - 1 : number,
            untilText: isShelfLife ? shelfLifeDays : cookUntilDays,
        };
    }, [cs, cookUntil, categoryId]);

    const isAnimation = Array.isArray(overrideImages[id]);
    const imageUrlMobile = overrideImages[id]
        ? overrideImages[id]
        : getImageUrl({ url: previewMobileImage, ...menuImageSizeMobile });

    const isBoxDish = BOXES_IDS_COLLECTION.includes(id);
    const rootClasses = classNames({
        'new-details-card-root': true,
        box: isBoxDish,
    });

    return (
        <div styleName={rootClasses}>
            {/* IMAGE */}
            <div styleName="new-details-card-img">
                {isAnimation ? (
                    <div styleName="new-details-card-animation-wrapper">
                        {overrideImages[id].map((src) => (
                            <div key={src} styleName="new-details-card-animation">
                                <DishImage
                                    src={src}
                                    alt={title}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <DishImage
                        src={imageUrlMobile}
                        alt={title}
                    />
                )}
            </div>

            {/* CARD */}
            <div styleName="new-details-card-title">
                {title}
                <br />
                {caption}
            </div>
            <div styleName="new-details-card-info">
                <div styleName="new-details-card-info-line">
                    <div styleName="new-details-card-info-line-one">Порция</div>
                    <div styleName="new-details-card-info-line-two basket-with--dots" />
                    <div styleName="new-details-card-info-line-three">
                        <span styleName="new-details-card-info-digit-value">{weight}</span>
                        {' '}
                        г
                    </div>
                </div>
                <div styleName="new-details-card-info-line">
                    <div styleName="new-details-card-info-line-one">Готовить</div>
                    <div styleName="new-details-card-info-line-two basket-with--dots" />
                    <div styleName="new-details-card-info-line-three">
                        <span styleName="new-details-card-info-digit-value">{cookingTime / 60}</span>
                        {' '}
                        минут
                    </div>
                </div>
                <div styleName="new-details-card-info-line">
                    <div styleName="new-details-card-info-line-one">
                        {untilTitle}
                    </div>
                    <div styleName="new-details-card-info-line-two basket-with--dots" />
                    <div styleName="new-details-card-info-line-three">
                        {untilDaysPrefix}
                        <span styleName="new-details-card-info-digit-value">{untilDays}</span>
                        {' '}
                        {untilText}
                    </div>
                </div>
                <NutritionLine nutrition={nutrition} />
            </div>
        </div>
    );
});

NewDishDetailsCard.propTypes = {
    id: PropTypes.string.isRequired,
};


export const DishDetailsWithCustom = React.memo((props) => {
    const {
        id, renderingContext, onClose, refetchCallback,
    } = props;
    const isBoxDish = BOXES_IDS_COLLECTION.includes(id);

    return (
        <>
            <div styleName="new-dish-details-root">
                <NewDishDetailsCard id={id} />
                {isBoxDish
                    ? <NewDishDetailsCompositionBox id={id} />
                    : <NewDishDetailsComposition id={id} />
                }
                {isBoxDish && <DishDetailsSetDescription id={id} />}
                <PortionsButtonGuard renderingContext={renderingContext}>
                    <PortionsButtonGroup id={id} refetchCallback={refetchCallback} />
                </PortionsButtonGuard>
            </div>
            <button
                styleName="new-details-card-close"
                type="button"
                onClick={onClose}
            >
                <img
                    src={closeIconPath}
                    alt="icon"
                    styleName="new-details-card-close__item"
                />
            </button>
        </>
    );
});

DishDetailsWithCustom.propTypes = {
    id: PropTypes.string.isRequired,
    renderingContext: PropTypes.oneOf(ALL_RENDERING_CONTEXTS),
    onClose: PropTypes.func.isRequired,
};

DishDetailsWithCustom.defaultProps = {
    renderingContext: RENDERING_CONTEXT_DEFAULT,
};
