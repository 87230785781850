import React from 'react';
import PropTypes from 'prop-types';
import Velocity from 'velocity-animate';
import { Transition } from 'react-transition-group';
import { Portal } from 'react-portal';
import classNames from 'classnames';


import UI from 'app/components/ui';
import { FiltersSoldOutOverlayTooltip } from 'app/components/FeatureNews';
import MobileCategoriesSelect from '../MobileCategoriesSelect';

import './header-category.scss';


const FEATURE_NEWS_OVERLAY_ANIMATION_TIMER = 700;
const CATEGORY_DISAPPEARING_TIMEOUT = 300; // should be the same as '$transition-timeout' in 'mobile-dish.scss'

const locales = {
    ru: {
        readySetsTitle: 'Наши сеты',
    },
    en: {
        readySetsTitle: 'Our sets',
    },
};


class HeaderCategory extends React.Component {
    constructor(props) {
        super(props);

        this.categoryRef = React.createRef();
    }

    state = {
        isSelectOpen: false,
    };

    componentDidUpdate(prevProps) {
        const { isCategoryChanging } = this.props;
        const { isCategoryChanging: prevIsCategoryChanging } = prevProps;
        const isCategoryStateChanged = isCategoryChanging !== prevIsCategoryChanging;
        const categoryEl = this.categoryRef.current;

        if (isCategoryChanging && categoryEl && isCategoryStateChanged) {
            this.animateVanishCategory(categoryEl);
        }
        if (!isCategoryChanging && categoryEl && isCategoryStateChanged) {
            this.animateAppearingCategory(categoryEl);
        }
    }

    handleToggleSelect = () => {
        this.setState(({ isSelectOpen }) => ({ isSelectOpen: !isSelectOpen }));
    };

    handleClickFilters = () => {
        const {
            isSoldOutNotificationVisible,

            onClickFilter,
            onClickCloseSoldOutNotification,
        } = this.props;

        onClickFilter();

        if (isSoldOutNotificationVisible) {
            onClickCloseSoldOutNotification();
        }
    };

    // eslint-disable-next-line class-methods-use-this
    async animateVanishCategory(el) {
        await Velocity( // reduce
            el,
            { scale: 0, opacity: 0 },
            { duration: CATEGORY_DISAPPEARING_TIMEOUT },
        );
    }

    // eslint-disable-next-line class-methods-use-this
    async animateAppearingCategory(el) {
        await Velocity( // appear
            el,
            { opacity: 1 },
            { duration: 0 },
        );
        await Velocity( // increase
            el,
            { scale: 1 },
            [229.47, 20.17],
        );
    }

    renderNewsBanner(canShowSoldOutNotification) {
        const {
            onClickCloseSoldOutNotification,
        } = this.props;

        if (canShowSoldOutNotification) {
            return (
                <Transition
                    in={canShowSoldOutNotification}
                    appear
                    timeout={FEATURE_NEWS_OVERLAY_ANIMATION_TIMER}
                >
                    {(transitionState) => (
                        <FiltersSoldOutOverlayTooltip
                            transitionState={transitionState}
                            onClickClose={onClickCloseSoldOutNotification}
                        />
                    )}
                </Transition>
            );
        }
        return null;
    }

    renderCategoryTitle() {
        const {
            showBestSetsTitle,
            categoryTitle,
            locale,
        } = this.props;

        const title = categoryTitle;

        if (showBestSetsTitle) {
            return locales[locale].readySetsTitle;
        }

        if (Array.isArray(title)) {
            return (
                <>
                    {title.map((part, i) => (
                        <React.Fragment key={part}>
                            {i !== 0 && <span className="micro">.&nbsp;</span>}
                            {part}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return title;
    }

    render() {
        const {
            isSelectOpen,
        } = this.state;

        const {
            isSoldOutNotificationVisible,

            setDishesUpdatingState,
            mainDishesCountState,
            selectedTags,
            basketQuery,
        } = this.props;

        const isMainDishesSoldOut = mainDishesCountState === 'sold-out';

        const canShowSoldOutNotification = isSoldOutNotificationVisible && isMainDishesSoldOut;

        const filtersIconClasses = classNames({
            'header-category__filter-button-container': true,
            'is-above-notifications': canShowSoldOutNotification,
            'is-some-filter--selected': Boolean(selectedTags.length),
        });

        return (
            <>
                <button
                    type="button"
                    aria-label="Открыть типы блюд"
                    styleName="header-category__button"
                    onClick={this.handleToggleSelect}
                >
                    <div styleName="header-category__content" ref={this.categoryRef}>
                        <UI.Heading
                            level="2.1"
                        >
                            {this.renderCategoryTitle()}
                        </UI.Heading>

                        <div styleName="header-category__arrow-container">
                            <UI.Arrow />
                        </div>
                    </div>
                </button>

                <div styleName={filtersIconClasses}>
                    <UI.FiltersButton
                        onClick={this.handleClickFilters}
                    />
                </div>

                <Portal>
                    <Transition
                        in={isSelectOpen}
                        appear
                        timeout={500}
                    >
                        {(transitionState) => (
                            <MobileCategoriesSelect
                                transitionState={transitionState}
                                setDishesUpdatingState={setDishesUpdatingState}
                                onClickClose={this.handleToggleSelect}
                                basketQuery={basketQuery}
                            />
                        )}
                    </Transition>
                </Portal>
                {this.renderNewsBanner(canShowSoldOutNotification)}
            </>
        );
    }
}

function HeaderCategoryFC(props) {
    return (<HeaderCategory {...props} />);
}

export default HeaderCategoryFC;

HeaderCategory.propTypes = {
    onClickFilter: PropTypes.func.isRequired,

    showBestSetsTitle: PropTypes.bool.isRequired,
    categoryTitle: PropTypes.string.isRequired,

    mainDishesCountState: PropTypes.string.isRequired,
    isSoldOutNotificationVisible: PropTypes.bool,
    onClickCloseSoldOutNotification: PropTypes.func.isRequired,

    setDishesUpdatingState: PropTypes.func.isRequired,
    isCategoryChanging: PropTypes.bool.isRequired,
};

HeaderCategory.defaultProps = {
    isSoldOutNotificationVisible: false,
};
