/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
import React, { useCallback, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { getAddressForQuery } from 'app/utils/address';
import { TButtonUiState, TInputUiState } from 'app/components/ui/input/UiKItUiInput.types';
import { DeliveryZoneCard } from './components/DeliveryZoneCard';
import { DeliveryZoneTerms } from './components/DeliveryZoneTerms';

/* REQUESTS */
import {
    INIT_DELIVERY_ZONE_ADDRESS,
    INIT_DELIVERY_ZONE_INTERVALS,
    MUTATE_SESSION_ADDRESS_FROM_DELIVERY_ZONE_PAGE,
} from './graphql/deliveryZone.graphql';

/* STYLES */
import css from './deliveryZonePage.module.scss';
import { IDeliveryZonePageProps } from './DeliveryZonePage.type';

const DeliveryZonePage = (props: IDeliveryZonePageProps) => {
    const {
        mutateDZoneSessionAddress,
        setUIStateForEmptyAddress,
        externalInputUiState,
        externalButtonUiState,
        externalInputLabel,
    } = props;

    return (
        <div className={css.deliveryZonePageRoot}>
            <DeliveryZoneCard
                mutateDZoneSessionAddress={mutateDZoneSessionAddress}
                setUIStateForEmptyAddress={setUIStateForEmptyAddress}
                externalInputUiState={externalInputUiState}
                externalButtonUiState={externalButtonUiState}
                externalInputLabel={externalInputLabel}
            />
            <DeliveryZoneTerms />
        </div>
    );
};


function DeliveryZoneDAC() {
    const [externalInputUiState, setExternalInputUiState] = useState<TInputUiState>('validation');
    const [externalButtonUiState, setExternalButtonUiState] = useState<TButtonUiState>('validation');
    const [externalInputLabel, setExternalInputLabel] = useState<string>('Укажите адрес доставки');

    const setUIStateForEmptyAddress = useCallback((errorText = 'Адрес вне зоны доставки') => {
        setExternalInputUiState('error');
        setExternalButtonUiState('error');
        setExternalInputLabel(errorText);
    }, []);

    const setUIStateByAddress = useCallback((address: any) => {
        if (!address?.addressValue) {
            setExternalInputUiState('default');
            setExternalButtonUiState('empty');
        } else if (address?.city && address?.street) {
            setExternalInputUiState('default');
            setExternalButtonUiState('confirmed');
        } else {
            setUIStateForEmptyAddress();
        }
    }, [setUIStateForEmptyAddress]);


    const [
        lazyFetchInitialDeliveryIntervals,
    ] = useLazyQuery(INIT_DELIVERY_ZONE_INTERVALS, {
        onCompleted: ({ deliveryIntervals, deliveryAddress }) => {
            const { address: { street } } = deliveryAddress;

            if (deliveryIntervals.region_name) {
                setExternalButtonUiState(street ? 'confirmed' : 'empty');
                setExternalInputUiState(street ? 'confirmed' : 'default');
                setExternalInputLabel('Укажите адрес доставки');
            } else {
                setExternalButtonUiState('error');
                setExternalInputUiState('error');
                setExternalInputLabel('Адрес вне зоны доставки');
            }
        },
        fetchPolicy: 'network-only',
        context: {
            step: 'deliveryZone:lazy:DeliveryZoneDAC',
        },
    });

    const { loading } = useQuery(INIT_DELIVERY_ZONE_ADDRESS, {
        onCompleted: ({ deliveryAddress }) => {
            // setUIStateByAddress(deliveryAddress?.address);

            if (deliveryAddress?.address?.addressValue) {
                lazyFetchInitialDeliveryIntervals({
                    variables: {
                        address: {
                            address: deliveryAddress.address.addressValue,
                        },
                    },
                });
            }
        },
        nextFetchPolicy: 'standby',
        context: {
            step: 'deliveryZone:init:DeliveryZoneDAC',
        },
    });

    const [mutateDZoneSessionAddress] = useMutation(
        MUTATE_SESSION_ADDRESS_FROM_DELIVERY_ZONE_PAGE, {
            onCompleted: ({ shipping }) => {
                setExternalButtonUiState('validation');
                setExternalInputUiState('validation');
                const { address: { street, city, house } } = shipping;
                const nextAddressValue = getAddressForQuery({ street, city, house });
                lazyFetchInitialDeliveryIntervals({
                    variables: {
                        address: {
                            address: nextAddressValue,
                        },
                    },
                });
            },
            context: {
                step: 'DeliveryZone:mutate:DeliveryZoneStreetForm',
            },
            // NOTE: Оказывается мы не можем получить доспуп к client филдам в refetchQueries???
            awaitRefetchQueries: true,
        },
    );

    if (loading) {
        return null;
    }

    return (
        <DeliveryZonePage
            mutateDZoneSessionAddress={mutateDZoneSessionAddress}
            setUIStateForEmptyAddress={setUIStateForEmptyAddress}
            externalInputUiState={externalInputUiState}
            externalButtonUiState={externalButtonUiState}
            externalInputLabel={externalInputLabel}
        />
    );
}

const DRAFT_DeliveryAreasPage = React.memo(() => {
    return <DeliveryZoneDAC />;
});

export { DRAFT_DeliveryAreasPage };


// for react lazy
export default DRAFT_DeliveryAreasPage;
