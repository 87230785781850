/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import {
    locales,
    PORTION_UM_ALIAS_TYPE,
} from 'app/const/umAlias';

import { basketImageSize, getImageUrl } from 'app/utils/dish';
import pluralize from 'app/utils/pluralize';

import DishImage from 'app/components/DishImage';
import DishTitle from '../DishTitle';

import './dish-item-trial.scss';


const itemLocales = {
    ru: {
        time: 'мин',
    },
    en: {
        time: 'min',
    },
};


const DELETING_DISH_TIMEOUT = 1300;


export default class DishItemTrial extends React.PureComponent {
    state = {
        isMounted: false,
    }

    componentDidMount() {
        this.setState({ isMounted: true });
    }

    // Handlers ================================================================

    handleChangePortion = ({ value }) => {
        const { onChangePortion, dishItem: { dish: { id } } } = this.props;
        onChangePortion({ id, value });
    }

    handleClickMobileDishDetails = () => {
        const {
            onClickMobileDishDetails,
            isSubscriptionActivated,
            dishItem,
        } = this.props;

        onClickMobileDishDetails({
            item: {
                ...dishItem,
                isSubscriptionActivated,
            },
        });
    }

    // Render = ================================================================

    renderImage() {
        const { dishItem } = this.props;
        const { dish: { title, previewImage } } = dishItem;

        const imageUrl = getImageUrl({ url: previewImage, ...basketImageSize });
        return (
            <div styleName="basket__dish-item__image">
                <DishImage url={imageUrl} alt={title} />
            </div>
        );
    }

    renderImageWrapper() {
        return (
            // in chrome image inside button doesn't stretch for full button's height/width
            // eslint-disable-next-line
            <div
                type="button"
                styleName="basket__dish-item__image--container"
                arial-label="Показать детали блюда"
                onClick={this.handleClickMobileDishDetails}
            >
                {this.renderImage()}
            </div>
        );
    }

    renderTitle(dishItem) {
        const {
            dish: {
                title, titleEn, caption, captionEn,
            },
        } = dishItem;
        const { locale } = this.props;

        const dishTitle = locale === 'en' ? (titleEn || title) : title;
        const dishCaption = locale === 'en' ? (captionEn || caption) : caption;

        return (
            <button
                type="button"
                onClick={this.handleClickMobileDishDetails}
                arial-label="Показать детали блюда"
                styleName="basket__dish-item__title-container"
            >
                <DishTitle
                    title={dishTitle}
                    caption={dishCaption}
                    linesCount={2}
                />
            </button>
        );
    }

    renderDishItem({ transitionState }) {
        const {
            dishItem,
            isLastItem,
            portionAlias,
            locale,
        } = this.props;

        const {
            dish: {
                id,
                cooking_time: cookingTime,
            },
            portions,
        } = dishItem;

        const [UM_ALIAS_TYPE] = portionAlias || [PORTION_UM_ALIAS_TYPE];
        const portionAliases = locales[locale][UM_ALIAS_TYPE] || locales[locale][PORTION_UM_ALIAS_TYPE];
        const portionText = pluralize(portions, portionAliases);

        const rootClasses = classNames({
            'basket__dish-item': true,
            [transitionState]: true,
            'is-last-item': isLastItem,
        });

        const cookTime = cookingTime / 60;

        return (
            <li key={id} styleName={rootClasses} data-test-id="basket-dish-mobile-small">
                {this.renderImageWrapper()}

                <div styleName="basket__dish-item__info--container">
                    <div styleName="basket__dish-item__row first">
                        {this.renderTitle(dishItem)}
                    </div>

                    <div styleName="basket__dish-item__row second">
                        <span styleName="basket__dish-cooking-time">
                            <span styleName="num">{cookTime}</span>
                            <span styleName="num-spacing">&nbsp;</span>
                            {itemLocales[locale].time}
                        </span>
                        <span styleName="basket__dish-item__multiplier">&nbsp;/&nbsp;</span>
                        <span styleName="basket__dish-cooking-time">
                            <span styleName="num">{portions}</span>
                            <span styleName="num-spacing">&nbsp;</span>
                            {portionText}
                        </span>
                    </div>
                </div>
            </li>
        );
    }

    render() {
        const { isMounted } = this.state;

        return (
            <Transition
                in={isMounted}
                appear
                unmountOnExit
                timeout={DELETING_DISH_TIMEOUT}
            >
                {(transitionState) => this.renderDishItem({ transitionState })}
            </Transition>
        );
    }
}


DishItemTrial.propTypes = {
    portionAlias: PropTypes.arrayOf(PropTypes.string),
    dishItem: PropTypes.shape({
        is_promo: PropTypes.bool,
        dish: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            caption: PropTypes.string,
            weight: PropTypes.number,
            portions: PropTypes.arrayOf(PropTypes.number),
            previewImage: PropTypes.string,
            cooking_time: PropTypes.number,
        }),
        portions: PropTypes.number,
        price: PropTypes.number,
    }).isRequired,
    isSubscriptionActivated: PropTypes.bool.isRequired,
    isLastItem: PropTypes.bool,

    scrollbarsRef: PropTypes.shape({}).isRequired,

    onClickMobileDishDetails: PropTypes.func.isRequired,
    onChangePortion: PropTypes.func.isRequired,

    locale: PropTypes.string,
};

DishItemTrial.defaultProps = {
    locale: 'ru',
    portionAlias: null,
    isLastItem: false,
};
