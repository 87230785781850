import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Responsive from 'app/components/Responsive';
// TODO: move here
import MobileNavRegionButton from 'app/views/Header/components/MobileNavRegionButton';

// TODO: move to serarate file
import { CitySelectV2 } from './CitySelectV2/CitySelectV2';
import { SignInButtonContainer } from './SignInButtonContainer';

import css from './spaHeader.module.scss';
import classNames from 'classnames';


interface HeaderActionsProps {
    isAuthed?: boolean,
    history?: RouteComponentProps['history'] | undefined,
}


export function HeaderActions({
    history = undefined,
    isAuthed = false,
}: HeaderActionsProps) {
    const isScalingNeeded = window.location.pathname.includes('profile')
    const isProfile = isScalingNeeded
    const rootClasses = classNames({
        [css.newSpaHeaderNavigation]: true,
        [css.scalingNav]: isScalingNeeded,
        [css.profile]: isProfile
    })
    return (
        <div className={rootClasses}>
            {/* <Responsive
                mobile={<MobileNavRegionButton />}
                desktop={<DesktopCitySelect />}

                desktop={<DesktopCitySelectV2 />}
            /> */}
            <CitySelectV2 />
            <SignInButtonContainer history={history} isAuthed={isAuthed} />
        </div>
    );
}
