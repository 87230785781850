import Raven from 'raven-js';
import { analyticService, userSessionService } from 'global/services';
import { appendTagReaction } from 'app/apollo/reactions/filters';
import { clearCachedInvoiceData } from 'app/utils/invoice';

const onUserKnown = (userQueryRes: any) => {
    const {
        id, name, email, phone, region, filterTags, hasPendingDelivery,
    } = userQueryRes;
    const normolizedEmail = email || `${phone}@unknown.email`;

    if (!hasPendingDelivery) {
        clearCachedInvoiceData();
    }

    // закидываем id в ls
    localStorage.setItem('userId', id);

    // Ставим id в обьект сессии
    userSessionService.setData({ id });

    // Пушим аналитику
    analyticService
        .push({
            eventName: 'setUserId',
            userId: id,
        })
        .push({
            eventName: 'Flocktory_Login',
            eventParams: {
                name,
                email: normolizedEmail,
            },
        });

    // Задаем контекст для sentry
    Raven.setUserContext({
        id, phone, email, region,
    });

    // ставим теги кастомизации в фильтр меню
    filterTags.forEach((tagId: string) => {
        appendTagReaction({ id: tagId });
    });
};

const onUserUnknown = () => { };

/**
 * @description экшены после инициализируемого запроса user'а
 */
export function onUserInited(userQueryRes: any) {
    // console.log(userQueryRes, 'userQueryRes');
    return userQueryRes ? onUserKnown(userQueryRes) : onUserUnknown();
}
