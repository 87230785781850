/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';

import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';
import {
    VIEW_CUSTOMIZATION_COMMENT,
    SAVE_CUSTOMIZATION_COMMENT,
} from 'app/graphql/network/customizationComment';
import {
    ONE_DAYS_IN_UTC_FORMAT,
    TWO_DAYS_IN_UTC_FORMAT,
} from 'app/containers/contexts/menuDates.consts';
import { pickPeriodReaction, resetSelectedFiltersReaction } from '../../../apollo/reactions/filters';


const dialogTexts = {
    ru: {
        strongText: <>Вы точно хотите изменить&nbsp;дату?</>,
        regularText: <>При смене даты не&nbsp;сохранятся блюда в&nbsp;корзине, а&nbsp;также комментарии и&nbsp;выбранные теги</>,
        confirmText: 'Да, изменить',
        rejectText: 'Нет, не надо',
    },
    en: {
        strongText: <>Вы точно хотите изменить&nbsp;дату?</>,
        regularText: <>При смене даты не&nbsp;сохранятся блюда в&nbsp;корзине, а&nbsp;также комментарии и&nbsp;выбранные теги</>,
        confirmText: 'Да, изменить',
        rejectText: 'Нет, не надо',
    },
};


/**
 * Контейнер сам определяет условия отображения диалога.
 * Компонент ничего не рендерит, только пушит оверлей диалога в коллбэке по условию.
 */
const DeliveryDateDialogContainer = React.memo((props) => {
    const {
        basketQuery,
    } = props;

    const prevDateFilterStatusRef = useRef(null);

    const [updateCustomComment] = useMutation(SAVE_CUSTOMIZATION_COMMENT);

    const { locale } = useContext(LocaleContext);
    const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);
    const { state: menuDatesContext, onSelectDate } = useContext(menuDatesState);
    const {
        dateFilterStatus,
        prevDateFilterStatus,
        prevSelectedFilterPeriod,
        filterPeriod,
    } = menuDatesContext;

    const customizationCommentQueryData = useQuery(VIEW_CUSTOMIZATION_COMMENT, {
        context: { step: 'root:init:DeliveryDateDialogContainer' },
    });

    const { data: customizationCommentData } = customizationCommentQueryData;

    const { selectedTags, selectedPeriod } = useSelectedFilters();


    const dateRejectHandler = async () => {
        closeLastOverlays();
        onSelectDate(prevDateFilterStatus, prevSelectedFilterPeriod);

        if (prevSelectedFilterPeriod) {
            pickPeriodReaction({ periodStart: prevSelectedFilterPeriod });
        }
    };

    const dateConfirmHandler = async () => {
        closeLastOverlays();

        resetSelectedFiltersReaction();
        await updateCustomComment({ variables: { comment: '' } });

        await basketQuery.refetch({ clear: true });
    };


    const dialogData = {
        ...dialogTexts[locale],
        oneRowButtons: true,
        onConfirm: dateConfirmHandler,
        onReject: dateRejectHandler,
    };


    const hasDataToClear = [
        !!customizationCommentData?.viewCustomizationComment.comment,
        !!basketQuery?.cart?.sections.length,
        !!selectedTags.length,
    ].some((i) => i);

    const isPeriodWithDaysSelected = selectedPeriod === filterPeriod;
    const isSelectedDayChanged = (prevDateFilterStatusRef.current > 0) && (prevDateFilterStatusRef.current !== dateFilterStatus);

    const firstTwoDaysInFilterPeriod = [ONE_DAYS_IN_UTC_FORMAT, TWO_DAYS_IN_UTC_FORMAT];
    const isFilteredDateWhithinFirstTwoDays = firstTwoDaysInFilterPeriod.includes(dateFilterStatus);

    const needToShowDialog = hasDataToClear
        && isPeriodWithDaysSelected
        && isSelectedDayChanged
        && isFilteredDateWhithinFirstTwoDays;

    useEffect(() => {
        if (needToShowDialog) {
            pushDialogOverlay('delivery_date_dialog', dialogData);
        }
    }, [needToShowDialog, prevDateFilterStatus, pushDialogOverlay, dialogData]);


    // useEffect для хранения предыдущего состояния dateFilterStatus используется,
    // т.к. prevDateFilterStatus работает некорректно (обновляется не моментально).
    // Это приводит к двойному появлению окна диалога.
    // TODO: разобраться, почему prevDateFilterStatus обновляется некорректно
    useEffect(() => {
        prevDateFilterStatusRef.current = dateFilterStatus;
    }, [prevDateFilterStatusRef, dateFilterStatus]);

    return null;
});

export default DeliveryDateDialogContainer;
