/* eslint-disable react/jsx-one-expression-per-line */
import React, {
    useContext, useRef, useEffect, useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSuspenseQuery } from '@apollo/client';
import ScrollLock from 'react-scrolllock';

import LINKS from 'app/const/links';
import { OverlayContextDispatch } from 'app/containers//contexts/overlay.context';
import { LocaleContext, LOCALE_RU, LOCALE_EN } from 'app/containers/LocaleProvider';

import { UIButton, UIHeading } from 'app/components/ui';
import OpenBasketButton from 'app/components/OpenBasketButton';
import MobileCartHeader from 'app/components/MobileCartHeader';
import Dishes from 'app/views/Basket/components/BasketDishes';
import TrialBasketDescription from 'app/views/Basket/components/TrialBasket/TrialBasketDescription';
import MobileDishDetails from 'app/views/MobileDishDetails/MobileDishDetails';
import PREVIEW_CART_FOR_SET from '../../graphql/network/previewCartForSet';

import '../../views/Basket/basket.scss';
import '../../views/Basket/components/BasketCategories/basket-categories.scss';
import '../../views/Basket/components/TrialBasket/styles/trialBasketDescription.scss';
import '../CustomizationComment/customization-comment.scss';
import './basket-preview.scss';


const {
    TRIAL_FIRST_ORDER_SET,
    EVERYDAY_SET,
    PREMIUM_SET,
    OUR_SET,
    PREMIUM_SET_GREEN_VERSION,
} = LINKS;


const noop = () => { };


const textOptions = [
    {
        sets: [TRIAL_FIRST_ORDER_SET?.id],
        title: {
            ru: {
                row_1: 'Пробный сет',
                row_2: 'из 5 ужинов',
            },
            en: {
                row_1: '5 Dishes',
                row_2: 'First Order',
            },
        },
        description: {
            ru: {
                row_1: 'Для знакомства с Elementaree',
                row_2: '- подборка супер-блюд от шефа!',
                row_3: (
                    <>
                        Новым клиентам скидка
                        {/* &nbsp;
                        <span styleName="basket-preview__crossed">{TRIAL_FIRST_ORDER_SET.originalPrice} ₽</span> */}
                    </>
                ),
            },
            en: {
                row_1: 'Get your first 5 dishes pack tomorrow!',
                row_2: (
                    <>
                        Discount for new clients
                        {/* &nbsp;
                        <span styleName="basket-preview__crossed">{TRIAL_FIRST_ORDER_SET.originalPrice} ₽</span> */}
                    </>
                ),
            },
        },
        price: TRIAL_FIRST_ORDER_SET.originalPrice,
        isPriceDynamic: false,
    },
    {
        sets: [OUR_SET?.id],
        title: {
            ru: {
                row_1: 'Топ-5',
                row_2: 'ужинов недели',
            },
            en: {
                row_1: 'ROW 2 EN',
                row_2: 'ROW 2 EN',
            },
        },
        description: {
            ru: {
                row_1: 'Сет из самых интересных блюд недели. Новые вкусы ждут!',
                // row_2: '',
                row_3: (
                    <>
                        Скидка 10% по подписке

                    </>
                ),
            },
            en: {
                row_1: 'Get your first 5 dishes pack tomorrow!',
                row_2: (
                    <>
                        Discount for new clients
                    </>
                ),
            },
        },
        price: '3600',
        isPriceDynamic: true,
    },
    {
        sets: [EVERYDAY_SET.id],
        title: {
            ru: {
                row_1: 'Сет недели',
                row_2: 'из 5 ужинов',
            },
            en: {
                row_1: '5 Dishes',
                row_2: 'Family Pack',
            },
        },
        description: {
            ru: {
                row_1: 'Меню недели по выгодной цене!',
                row_2: 'И мы готовы привезти его уже завтра.',
                row_3: (
                    <>
                        Скидка {EVERYDAY_SET.discountPercent}% по подписке
                        &nbsp;
                        <span styleName="basket-preview__crossed">{EVERYDAY_SET.originalPrice} ₽</span>
                    </>
                ),
            },
            en: {
                row_1: 'Weekly menu from all over the world,',
                row_2: 'delivered tomorrow!',
                row_3: (
                    <>
                        {EVERYDAY_SET.discountPercent}% off for subscribers
                        &nbsp;
                        <span styleName="basket-preview__crossed">{EVERYDAY_SET.originalPrice} ₽</span>
                    </>
                ),
            },
        },
        price: EVERYDAY_SET.discountPrice,
        isPriceDynamic: false,
    },

    {
        sets: [PREMIUM_SET_GREEN_VERSION.id],
        title: {
            ru: {
                row_1: 'Премиум сет',
                row_2: 'из 5 ужинов',
            },
            en: {
                row_1: '5 Dishes',
                row_2: 'Premium Pack',
            },
        },
        description: {
            ru: {
                row_1: 'Устройте ресторан прямо у себя',
                row_2: 'дома с подборкой авторских блюд! ',
                row_3: (
                    <>
                        Скидка {PREMIUM_SET_GREEN_VERSION.discountPercent}% по подписке
                        &nbsp;
                        <span styleName="basket-preview__crossed">{PREMIUM_SET_GREEN_VERSION.originalPrice} ₽</span>
                    </>
                ),
            },
            en: {
                row_1: 'Restaurant-like dishes with homemade',
                row_2: 'sauces by our chief.',
                row_3: (
                    <>
                        {PREMIUM_SET.discountPercent}% off for subscribers
                        &nbsp;
                        <span styleName="basket-preview__crossed">{PREMIUM_SET_GREEN_VERSION.originalPrice} ₽</span>
                    </>
                ),
            },
        },
        price: PREMIUM_SET.discountPrice,
        isPriceDynamic: false,
    },
];

const getTexts = (id, locale) => {
    const options = textOptions.find((op) => op.sets.includes(id));

    const { price, isPriceDynamic } = options;

    return {
        price,
        isPriceDynamic,
        title: options.title[locale],
        description: options.description[locale],
    };
};


const mockingDishesProps = {
    scrollbarsRef: { current: null },

    isSubscriptionActivated: false,
    isCategoryOpened: true,
    isOneItemInCategory: true,

    onChangePortion: noop,
    onRemoveClick: noop,
    onClickMobilePortions: noop,
    openDishDetails: noop,
};


const SetTitle = ({
    onChooseSet, locale, texts, price,
}) => (
    <div styleName="basket-trial-description__title basket-preview__top-area__first-row">
        <UIHeading level="3.2" styleName="basket-preview__title">
            {texts.row_1}
            <br />
            {texts.row_2}
        </UIHeading>

        {/* <UIButton
            onClick={onChooseSet}
            aria-label="Заполнить корзину"
            styleName="basket-preview__set-button"
            sizeType="medium"
        >
            <span className="price">
                {locale === 'en' && (<span className="rub">₽</span>)}
                <span className="num">{price}</span>
                {locale === 'ru' && (<span className="rub">₽</span>)}
            </span>
        </UIButton> */}
    </div>
);

SetTitle.propTypes = {
    onChooseSet: PropTypes.func.isRequired,
    locale: PropTypes.oneOf([LOCALE_RU, LOCALE_EN]).isRequired,
    texts: PropTypes.shape({
        row_1: PropTypes.string,
        row_2: PropTypes.string,
    }).isRequired,
    price: PropTypes.number,
};


const BasketPreview = (props) => {
    const {
        setId,
        period,
        setType,
        onChooseSet,
        onClickBack,
        basketQuery: {
            cart: {
                sections,
            },
        },
    } = props;

    const { locale } = useContext(LocaleContext);
    const { pushOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    const scrollableContentRef = useRef({});
    const [isMounted, setIsMounted] = useState(false);

    const isBasketEmpty = sections.length === 0;

    const {
        title, description, price: priceValue, isPriceDynamic,
    } = useMemo(
        () => getTexts(setId, locale, period),
        [setId, locale, period],
    );

    const queryVars = {
        period,
        set_type: setType,
    };

    const {
        loading, error, data,
    } = useSuspenseQuery(PREVIEW_CART_FOR_SET, {
        variables: queryVars,
        context: {
            step: 'menu:init:BasketPreview',
        },
        fetchPolicy: 'network-only',
    });

    const previewSections = data?.previewCartForSet?.sections;
    const price = data?.previewCartForSet?.totals?.total_common_price;
    const previewTotals = data?.previewCartForSet.totals;
    const {
        total_common_price: totalCommonPriceFromPreview,
        static_common_price: staticCommonPriceFromPreview,
    } = previewTotals || {};

    const handleClickMobileDishDetails = useCallback((mobileDishDetailsInfo) => pushOverlay({
        id: 'dish_details',
        template: 'bottom',
        children: (
            <MobileDishDetails
                info={mobileDishDetailsInfo}
                onClose={closeLastOverlays}
            />
        ),
    }), [closeLastOverlays, pushOverlay]);


    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, [setIsMounted]);

    const rootClasses = classNames({
        basket: true,
        'on-discount-counter-unmount': !isBasketEmpty,
    });

    const scrollContainerClasses = classNames({
        'basket-categories__scroll-content': true,
        'basket-categories__scroll-content--preview': true,
        basket__dishes: true,
    });

    const categoryClasses = classNames({
        'basket__dishes-type': true,
        preview: true,
        open: true,
        small: true,
    });

    const preparedBasketAreas = {
        basketStyleModifier: 'PREVIEW_SET',
        list: {
            titleType: 'regular',
        },
    };

    return (
        <div styleName={rootClasses}>
            <div styleName="basket-preview__content-wrapper">
                <div styleName="basket-header">
                    <MobileCartHeader
                        isLeftBlockShown
                        isRightBlockShown={false}
                        onClickBack={onClickBack}
                        position="fixed"
                    />
                </div>

                <TrialBasketDescription
                    texts={description}
                    price={isPriceDynamic ? totalCommonPriceFromPreview : priceValue}
                    staticCommonPrice={isPriceDynamic ? staticCommonPriceFromPreview : undefined}
                    TitleComponent={(
                        <SetTitle
                            onChooseSet={onChooseSet}
                            locale={locale}
                            texts={title}
                            price={isPriceDynamic ? totalCommonPriceFromPreview : priceValue}
                        />
                    )}
                />

                {isMounted && (
                    <ScrollLock touchScrollTarget={scrollableContentRef.current} />
                )}

                <div styleName={scrollContainerClasses} ref={scrollableContentRef}>
                    <div styleName={categoryClasses}>
                        {!(loading || error) && (
                            <Dishes
                                items={previewSections[0].items}
                                locale={locale}
                                isEditEnabled={false}
                                preparedBasketAreas={preparedBasketAreas}
                                onClickMobileDishDetails={handleClickMobileDishDetails}
                                sectionId={previewSections[0].id}
                                {...mockingDishesProps}
                            />
                        )}
                    </div>
                </div>
                <div styleName="basket-footer">
                    <OpenBasketButton
                        price={price}
                        position="relative"
                        onClick={onChooseSet}
                        loading={false}
                        title=""
                        showPlusIcon
                    />
                </div>
            </div>
        </div>
    );
};

BasketPreview.propTypes = {
    setId: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    setType: PropTypes.string.isRequired,

    onChooseSet: PropTypes.func.isRequired,
    onClickBack: PropTypes.func.isRequired,
    basketQuery: PropTypes.shape({
        cart: PropTypes.shape({
            sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
            totals: PropTypes.shape({
                total_common_price: PropTypes.number.isRequired,
                static_common_price: PropTypes.number.isRequired,
            }),
        }),
    }).isRequired,
};

export default BasketPreview;
