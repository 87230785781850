import React from 'react';
import { useFragment } from '@apollo/client';
import classNames from 'classnames';
import { PARTNER_AD_FIELDS } from './graphql/dishAdLabelOperations.graphql';
import './DishAdLabel.scss';

const DishAdLabel = (props) => {
    const { id, source } = props;

    const { data: partnerAdFields } = useFragment({
        fragment: PARTNER_AD_FIELDS,
        from: {
            __ref: `menuDish:${id}`,
        },
    });

    if (!partnerAdFields) {
        return null;
    }

    const { partnerAdSignature, partnerPhotoBackground } = partnerAdFields;

    const dishAdRootClasses = classNames({
        'dish-ad-lable-root': true,
        [source]: true,
    });

    const dishAdBgClasses = classNames({
        'dish-ad-lable-bg': true,
        [partnerPhotoBackground]: true,
    });
    const dishAdSignatureClasses = classNames({
        'dish-ad-lable-signature': true,
        [partnerPhotoBackground]: true,
    });

    return (
        <div styleName={dishAdRootClasses}>
            <div styleName={dishAdBgClasses}>
                <div styleName={dishAdSignatureClasses}>
                    {partnerAdSignature}
                </div>
            </div>
        </div>
    );
};

DishAdLabel.defaultProps = {
    source: 'init',
};

export { DishAdLabel };
