import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
import {
    parseCategories, parseDishes,
} from 'app/utils/basketSchema';

import {
    period as nyPeriod,
    extraPeriodCustomStart,
    extraPeriodNYStart,
} from 'global/const/ny';


export function getPeriod({
    periodFromUrl, allPeriods, location, menuDates,
}) {
    const isLinkToBasket = /basket|checkout/.test(location.pathname);
    const hasDishesSchema = Boolean(parseDishes(location));
    const hasCategoriesSchema = Boolean(parseCategories(location));
    const hasTrialScenario = Boolean(getTrialScenarioFromUrl(location));

    const allVisiblePeriods = allPeriods.filter(({ isVisibleInFilter, start }) => {
        const datesForPeriod = menuDates.rawDates.find((data) => {
            const periodStart = start === extraPeriodNYStart ? extraPeriodCustomStart : start;
            return data.startDate === periodStart;
        });

        if (!datesForPeriod) return false;
        if (datesForPeriod.eeAvailableDates.length === 0 && datesForPeriod.startDate !== nyPeriod) return false;

        return isVisibleInFilter === 'visible';
    });

    let allFilteredPeriods = allVisiblePeriods;

    if (hasTrialScenario) {
        allFilteredPeriods = allPeriods.filter(({ start }) => {
            const datesForPeriod = menuDates.rawDates.find((data) => data.startDate === start);

            if (!datesForPeriod) return false;
            if (datesForPeriod.trialAvailableDates.length === 0) return false;

            return true;
        });
    } else if (isLinkToBasket && (hasDishesSchema || hasCategoriesSchema)) {
        /*
            TODO: возможно, для схем с menu=mix или вообще без параметра menu должен подставляться только visible период
        */
        allFilteredPeriods = allPeriods.filter(({ isVisibleInFilter, start }) => {
            const datesForPeriod = menuDates.rawDates.find((data) => data.startDate === start);

            const datesType = hasDishesSchema ? 'topAvailableDates' : 'everydayAvailableDates';

            if (!datesForPeriod) return false;
            if (datesForPeriod[datesType].length === 0) return false;

            return isVisibleInFilter !== 'disabled';
        });
    }

    if (allFilteredPeriods.length === 0) {
        allFilteredPeriods = allVisiblePeriods;
    }

    let [period] = allFilteredPeriods;

    if (periodFromUrl) {
        const matchedPeriod = allFilteredPeriods.find(p => p.start === periodFromUrl);
        if (matchedPeriod) {
            period = matchedPeriod;
        }
    }

    return period;
}


export default {
    getPeriod,
};
