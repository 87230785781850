/* eslint-disable camelcase */
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { selectedPeriodVar } from 'app/apollo/reaction';
import client from 'app/apollo/client';
import { MUTATE_CART_FROM_BASKET, FULL_CART_FRAGMENT } from './graphql/useCartMutationInBasket.graphql';

const readBasketDishData = (id) => {
    const cartItem = client.readFragment({
        id: `cartItem:${id}`,
        fragment: gql`
            fragment GET_FULL_CART_ITEM on cartItem {
                ...ROOT_CART_DISH_ITEM
                dish {
                ...ROOT_MENU_DISH_ITEM
                }
            }
        `,
    });
    return cartItem;
};

const readMenuDishData = (id) => {
    const menuDish = client.readFragment({
        id: `menuDish:${id}`,
        fragment: gql`
            fragment GET_FULL_MENU_DISH on menuDish {
                ...ROOT_MENU_DISH_ITEM
            }
        `,
    });
    return menuDish;
};

const readCurrentCart = (period) => {
    const cart = client.readFragment({
        id: `cart:${period}`,
        fragment: FULL_CART_FRAGMENT,
    });
    return cart;
};

const swipeDishOptimisticUpdate = (dishes = [], cart) => {
    if (!dishes.length || !cart) return null;

    const dishForDelete = dishes.find((dish) => !dish.portions);
    const dishForAdd = dishes.find((dish) => !!dish.portions);
    const dishForDeleteData = readBasketDishData(dishForDelete.dish_id);
    const dishForAddData = readMenuDishData(dishForAdd.dish_id);

    const sourceSection = cart?.sections?.find(
        (section) => String(section.id) === String(dishForAddData?.categoryId),
    );

    const nextItemsList = sourceSection?.items?.map((item) => {
        if (item.dish_id === dishForDeleteData.dish_id) {
            return { ...item, dish_id: dishForAddData.id, dish: dishForAddData };
        }
        return item;
    });

    const nextSections = cart.sections.map((section) => {
        if (section.id === sourceSection.id) {
            return { ...sourceSection, items: nextItemsList };
        }
        return section;
    });

    return {
        addItemsToCartByPortionsList: {
            cart: {
                ...cart,
                sections: nextSections,
            },
            success: true,
            __typename: 'addItemsToCartResult',
        },
    };
};

const changePortionOptimisticUpdate = (dishes = [], cart) => {
    if (!dishes.length || !cart) return null;
    const [dish] = dishes;
    const dishData = readBasketDishData(dish.dish_id);

    const sourceSection = cart?.sections?.find(
        (section) => String(section.id) === String(dishData?.dish?.categoryId),
    );

    const nextItemsList = sourceSection?.items?.map((item) => {
        if (item.dish_id === dish.dish_id) {
            const {
                portions, price, discount_price, promoDiscountPrice,
            } = item;

            const nextPrice = (price / portions) * dish.portions;
            const nextDiscountPrice = (discount_price / portions) * dish.portions;
            const nextPromoDiscountPrice = (promoDiscountPrice / portions) * dish.portions;
            return {
                ...item,
                portions: dish.portions,
                price: nextPrice,
                discount_price: nextDiscountPrice,
                promoDiscountPrice: nextPromoDiscountPrice,
            };
        }
        return item;
    });

    const nextSections = cart.sections.map((section) => {
        if (section.id === sourceSection.id) {
            return { ...sourceSection, items: nextItemsList };
        }
        return section;
    });


    return {
        addItemsToCartByPortionsList: {
            cart: {
                ...cart,
                sections: nextSections,
            },
            success: true,
            __typename: 'addItemsToCartResult',
        },
    };
};

const ptimisticUpdaterRecord = {
    portion: changePortionOptimisticUpdate,
    swipe: swipeDishOptimisticUpdate,
};

const noop = () => null;

export function useCartMutationInBasket() {
    const [mutateCart, { loading: basketLoading }] = useMutation(MUTATE_CART_FROM_BASKET, {
        context: {
            step: 'menu:mutate:useCartMutationInBasket',
        },
    });
    const period = selectedPeriodVar();

    const mutateItemInCart = ({ dishes, optimistic = null }) => {
        const cart = readCurrentCart(period);
        const optimisticUpdate = ptimisticUpdaterRecord[optimistic] || noop;
        mutateCart({
            variables: {
                dishes,
            },
            optimisticResponse: optimisticUpdate(dishes, cart),
        });
    };

    const isCartUpdating = basketLoading;

    return { isCartUpdating, mutateItemInCart };
}
