import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
// import { useReactiveVar } from '@apollo/client';

// import { selectedPeriodVar } from 'app/apollo/reaction';

import { useDishDetails } from 'app/components/MobileDishDetails';

import {
    abTestDataContext,
    // FETCHED_STATE,
    // ERROR_STATE,
    // BASKET_WITH_GIFT,
    // BASKET_WITH_GIFT_CONTROL,
} from 'app/containers/contexts/abTest.context';

// import { wasaGift, wasaPeriods } from './customGifts/customGifts';
import { BasketCategories } from './BasketCategories';


const dynamicCustomGifts = [];

export function BasketCategoriesContainer({
    onClickMobileDishDetails,
    ...restProps
}) {
    const { openDishDetails } = useDishDetails();
    const abTestData = useContext(abTestDataContext);

    const handleOpenDishDetails = useCallback((dishItemData) => {
        if (window.innerWidth >= 1024) {
            onClickMobileDishDetails(dishItemData);
        } else {
            openDishDetails(dishItemData.item.dish_id);
        }
    }, [onClickMobileDishDetails, openDishDetails]);

    return (
        <BasketCategories
            {...restProps}
            dynamicCustomGifts={dynamicCustomGifts}
            onClickMobileDishDetails={handleOpenDishDetails}
            abTestData={abTestData}
        />
    );
}

BasketCategoriesContainer.propTypes = {
    onClickMobileDishDetails: PropTypes.func.isRequired,
};
