/* eslint-disable import/no-unresolved */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { UIParagraph } from 'app/components/ui';
import { validatePromocode } from 'app/utils/promocode.utils';
import {
    discountConditionsInitialType,
    discountConditionsType,
} from 'types';

import { PROMOCODE_TYPES_PERCENT, PROMOCODE_TYPES_FIXED } from 'app/const/promo';

import './floatFooterDiscountCounter.scss';

// TODO: Отдавать span отступ от знака рублся отличется от процента
const getCharByDiscountType = ({ discountType }) => {
    if (PROMOCODE_TYPES_FIXED.includes(discountType)) {
        return ' ₽';
    }
    if (PROMOCODE_TYPES_PERCENT.includes(discountType)) {
        return '%';
    }
    return '%';
};

/**
 * @param {props} props promocodeConditions promocode
 * @returns boolean
 * @note экпортируется в basket.js для отпределения отступов
 */
export const isFloatFooterDiscountCounter = (props) => {
    const {
        promocodeConditions: {
            isPromoCodeExists, differenceBetweenPromocodeAndOrder, promocode,
        },
        promocodeConditions,
    } = props;

    const hidingErrors = ['durationError', 'subscriptionError'];
    const promocodeState = validatePromocode(promocodeConditions, promocode, {});
    const isPromocodeHiddenByValidationError = hidingErrors.includes(promocodeState.errorType);

    if (isPromocodeHiddenByValidationError) {
        return true;
    }

    const canConfirmOrder = differenceBetweenPromocodeAndOrder <= 0;
    const isPromocodeApplied = Boolean(promocode) && isPromoCodeExists && !canConfirmOrder;

    return !isPromocodeApplied;
};

const DiscountCounter = React.memo(
    (props) => {
        const {
            promocodeConditions: {
                differenceBetweenPromocodeAndOrder, discountValue, discountType,
            },
            isDiscountCounterMount,
        } = props;

        const discountCounterRootClasses = cn({
            'discount-counter-root': true,
            'on-discount-counter-mount': !isDiscountCounterMount,
            'on-discount-counter-unmount': isDiscountCounterMount,
        });
        const discountChar = getCharByDiscountType({ discountType });
        return (
            <div styleName={discountCounterRootClasses}>
                <div styleName="discount-counter-more">
                    <UIParagraph level="5.3">
                        Добавьте ещё на
                        {' '}
                        {differenceBetweenPromocodeAndOrder}
                        {' '}
                        ₽
                    </UIParagraph>
                </div>
                <div styleName="discount-counter-divider">
                    <UIParagraph level="5.3">
                        →
                    </UIParagraph>
                </div>
                <div styleName="discount-counter-value">
                    <UIParagraph level="5.3">
                        Скидка
                        {' '}
                        {discountValue}
                        {discountChar}
                    </UIParagraph>
                </div>
            </div>
        );
    },
);

DiscountCounter.propTypes = {
    promocodeConditions: PropTypes.shape(discountConditionsType),
    isDiscountCounterMount: PropTypes.bool.isRequired,
};

DiscountCounter.defaultProps = {
    promocodeConditions: discountConditionsInitialType,
};

/* FRNT-2618 */
const FloatFooterDiscountCounterWrapper = ({
    promocodeConditions = discountConditionsInitialType,
}) => {
    if (!promocodeConditions) {
        return null;
    }

    const isDiscountCounterMount = isFloatFooterDiscountCounter({ promocodeConditions });

    return (
        <DiscountCounter
            promocodeConditions={promocodeConditions}
            isDiscountCounterMount={isDiscountCounterMount}
        />
    );
};

FloatFooterDiscountCounterWrapper.propTypes = {
    promocodeConditions: PropTypes.shape(discountConditionsType),
};


export default React.memo(FloatFooterDiscountCounterWrapper);
