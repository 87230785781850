/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
// import PropTypes from 'prop-types';

import classNames from 'classnames';
import CustomizationCommentContainer from 'app/containers/CustomizationCommentContainer';
import { isDesktop } from 'app/utils/resolution';
import { UIHeading } from 'app/components/ui';

import Responsive from 'app/components/Responsive';
import TrialBasketDescription from './components/TrialBasket/TrialBasketDescription';
import { locales } from './basket.locales';

import './basket.scss';

const CLOSED = 'closed';
const OPENED = 'opened';

/* COMPONENTS */

const CustomizationComment = (props) => {
    const {
        localeContext: { locale },
        // isEditEnabled,
        isCustomizationCommentAreaShown,
        isCustomizationCommentSidebarShown,
        prearedBasketAreas: { top: { isCustomizationComment, isTopTitle } },
        // ACTION
        toggleCustomizationCommentArea,
        setCustomizationCommentShowingState,
        handleSetIsCustomizationEdited,
    } = props;
    const {
        withCommentTitle, withOutCommentTitle,
    } = locales[locale];


    /** Basket title behavior */
    const mobileTitle = isCustomizationComment ? withCommentTitle : withOutCommentTitle;
    const desktopTitle = isCustomizationComment ? withCommentTitle : withOutCommentTitle;
    const title = isDesktop() ? desktopTitle : mobileTitle;


    // Styled ==============================================================
    const titleClasses = classNames({
        'customization-comment__title': true,
        'is-comment-hidden': isTopTitle,
    });

    return (
        <div styleName="customization-comment">
            <div className="basket-enter-animation__item">
                <div styleName={titleClasses}>
                    <UIHeading level="3.2">{title}</UIHeading>
                </div>
                {isCustomizationComment && (
                    <CustomizationCommentContainer
                        isNeedTooltip
                        isCommentAreaShown={isCustomizationCommentAreaShown}
                        isCommentSidebarShown={isCustomizationCommentSidebarShown}
                        toggleCommentArea={toggleCustomizationCommentArea()}
                        onClosed={setCustomizationCommentShowingState(CLOSED)}
                        onOpened={setCustomizationCommentShowingState(OPENED)}
                        handleSetIsCustomizationEdited={handleSetIsCustomizationEdited}
                    />
                )}
            </div>
        </div>
    );
};

const BasketDesktopTop = (props) => {
    const {
        prearedBasketAreas,
        prearedBasketAreas: { top: { isCustomizationComment, isTopTitle } },
        handleSetIsCustomizationEdited,
    } = props;
    const isCommentAreaShown = isTopTitle || isCustomizationComment;
    return (
        <>
            {isCommentAreaShown
                && (
                    <CustomizationComment
                        {...props}
                        prearedBasketAreas={prearedBasketAreas}
                        handleSetIsCustomizationEdited={handleSetIsCustomizationEdited}
                    />
                )}
        </>
    );
};

const BasketMobileTop = (props) => {
    const {
        localeContext: { locale },
        prearedBasketAreas: { top: { isTrialBasketDescription } },
    } = props;

    return (
        <>
            {isTrialBasketDescription && <TrialBasketDescription locale={locale} />}
        </>
    );
};

const BasketTopArea = (props) => {
    // const { abTestDataContext } = props;
    return (
        <Responsive
            mobile={<BasketMobileTop {...props} />}
            desktop={<BasketDesktopTop {...props} />}
        />
    );
};


export default React.memo(BasketTopArea);
