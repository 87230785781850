import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import Raven from 'raven-js';
import compose from 'lodash/flowRight';

/* NETWORK */
import USER_QUERY from 'app/graphql/network/auth/userQuery';
import { ADD_ITEM_TO_CART, ADD_ITEMS_TO_CART_BY_PERIOD } from 'app/graphql/network/basket/basketMutations';
import billingMutation from 'app/graphql/network/billing';

/* CLIENT */
import { GET_SUBSCRIPTION_TYPE } from 'app/graphql/client/subscription';
import { GET_PAYMENT_METHOD } from 'app/graphql/client/payment';
import { GET_PROMOCODE } from 'app/graphql/client/promocode';
import { DISH_ITEM_TYPE, BASKET_FILLED_STATUS } from 'app/graphql/client/basket';
import { CHECKOUT_DELIVERY_DATE_QUERY, GET_CHECKOUT_DATA } from 'app/graphql/client/checkout';

/* HOCS */
import withPushLead from 'app/containers/withPushLead';
import { withAuthContext } from 'app/containers/AuthContainer';
import { getBillingPaymentMethod } from 'app/containers/withBillingParams';
import { withFiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { removeSchemaParams } from 'app/utils/basketSchema';
import {
    userSessionService,
} from 'global/services';
import { withBasketQuery } from './connectToBasket';
import { withSelectedFilters } from './connectToSelectedFilters';
import { appendTagReaction } from '../apollo/reactions/filters';


/**/
const connect = (Component) => compose(
    withRouter,
    graphql(GET_CHECKOUT_DATA, { name: 'getCheckoutData' }),
    graphql(CHECKOUT_DELIVERY_DATE_QUERY, { name: 'checkoutDeliveryDateQuery' }),
    graphql(DISH_ITEM_TYPE, { name: 'dishItemTypeQuery' }),
    graphql(BASKET_FILLED_STATUS, { name: 'basketFilledStatus' }),
    withSelectedFilters,
    graphql(GET_SUBSCRIPTION_TYPE, { name: 'subscriptionTypeQuery' }),
    graphql(GET_PAYMENT_METHOD, { name: 'paymentMethod' }),
    graphql(GET_PROMOCODE, { name: 'promocode' }),
    graphql(ADD_ITEM_TO_CART, {
        name: 'addToBasket',
        options: (props) => {
            if (props.match.params.deliveryId) return {};
            return {
                onCompleted: () => {
                    const {
                        history,
                        location,
                    } = props;

                    const search = removeSchemaParams(location.search);
                    history.push({
                        ...location,
                        search,
                    });
                },
            };
        },
    }),
    graphql(ADD_ITEMS_TO_CART_BY_PERIOD, { name: 'addItemsToCartByPeriod' }),

    withFiltersContext,
    graphql(USER_QUERY, {
        name: 'userQuery',
        options: {
            onError: (error) => {
                Raven.captureMessage('[Auth]: userQuery auth error', {
                    extra: {
                        error,
                        scope: 'connect.js',
                    },
                    tags: {
                        type: 'Auth error',
                    },
                });
            },
            context: {
                step: 'app:init:connect',
            },
        },
    }),

    withBasketQuery,
    graphql(billingMutation, {
        name: 'updateBilling',
        options: (props) => {
            /*
                Добавляет дефолтные значения параметров, если они не переданы в мутацию явно
            */
            const {
                subscriptionTypeQuery: { subscriptionType },
                paymentMethod: { paymentMethod },
                selectedFilters: { selectedPeriod },
                promocode: { promocode },
            } = props;

            const { billingPaymentMethod } = getBillingPaymentMethod({
                statePaymentMethod: paymentMethod,
                subscriptionType,
            });

            return {
                variables: {
                    promocode,
                    period: selectedPeriod,
                    payment_method: billingPaymentMethod,
                },
            };
        },
    }),
    withPushLead,
    withAuthContext,
)(Component);

export default connect;

/*
    AOLLO REFACTOR

    // graphql(PICK_PERIOD, { name: 'pickPeriod_DONE' }),
    // graphql(PICK_CATEGORY, { name: 'pickCategory_DONE' }),
    // graphql(APPEND_TAG, { name: 'appendTag_DONE' }),
    // graphql(DELETE_TAG, { name: 'deleteTag_DONE' }),
    // graphql(ESTABLISH_TAGS, { name: 'establishTags_DONE' }),
    // graphql(SET_CATEGORIES, { name: 'setCategories_DONE' }),
    // graphql(RESET_SELECTED_FILTERS, { name: 'resetSelectedFilters_DONE' }),
    // graphql(SET_DEFAULT_FILTERS, { name: 'setDefaultFilters_DONE' }),
    // graphql(SET_SUBSCRIPTION_TYPE, { name: 'setSubscriptionType_DONE' }),
    // graphql(GET_DISHES, { name: 'getDishes' }),
    // graphql(CLEAR_DISHES_DATA, { name: 'clearDishesData' }),
    // graphql(SET_PORTION, { name: 'setPortion' }),
    // graphql(SET_CLIENT_PHONE, { name: 'setClientPhone_DONE' }),
    // graphql(SET_PAYMENT_METHOD, { name: 'setPaymentMethod_DONE' }),
    // graphql(SET_PROMOCODE, { name: 'setPromocode_DONE' }),

*/
