import React from 'react';
import queryString from 'query-string';
import { bool, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router';

import { isDesktop } from 'app/utils/resolution';

import { Overlay } from 'app/components/BlurredOverlay';
// import DeliveryPricePopup from 'app/components/DeliveryPricePopup';
import SubscriptionInfoPopup from 'app/components/infoPopup/SubscriptionInfoPopup';
import DeliveryInfoPopup from 'app/components/infoPopup/DeliveryInfoPopup';
import AddressInfoPopup from 'app/components/infoPopup/AddressInfoPopup/AddressInfoPopup';


const getBasePath = (pathname) => {
    const [basePath] = pathname.split('/').filter((e) => Boolean(e));
    return basePath || '';
};

const MainPopupAndDialog = (props) => {
    const {
        openedModal,
        showModalOverlay,
        popUpUiState,
    } = props;

    const location = useLocation();
    const history = useHistory();

    const handleCloseModal = () => {
        const { pathname, search } = location;

        const { modal, ...otherSearchParams } = queryString.parse(search);
        const nextSearch = queryString.stringify(otherSearchParams);

        history.push(`${pathname}?${nextSearch}`);
    };

    const direction = isDesktop() ? 'right' : 'bottom';
    const isNeedBlur = !isDesktop();

    return (
        <>
            {openedModal === 'delivery-info' && (
                <Overlay
                    overlayOpen={showModalOverlay}
                    onClick={handleCloseModal}
                    animateEnter
                    animateExit
                    animationDirection={direction}
                    dialog
                    isNeedBlur={isNeedBlur}
                >
                    <DeliveryInfoPopup
                        onClose={handleCloseModal}
                        popUpUiState={popUpUiState}
                    />
                </Overlay>
            )}
            {openedModal === 'recurrent-info' && (
                <Overlay
                    overlayOpen={showModalOverlay}
                    onClick={handleCloseModal}
                    animateEnter
                    animateExit
                    isNeedBlur={isNeedBlur}
                    animationDirection={direction}
                    render={(componentProps) => (
                        <SubscriptionInfoPopup
                            {...componentProps}
                            onClose={handleCloseModal}
                            popUpUiState={popUpUiState}
                        />
                    )}
                />
            )}
            {openedModal === 'address-info' && (
                <Overlay
                    overlayOpen={showModalOverlay}
                    onClick={handleCloseModal}
                    animateEnter
                    animateExit
                    isNeedBlur={isNeedBlur}
                    animationDirection={direction}
                    render={(componentProps) => (
                        <AddressInfoPopup
                            {...componentProps}
                            onClose={handleCloseModal}
                            popUpUiState={popUpUiState}
                        />
                    )}
                />
            )}
        </>
    );
};

MainPopupAndDialog.propTypes = {
    openedModal: string,
    showModalOverlay: bool.isRequired,
    popUpUiState: string,
};

MainPopupAndDialog.defaultProps = {
    openedModal: 'default',
    popUpUiState: '',
};

export default React.memo(MainPopupAndDialog);
