import { gql } from '@apollo/client';

export const DISH_ITEM_TYPE = gql`
   query dishItemType {
        dishItemType @client
    }
`;

// export const SAVE_DISH_ITEM_TYPE = gql`
//     mutation saveDishItemType_ODNE($dishItemType: String) {
//         saveDishItemType_DONE(dishItemType: $dishItemType) @client
//     }
// `;

export const BASKET_FILLED_STATUS = gql`
   query basketFilledStatus {
        isBasketFilled @client
    }
`;

// export const SAVE_BASKET_FILLED_STATUS = gql`
//     mutation saveBasketFilledStatus_DONE($isBasketFilled: Boolean) {
//         saveBasketFilledStatus_DONE(isBasketFilled: $isBasketFilled) @client
//     }
// `;
