/* eslint-disable max-len */
import { SetDescriptionRecord } from './types';

const SetDescriptionCollection: SetDescriptionRecord = {
    4444: {
        setId: '4444',
        descriptionContent: {
            composition: [
                {
                    title: 'Мясо (курица, свинина)',
                    value: '2',
                    unit: 'кг',
                    hasLimitedWidth: false,
                },
                {
                    title: 'Лаваш, помидоры, огурцы, перцы, редис, зелень',
                    value: '1',
                    unit: 'кг',
                    hasLimitedWidth: true,
                },
                {
                    title: 'Соус от шеф-повара',
                    value: '3',
                    unit: 'шт',
                    hasLimitedWidth: false,
                },
                {
                    title: 'Основа для лимонада',
                    value: '1',
                    unit: 'шт',
                    hasLimitedWidth: false,
                },
            ],
            uiCards: [
                {
                    layoutType: 'small',
                    listType: 'list',
                    iconClassName: 'dacha_delivered',
                    descriptionList: [
                        'нарезанное и замаринованное мясо',
                        'свежие овощи и тонкий лаваш',
                        'домашние соусы от шеф-повара',
                        'основа для лимонада или коктейлей',
                    ],
                    title: 'Что мы доставим',
                    descTitle: 'Коробку подготовленных продуктов для поездки на дачу с шашлыками:',
                },
                {
                    layoutType: 'large',
                    listType: 'list',
                    iconClassName: 'dacha_inside',
                    descriptionList: [
                        'Два килограмма мяса в маринаде для шашлыков — из курицы (бедро) и из свинины (шея)',
                        'Лаваш, помидоры, огурцы, перцы, редис, свежая зелень — всего около 1 кг',
                        'Три домашних соуса от шеф-повара — барбекю, медовая горчица и лимонный с кинзой',
                        'Клубничная основа для домашних лимонадов или коктейлей',
                    ],
                    title: 'Что в наборе',
                },
                {
                    layoutType: 'small',
                    listType: 'paragraph',
                    iconClassName: 'dacha_cooked',
                    descriptionList: [
                        'Дачный пир! На 4-6 человек. Без заморочек и ходьбы по магазинам. А еще — отлично проведенное время на даче в компании друзей, шашлыков и природы, вдали от города.',
                    ],
                    title: 'Что получится',
                },
            ],
        },
    },
};

export default SetDescriptionCollection;
