import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import {
    ISODate, AvailableDate, TimeInterval, Colors,
} from './DatetimeSelect.types';

import { DateSelect } from './DateSelect';
import { DeliveryPrice } from './DeliveryPrice';
import { TimeSelect } from './TimeSelect';

import css from './datetimeSelect.module.scss';


interface DatetimeSelectProps {
    colors?: Colors,
    disabled?: boolean,
    deliveryDates?: AvailableDate[],
    selectedDate?: ISODate,
    onSelectDate: (nextDate: ISODate) => void, // eslint-disable-line no-unused-vars
    deliveryPrice: number,
    timeIntervals: TimeInterval[],
    selectedIntervalIndex: number,
    onSelectInterval: (nextIndex: number) => void, // eslint-disable-line no-unused-vars
    limitTimeIntervalsHeight?: boolean,
    responsive?: boolean,
}

export function DatetimeSelect({
    colors = 'grey',
    disabled = false,
    deliveryDates = [],
    selectedDate = '',
    onSelectDate,
    deliveryPrice,
    timeIntervals,
    selectedIntervalIndex,
    onSelectInterval,
    limitTimeIntervalsHeight = false,
    responsive = false,
}: DatetimeSelectProps) {
    const [hasScroll, setHasScroll] = useState(false);
    const [isFullyScrolled, setIsFullyScrolled] = useState<boolean>(false);

    const intervalsRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const c = intervalsRef.current;

        if (!c) return;
        if (!limitTimeIntervalsHeight) return;

        const hs = c.clientHeight < c.scrollHeight;
        setHasScroll(hs);
    }, [limitTimeIntervalsHeight]);

    useLayoutEffect(() => {
        const c = intervalsRef.current;
        if (!c) return undefined;
        const handleScroll = () => {
            const fullyScrolled = c.scrollTop + c.clientHeight >= c.scrollHeight;
            setIsFullyScrolled(fullyScrolled);
        };
        c.addEventListener('scroll', handleScroll);
        return (): void => {
            c.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const timeIntervalsClasses = cn({
        [css.timeSelectListContainer]: true,
        [css.timeSelectListContainerLimited]: limitTimeIntervalsHeight,
        [css.timeSelectListContainerHasScroll]: hasScroll,
    });

    return (
        <div className={responsive ? css.responisveContainer : ''}>
            <div>
                <DateSelect
                    colors={colors}
                    disabled={disabled}
                    deliveryDates={deliveryDates}
                    selectedDate={selectedDate}
                    onSelectDate={onSelectDate}
                    responsive={responsive}
                />
            </div>
            <div className={css.timeSelectContainer}>
                <DeliveryPrice
                    deliveryPrice={deliveryPrice}
                    responsive={responsive}
                    noBorder={limitTimeIntervalsHeight}
                />
                <div
                    className={timeIntervalsClasses}
                    ref={intervalsRef}
                >
                    <TimeSelect
                        disabled={disabled}
                        timeIntervals={timeIntervals}
                        selectedIntervalIndex={selectedIntervalIndex}
                        onSelectInterval={onSelectInterval}
                        responsive={responsive}
                    />
                </div>
                {!isFullyScrolled && limitTimeIntervalsHeight && hasScroll && (
                    <div className={css.timeSelectListContainerShadow} />
                )}
            </div>
        </div>
    );
}
