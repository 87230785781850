import {
    useEffect, useContext, useState, useCallback, useMemo,
} from 'react';
import { abTestDataContext, REPLACE_POPULAR_CUSTOM } from 'app/containers/contexts/abTest.context';
import {
    ReplacePopularCustomSetsEnum,
    TagsContentForABDialog,
    customizationTagsForABTest,
    customizationTagsThrowingOutFromABTest,
} from 'app/views/Basket/enums/basket';
import { findMissingTag } from 'app/views/Basket/utils/findMissingTag';

export const useCustomContradictionCheck = (cart, userCustomizationTags) => {
    const typeOfSet = cart?.typeOfSet;

    const [dialogIngridient, setDialogIngridient] = useState(null);
    const [isCustomizationDialogShown, setIsCustomizationDialogShown] = useState(false);

    const { fetchABTestValue, replace_popular_custom: replacePopularCustom } = useContext(abTestDataContext);

    const searchForCustomizationContradiction = useCallback(() => {
        const sections = cart?.sections ?? [];
        const missingTags = findMissingTag(userCustomizationTags, sections);
        return missingTags;
    }, [cart?.sections, userCustomizationTags]);

    const hasOtherTagsInAccount = useMemo(
        () => !userCustomizationTags.every((tag) => customizationTagsThrowingOutFromABTest.includes(Number(tag))),
        [userCustomizationTags],
    );

    const findMostImportantContradictionTag = (missingTags) => {
        const missingTagsFromABTestEnum = [];
        missingTags.forEach((tag) => {
            if (customizationTagsForABTest.includes(Number(tag))) {
                missingTagsFromABTestEnum.push(tag);
            }
        });
        if (missingTagsFromABTestEnum) {
            // Пока берем просто первый
            return missingTagsFromABTestEnum[0];
        }
        return null;
    };

    const checkIsСontraditionSearchNeeded = useCallback(() => {
        const isСontraditionSearchNeeded = Boolean(
            ReplacePopularCustomSetsEnum[typeOfSet] !== undefined
            && userCustomizationTags.length
            && !hasOtherTagsInAccount,
        );
        return isСontraditionSearchNeeded;
    }, [replacePopularCustom, typeOfSet, userCustomizationTags, hasOtherTagsInAccount]);

    const checkForCustomizationContradiction = useCallback(() => {
        const isСontraditionSearchNeeded = checkIsСontraditionSearchNeeded();

        if (!isСontraditionSearchNeeded) {
            sessionStorage.removeItem('with_customization');
            return;
        }

        const missingTags = searchForCustomizationContradiction();
        const hasCustomizationContradiction = Boolean(missingTags.length);

        const mostImportantContradictionTag = findMostImportantContradictionTag(missingTags);
        const tagFromEnumExists = mostImportantContradictionTag !== null;

        if (hasCustomizationContradiction && (mostImportantContradictionTag in TagsContentForABDialog)) {
            setDialogIngridient(TagsContentForABDialog[mostImportantContradictionTag]);
        }

        const isShowContradictionDialogNeeded = Boolean(mostImportantContradictionTag) && tagFromEnumExists;

        if (isShowContradictionDialogNeeded) {
            setIsCustomizationDialogShown(true);
            return;
        }
        sessionStorage.removeItem('with_customization');
    }, [checkIsСontraditionSearchNeeded, searchForCustomizationContradiction]);

    return {
        isCustomizationDialogShown,
        setIsCustomizationDialogShown,
        dialogIngridient,
        checkForCustomizationContradiction,
        checkIsСontraditionSearchNeeded,
    };
};
