import React, { FC } from 'react';
import css from './BonusesNotifier.module.scss';
import BonusesIcon from './img/bonuses.component.svg';
import LinkIcon from './img/link.component.svg';

interface IBonusesNotifier {
    isUserAuthorized: boolean,
    bonusesAmount: number,
    type?: 'basket' | 'checkout' | 'thx',
}

const authorizedUserContent = {
    basket: {
        title: 'Начислим бонусами',
        subtitle: '',
    },
    checkout: {
        title: 'Начислим бонусами',
        subtitle: '',
    },
    thx: {
        title: 'Бонусы',
        subtitle: 'после получения заказа',
    },
};

export const BonusesNotifier: FC<IBonusesNotifier> = ({
    type = 'basket', bonusesAmount,
    isUserAuthorized,
}: IBonusesNotifier) => (
    <div className={css.notifierWrapper}>
        {isUserAuthorized
            ? (
                <>
                    {type === 'thx' && (
                        <div className={css.notifierContentContainer}>
                            <BonusesIcon className={css.bonusesIconLarge} />
                            <div className={css.notifierContent}>
                                <div className={css.notifierText}>
                                    <span>{authorizedUserContent[type].title}</span>
                                    <div className={css.bonusesAmountCont}>
                                        <span className={css.bonusesAmount}>
                                            {` +${bonusesAmount}`}
                                        </span>
                                        <BonusesIcon className={css.bonusesIconSmall} />
                                    </div>
                                </div>
                                <div className={css.notifierText}>{authorizedUserContent[type].subtitle}</div>
                            </div>
                        </div>
                    )}
                    {(type === 'basket' || type === 'checkout')
                        && (
                            <div className={css.notifierSimpleContent}>
                                <div className={css.notifierText}>{authorizedUserContent[type].title}</div>
                                <div className={css.bonusesAmountCont}>
                                    <span className={css.bonusesAmount}>
                                        {` +${bonusesAmount}`}
                                    </span>
                                    <BonusesIcon className={css.bonusesIconSmall} />
                                </div>
                            </div>
                        )}
                </>
            )
            : (
                <div className={css.notifierContentContainer}>
                    <BonusesIcon className={css.bonusesIconLarge} />
                    <div className={css.notifierContent}>
                        <span className={css.notifierTextSmall}>Чтобы получить бонусы</span>
                        <div className={css.notifierTextSmallBold}>войдите в аккаунт</div>
                    </div>
                    <LinkIcon className={css.linkIcon} />
                </div>
            )}
    </div>
);
