/* eslint-disable no-underscore-dangle */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';

import {
    WITHOUT_DELIVERIES,
    WITH_PAST_DELIVERIES,
    WITH_ACTIVE_DELIVERIES,
} from 'app/utils/user/user';
import { isStandaloneApp } from 'app/utils/browser';
import { getAvailableLinksWithUrl, getAvailablePeriods } from 'app/utils/links';

import LINKS from 'app/const/links';
import FILTER_QUERY from 'app/graphql/network/filter';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { AvailableLinksContext } from 'app/containers/contexts/availableLinks.context';
import { UIHeading } from 'app/components/ui';

import Arrow from './icons/arrow.component.svg';

import { OrdinaryLink } from './MobileSiteNavOrdinaryLink';
import { getDropdownInfoLinks, getTopLevelLinks, getDropdownSetLinks } from './linkOptions.record';
import { locales } from './mobile-site-nav-ordinary-links.locales';

import './mobile-site-nav-ordinary-links.scss';


const { EE_MAIN } = LINKS;


const _setPeriodQueryParamToSpaLink = (eeLink) => {
    const { location: { search } } = window;

    if (search) {
        const { data: { href } } = eeLink;
        return {
            ...eeLink,
            data: {
                ...eeLink.data,
                href: `${href}${search}`,
            },
        };
    }
    return eeLink;
};

// eslint-disable-next-line arrow-body-style
const AllOrdinaryLinks = ({ ordinaryLinkList }) => {
    const mapedOrdinaryLinkList = ordinaryLinkList.map((linkItem) => {
        if (linkItem.id === 'SPA_MENU') return _setPeriodQueryParamToSpaLink(linkItem);
        return linkItem;
    });

    return (
        <div styleName="mobile-nav__ordinary-links-container">
            <ul styleName="mobile-nav__ordinary-links">
                {mapedOrdinaryLinkList.map(({ data, id }) => (
                    <li styleName="mobile-nav__ordinary-item" key={id}>
                        <OrdinaryLink {...data} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

const DropdownList = ({
    // eslint-disable-next-line react/prop-types
    dropdownLinksOptions, isDropdownListShown, toggleDropdownListState, title,
}) => {
    const { translate } = useContext(LocaleContext);

    const dropdownArrowClasses = classNames({
        'mobile-nav__dropdown-arrow-container': true,
        expanded: isDropdownListShown,
    });
    const dropdownListClasses = classNames({
        'mobile-nav__dropdown-list': true,
        expanded: isDropdownListShown,
    });

    return (
        <div styleName="mobile-nav__dropdown-list-container">
            <button
                styleName="mobile-nav__ordinary-item button"
                onClick={toggleDropdownListState}
                type="button"
                aria-label="Развернуть"
            >
                <UIHeading level="4.1">
                    {translate(title)}
                </UIHeading>

                <div styleName={dropdownArrowClasses}>
                    <Arrow />
                </div>
            </button>

            <ul styleName={dropdownListClasses}>
                {dropdownLinksOptions.map(({ data }) => (
                    <li styleName="mobile-nav__ordinary-item" key={data.titleTrKey || data.title}>
                        <OrdinaryLink {...data} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

const MobileSiteNavOrdinaryLinks = ({
    cjGroup,
    user,
    onClickNavLink,
    onClickSubscriptionInfoOpen = null,
    handleClickDeleteAccount,
}) => {
    const allAvailableLinks = useContext(AvailableLinksContext);
    const { locale } = useContext(LocaleContext);
    const { state: { enabledDatesData } } = useContext(menuDatesState);

    const { data: { menuFilter: { periods } } } = useQuery(FILTER_QUERY, {
        context: {
            step: '&:MobileSiteNavOrdinaryLinks',
        },
    });

    const OFFER_LIST = 'offerList';
    const INFO_LIST = 'infoList';
    const SETS_LIST = 'setsList';

    const dropdownListInitStates = {
        [SETS_LIST]: false,
        [OFFER_LIST]: false,
        [INFO_LIST]: false,
    };

    const [dropdownListShownStates, setDropdownListStates] = useState(dropdownListInitStates);

    const toggleDropdownListState = (listId) => () => setDropdownListStates({
        ...dropdownListShownStates,
        [listId]: !dropdownListShownStates[listId],
    });

    const availablePeriods = getAvailablePeriods(periods);

    const commonArgs = {
        allAvailableLinks,
        availablePeriods,
        enabledDatesData,
    };

    const topLevelLinkList = getTopLevelLinks({
        cjGroup,
        user,
        onClickNavLink,
    });

    const availableTopLevelLinks = getAvailableLinksWithUrl({
        ...commonArgs,
        specificLinks: topLevelLinkList,
    });

    const setsDropdownLinksOptions = getDropdownSetLinks({
        locale,
        onClickNavLink,
    });

    const availableSetsDropdownLinks = getAvailableLinksWithUrl({
        ...commonArgs,
        specificLinks: setsDropdownLinksOptions,
    });

    // DONE
    const dropdownLinksOptions = getDropdownInfoLinks({
        locale,
        onClickNavLink,
        onClickSubscriptionInfoOpen,
    });

    const availableDropdownLinks = getAvailableLinksWithUrl({
        ...commonArgs,
        specificLinks: dropdownLinksOptions,
    });

    const foreignLangLinkData = {
        id: 'Версия на другом языке',
        data: {
            titleTrKey: 'mobileNav.foreignLang',
            href: locale === 'ru' ? EE_MAIN.hrefRelEn : EE_MAIN.hrefRelRu,
            handler: onClickNavLink,
            type: 'link',
        },
    };

    const deleteAccountLinkData = {
        id: 'delete-account',
        data: {
            title: locales[locale].deleteAccount,
            handler: handleClickDeleteAccount,
            type: 'button',
        },
        visible: window.location.host === 'elementaree.ru'
            ? user && isStandaloneApp()
            : Boolean(user),
    };

    return (
        <>
            <AllOrdinaryLinks ordinaryLinkList={availableTopLevelLinks} />
            {/* Все наборы */}
            <DropdownList
                title="mobileNav.allSets"
                dropdownLinksOptions={availableSetsDropdownLinks}
                isDropdownListShown={dropdownListShownStates[SETS_LIST]}
                toggleDropdownListState={toggleDropdownListState(SETS_LIST)}
            />
            {/* Как мы работаем */}
            <DropdownList
                title="mobileNav.dropdownInfo"
                dropdownLinksOptions={availableDropdownLinks}
                isDropdownListShown={dropdownListShownStates[INFO_LIST]}
                toggleDropdownListState={toggleDropdownListState(INFO_LIST)}
            />
            <div styleName="mobile-nav__ordinary-links-container">
                <ul styleName="mobile-nav__ordinary-links">
                    <li styleName="mobile-nav__ordinary-item" key={foreignLangLinkData.id}>
                        <OrdinaryLink {...foreignLangLinkData.data} />
                    </li>
                    {deleteAccountLinkData.visible && (
                        <li styleName="mobile-nav__ordinary-item" key={deleteAccountLinkData.id}>
                            <OrdinaryLink
                                {...deleteAccountLinkData.data}
                                inconspicuous
                            />
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};


MobileSiteNavOrdinaryLinks.propTypes = {
    location: PropTypes.string.isRequired,
    cjGroup: PropTypes.oneOf([
        WITHOUT_DELIVERIES,
        WITH_PAST_DELIVERIES,
        WITH_ACTIVE_DELIVERIES,
    ]).isRequired,
    onClickNavLink: PropTypes.func.isRequired,
    onClickSubscriptionInfoOpen: PropTypes.func,
    handleClickDeleteAccount: PropTypes.func.isRequired,
};

export default MobileSiteNavOrdinaryLinks;
