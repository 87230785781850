import React from 'react';
import PropTypes from 'prop-types';
import DishGift from './components/DishGift';
import './dish-gift-list.scss';

const DishGiftList = (props) => {
    const {
        items,
        size,
        preparedBasketAreas,
        ...otherProps
    } = props;


    return (
        <ul styleName="dish-gift__list">
            {items.map((item) => (
                <DishGift
                    key={item.dish.id}
                    preparedBasketAreas={preparedBasketAreas}
                    dishItem={item}
                    {...otherProps}
                />
            ))}
        </ul>
    );
};

DishGiftList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    size: PropTypes.oneOf([null, 'small']),
    preparedBasketAreas: PropTypes.shape({}).isRequired,
};

DishGiftList.defaultProps = {
    size: null,
};

export default DishGiftList;
