import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import PropTypes from 'prop-types';

import { discountConditionsType } from 'types/basketQuery.type';

import { usePrevious } from 'app/utils/hooks';
import { validatePromocode } from 'app/utils/promocode.utils';
import { UIInput } from 'app/components/ui';

import { locales } from './basket-check.locales';


const noop = () => { };


export const BasketCheckPromocodeForm = ({
    discountConditions,
    applyPromocodeHandler,
    promocode = '',
    locale = 'ru',
    defaultLabel = '',
    heightType = 'large',
    showConfirmedLabel = false,
    setIsWelcomeDiscountShown = noop,
    hasSubmitBtn = false,
}) => {
    const l = locales[locale];

    const [inputValue, setInputValue] = useState('');

    const [conditionState, setCondition] = useState({
        label: '',
        uiState: 'submit',
    });

    const prevInputValue = usePrevious(inputValue);

    const isValueChange = useMemo(
        () => (prevInputValue !== inputValue), [prevInputValue, inputValue],
    );

    /**
    * effect ставит изначаное значение промокода из url
    */
    useEffect(() => {
        if (promocode) {
            setInputValue(promocode);
        }
    }, [promocode]);

    useEffect(() => {
        const { promocode: submittedPromocode } = discountConditions;
        const nextConditionState = validatePromocode(discountConditions, submittedPromocode, l, defaultLabel, showConfirmedLabel);

        setCondition(nextConditionState);
        if (nextConditionState.uiState === 'confirmed') {
            setIsWelcomeDiscountShown(false);
        }
    }, [defaultLabel, l, discountConditions, setIsWelcomeDiscountShown, showConfirmedLabel]);

    useEffect(
        () => {
            if (!hasSubmitBtn) return;
            setCondition((prev) => ({ ...prev, uiState: 'submit' }));
        },
        [inputValue, hasSubmitBtn],
    );

    const promocodeSubmitHandler = useCallback(async (e) => {
        e.preventDefault();
        e.persist();

        setCondition({ label: '', uiState: 'validation' });
        const { promocodeConditions: nextConditions } = await applyPromocodeHandler(inputValue);

        const nextConditionState = validatePromocode(nextConditions, inputValue, l, defaultLabel, showConfirmedLabel);
        setCondition(nextConditionState);
    }, [applyPromocodeHandler, inputValue, l, defaultLabel, showConfirmedLabel]);

    const promocodeBlurHandler = useCallback((e) => {
        if (!isValueChange) return;
        promocodeSubmitHandler(e);
    }, [promocodeSubmitHandler, isValueChange]);

    return (
        <form
            onSubmit={promocodeSubmitHandler}
        >
            <UIInput
                onChangeHandler={setInputValue}
                inputValue={inputValue}
                onSubmitHandler={promocodeSubmitHandler}
                onBlurHandler={promocodeBlurHandler}
                uiState={conditionState.uiState}
                label={conditionState.label}
                heightType={heightType}
                isValueChange
                buttonType={conditionState.uiState}
            />
        </form>
    );
};

BasketCheckPromocodeForm.propTypes = {
    promocode: PropTypes.string,
    discountConditions: PropTypes.shape(discountConditionsType).isRequired,
    applyPromocodeHandler: PropTypes.func.isRequired,
    locale: PropTypes.string,
    defaultLabel: PropTypes.string,
    showConfirmedLabel: PropTypes.bool,
    heightType: PropTypes.oneOf(['large', 'medium', 'small']),
    setIsWelcomeDiscountShown: PropTypes.func,
    hasSubmitBtn: PropTypes.bool,
};


