export const PAYMENT_PARAM_RECURRENT = 'recurrent';
export const PAYMENT_PARAM_ONLINE = 'online';
export const PAYMENT_PARAM_CASH = 'cash';
// export const PAYMENT_PARAM_FOODCARD = 'foodcard'; FRNT-3237 Deprecated

export const BILLING_PAYMENT_METHODS = {
    [PAYMENT_PARAM_RECURRENT]: 'recurrent',
    [PAYMENT_PARAM_ONLINE]: 'online',
    [PAYMENT_PARAM_CASH]: 'cash',
    // [PAYMENT_PARAM_FOODCARD]: 'foodcard', FRNT-3237 Deprecated
};
