import { ApolloLink } from '@apollo/client';

const messageRow = (operation) => {
    const { message, step } = operation.getContext();
    if (step) {
        console.log(`\x1b[1;33mStep :\x1b[0m \x1b[1m${step}\x1b[0m`);
    }
};

const variablesRow = (operation) => {
    if (Object.keys(operation.variables).length) {
        return `\x1b[1;33mVariables :\x1b[0m ${JSON.stringify(operation.variables, null, 1)}`;
    }
    return '';
};

const fragmentsRow = (operation) => {
    const { query: { definitions } } = operation;
    const II = definitions.reduce((acc, item) => {
        const isFragementType = item.kind === 'FragmentDefinition';
        if (isFragementType) { return [...acc, item.name.value]; }
        return acc;
    }, []);

    if (!II.length) {
        return '';
    }

    const badge = II.length > 1 ? 'Includes fragments :' : 'Include fragment :';
    const fragmentsNames = II.join(', ');
    return `\x1b[1;33m${badge} \x1b[0m${fragmentsNames}`;
};

const operationRow = (operation) => {
    const { query: { definitions }, operationName } = operation;
    const [{ operation: operationType }] = definitions;
    return `\x1b[1;102;30m ${operationType.toUpperCase()} \x1b[0m \x1b[1m${operationName}\x1b[0m`;
};

const printOperation = (operation) => {
    // const handlers = [operationRow];
    const header = operationRow(operation);
    const rows = [];

    [fragmentsRow, variablesRow].forEach((handler) => {
        const r = handler(operation);
        if (r) rows.push(r);
    });

    // console.groupCollapsed
    const log = rows.length ? console.groupCollapsed : console.log;
    // console.log(operation.getContext().step);

    try {
        log(header);
        messageRow(operation);
        rows.forEach((r) => {
            console.log(r);
        });
        console.groupEnd();
    } catch (err) {
        console.log(err);
    }
};

function ultimaOperationLoger(operation, forward) {
    printOperation(operation);
    return forward(operation);
}

export const ultimaOperationLogerLink = new ApolloLink(ultimaOperationLoger);

