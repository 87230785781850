
import { gql } from '@apollo/client';

export const FULL_USER_PERSONAL_PROMO_FRAGMENT = gql`
  fragment fullUserPersonalPromoFragment on personalPromo {
    campaignId
    discountType
    discountValue
    id
    linkParams
    promoEndDate
    promoCode
  }
`;
