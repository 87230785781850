import React, { FC } from 'react';
import classNames from 'classnames';
import css from './BasketGreenSelectionSlider.module.scss';

const greenIconsCollection: string[] = ['green1', 'green2', 'green3', 'green4', 'green5', 'green6'];

const BasketGreenSelectionSlider: FC = () => (
    <div className={css.basketGreenSelectionSliderRoot}>
        {greenIconsCollection.map((slide: string) => {
            const classes = classNames({
                [css.basketGreenSelectionSliderCard]: true,
                [css[slide]]: true,
            });
            return (
                <div
                    key={slide}
                    className={classes}
                />
            );
        })}
    </div>
);

export default BasketGreenSelectionSlider;
