const ru = {
    addressErrorOutside: 'Извините, доставка доступна только по Москве и 60 км от МКАД',
    addressErrorEmpty: 'Введите адрес',
    addressErrorWrong: 'Некорректный адрес',
    addressErrorHouse: 'Не введен номер дома',

    offerTextOnline: 'Нажимая «Оплатить», вы принимаете условия',
    offerTextCash: 'Нажимая «Оформить заказ», вы принимаете условия',
    offerLink: 'Пользовательского соглашения',

    deliveryDate: 'Дата и время',

    addressTitle: 'Адрес и подтверждение',
    emailTitle: 'Ваш e-mail',
    emailErrorAt: 'E-mail должен содержать символ «@»',
    emailError: 'Введите корректный e-mail',
    streetInputTitle: 'Улица, дом',
    flat: 'Квартира',
    flatShort: 'Кв.',
    entrance: 'Подъезд',
    entranceShort: 'Под.',
    floor: 'Этаж',

    contactlessDeliveryTitle: 'Не звонить. Оставить у двери',
    contactlessDeliveryDescr: 'Курьер оставит набор у двери, сообщит о&nbsp;прибытии и уйдет, не контактируя с вами',

    paymentTitile: 'Способ оплаты',
    recurrentTitle: 'При подписке',
    whatIsRecurrent: 'Что такое подписка?',
    singleTitle: 'При разовой покупке',
    paymentMethodCard: 'Банковской картой',
    paymentMethodFoodcard: 'Картой FoodCard',
    paymentMethodCash: 'Наличными курьеру',

    payTextCash: 'Оформить заказ',
    payTextCard: 'Оплатить',
    payTextLoading: 'Секундочку…',
    loading: 'Загрузка...',

    notificationAddress: 'Выберите адрес, и мы предложим вам корректные дату и время доставки.',
    notificationDeliveryDelivery: 'Доставка',
    notificationDeliveryAdded: 'Добавлено к оплате.',
    notificationDeliveryIsFree: 'Доставка бесплатная',
    notificationDeliveryFree: 'В подарок при заказе пробного набора',
    notificationDeliveryDependsOn: 'Зависит от',
    notificationDeliveryDependsOnWhat: 'адреса доставки и суммы заказа.',
    notificationAddressIncomplete: 'Кажется, на карте нет такого дома. Проверьте, все верно?',

    notificationDeliveryCost: 'Стоимость доставки',
    notificationDeliveryCostAddress: ', т.к. ваш адрес находится в зоне платной доставки.',
};

const en = {
    addressErrorOutside: 'Sorry, delivery is available only in Moscow and areas within 60 km outside MKAD',
    addressErrorEmpty: 'Please type in address',
    addressErrorWrong: 'Address is incorrect',
    addressErrorHouse: 'Please type in house number',

    offerTextOnline: 'Pressing "Pay", you agree with',
    offerTextCash: 'Pressing "Order", you agree with',
    offerLink: 'Terms and Conditions',

    deliveryDate: 'Delivery date and time',

    addressTitle: 'Address and confirmation',
    emailTitle: 'e-mail',
    emailErrorAt: 'Please type in corret email',
    emailError: 'Please type in corret email',
    streetInputTitle: 'Street, house number',
    flat: 'Flat',
    flatShort: 'Flat',
    entrance: 'Entrance',
    entranceShort: 'En.',
    floor: 'Floor',

    contactlessDeliveryTitle: 'No-contact delivery',
    contactlessDeliveryDescr: 'The courier will leave your order at the door, notify you and&nbsp;leave without contact',

    paymentTitile: 'Payment Method',
    recurrentTitle: 'WEEKLY SUBSCRIPTION',
    whatIsRecurrent: 'What is a subscription?',
    singleTitle: 'ONE-TIME ORDER',
    paymentMethodCard: 'Credit card',
    paymentMethodFoodcard: 'FoodCard',
    paymentMethodCash: 'Cash to the courier',

    payTextCash: 'Order',
    payTextCard: 'Pay',
    payTextLoading: 'Please wait…',
    loading: 'Loading...',

    notificationAddress: 'Please insert address to choose available delivery date',
    notificationDeliveryDelivery: 'Delivery',
    notificationDeliveryAdded: 'Added to payment sum.',
    notificationDeliveryIsFree: 'Delivery is free',
    notificationDeliveryFree: 'Delivery is free for trial kit',
    notificationDeliveryDependsOn: 'Depends on',
    notificationDeliveryDependsOnWhat: 'delivery address and meals cost.',
    notificationAddressIncomplete: 'We can’t find the address on the map. Check if the address is correct?',

    notificationDeliveryCost: 'Delivery',
    notificationDeliveryCostAddress: '. Added to payment sum.',
};

export const locales = {
    ru,
    en,
};
