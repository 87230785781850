/* eslint-disable no-underscore-dangle */
import { getAppType } from 'app/utils/browser';

export class GoogleAnalyticsClient {
    constructor() {
        this.service = {
            push: () => {},
        };
        this.clientName = 'GoogleAnalytics';
        this._init();
    }

    _init() {
        if (window.dataLayer) {
            this.service = window.dataLayer;
        }
    }

    emit(data) {
        // console.warn(`emit GoogleAnalytics event: ${data.event}`, data);
        this.service.push({ ...data, platform: getAppType() });
    }
}


