import React, {
    useCallback, useContext, useState, useRef,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import isSameWeek from 'date-fns/is_same_week';
import { useReactiveVar } from '@apollo/client';
import { Portal } from 'react-portal';

import { analyticService } from 'global/services';

import { selectedPeriodVar } from 'app/apollo/reaction';

import { LocaleContext } from 'app/containers/LocaleProvider';
import { UIHeading } from 'app/components/ui';
import { DialogAnimated } from 'app/components/Dialog';

import { CheckoutStoreContext } from '../../store/checkoutStore.context';


import { StepLayout } from '../StepLayout/StepLayout';
import { RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP } from '../stepByStepCheckoutConst';

import { DatetimeSelect } from './DatetimeSelect';

import '../StepLayout/stepLayout.scss';


const datetimeLocales = {
    ru: {
        headerTitle: 'Оформление заказа',
        title: 'Дата и время',
    },
    en: {
        headerTitle: 'Checkout',
        title: 'Date and time',
    },
};


export function StepDatetime({
    renderContext = RENDER_CONTEXT_STEP_BY_STEP,
    headerTitle: headerTitleProp = null,
    deliveryDates,
    timeIntervals,
    selectDay = null,
    selectTime: { timeIndex },
    disabled = false,
    deliveryPrice,
    onSelectDate,
    onSelectDateInNextPeriod,
}) {
    // eslint-disable-next-line prefer-rest-params
    const props = arguments[0];

    const { locale } = useContext(LocaleContext);
    const { dispatch } = useContext(CheckoutStoreContext);

    const selectedPeriod = useReactiveVar(selectedPeriodVar);


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const selectingDate = useRef('');

    const [isConfirmLoading, setIsConfirmLoading] = useState(false);


    const handleSelectDate = useCallback((nextDate) => {
        analyticService.push({
            eventName: 'Checkout_Click_Date',
        });

        const isDateInCurrentPeriod = isSameWeek(selectedPeriod, nextDate, { weekStartsOn: 1 });

        if (isDateInCurrentPeriod) {
            onSelectDate(nextDate);
        } else {
            selectingDate.current = nextDate;
            setIsDialogOpen(true);
        }
    }, [onSelectDate, selectedPeriod]);

    const handleCloseDialog = useCallback(() => {
        if (isConfirmLoading) return;

        setIsDialogOpen(false);
        selectingDate.current = '';
    }, [isConfirmLoading]);

    const handleConfirmDate = useCallback(async () => {
        if (isConfirmLoading) return;

        setIsConfirmLoading(true);

        const nextDate = selectingDate.current;
        await onSelectDate(nextDate);
        await onSelectDateInNextPeriod(nextDate);
        handleCloseDialog();

        setIsConfirmLoading(false);
    }, [onSelectDate, onSelectDateInNextPeriod, handleCloseDialog, isConfirmLoading]);


    const onSelectInterval = useCallback((nextIndex) => {
        analyticService.push({
            eventName: 'Checkout_Click_Time',
        });
        dispatch({
            type: 'SELECT_TIME_INTERVAL_ITEM',
            payload: { timeIndex: nextIndex },
        });
    }, [dispatch]);

    useEffect(
        () => {
            dispatch({
                type: 'SET_IS_DATE_TIME_POPUP_OPENNED',
                payload: { isDateTimePopupOpenned: true },
            });

            return () => {
                dispatch({
                    type: 'SET_IS_DATE_TIME_POPUP_OPENNED',
                    payload: { isDateTimePopupOpenned: false },
                });
            };
        },
        [dispatch],
    );


    const headerTitle = headerTitleProp || datetimeLocales[locale].headerTitle;

    const hasSelectsData = selectDay && (timeIndex > 0 || timeIndex === 0);

    return (
        <>
            <StepLayout
                {...props}
                isNextStepButtonDisabled={disabled}
                title={headerTitle}
            >
                {renderContext === RENDER_CONTEXT_SINGLE_STEP
                    ? null
                    : (
                        <UIHeading level="3.2" styleName="step-layout__content-header">
                            {datetimeLocales[locale].title}
                        </UIHeading>
                    )}

                {hasSelectsData ? (
                    <DatetimeSelect
                        deliveryDates={deliveryDates}
                        timeIntervals={timeIntervals}
                        selectedDate={selectDay}
                        onSelectDate={handleSelectDate}
                        selectedIntervalIndex={timeIndex}
                        disabled={disabled}
                        deliveryPrice={deliveryPrice}
                        onSelectInterval={onSelectInterval}
                    />
                ) : null}
            </StepLayout>
            <Portal>
                <DialogAnimated
                    isOpen={isDialogOpen}
                    strongText="Будет новое меню"
                    regularText={(
                        <>
                            Мы подберем аналогичные блюда
                            <br />
                            Переносим дату доставки?
                        </>
                    )}
                    confirmText="Перенести"
                    rejectText="Не надо"
                    oneRowButtons
                    onReject={handleCloseDialog}
                    onConfirm={handleConfirmDate}
                    isConfirmLoading={isConfirmLoading}
                    extraButtonPosition="first"
                />
            </Portal>
        </>
    );
}

StepDatetime.propTypes = {
    renderContext: PropTypes.oneOf([RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP]),
    headerTitle: PropTypes.string,
    deliveryDates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    timeIntervals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deliveryPrice: PropTypes.number.isRequired,
    selectDay: PropTypes.string,
    selectTime: PropTypes.shape({
        timeIndex: PropTypes.number,
    }).isRequired,
    disabled: PropTypes.bool,
    onSelectDate: PropTypes.func.isRequired,
    onSelectDateInNextPeriod: PropTypes.func.isRequired,
};
