import React from 'react';
import PropTypes from 'prop-types';

import ClosePopupIcon from './ui_close_popup.component.svg';

import './close-popup.scss';


export default function ClosePopup(props) {
    const { onClick, disabled } = props;

    return (
        <button
            type="button"
            onClick={onClick}
            styleName="ui-close-popup"
            disabled={disabled}
        >
            <ClosePopupIcon />
        </button>
    );
}

ClosePopup.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

ClosePopup.defaultProps = {
    disabled: false,
};
