import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import stubMobile from './stub-image/stub-m.jpg';
import stubWide from './stub-image/stub-wide.jpg';

import styles from './mobile-dish-image.scss';


const DEFAULT_IMG_WPRAPPER_SIZE = {
    width: 1,
    height: 1,
};


export default class MobileDishImage extends React.PureComponent {
    state = {
        load: 'url', // fallbackUrl, error
    }

    handleLoadError = () => {
        const { fallbackUrl, onLoadError } = this.props;
        const { load } = this.state;

        if (load === 'url' && fallbackUrl) {
            this.setState({ load: 'fallbackUrl' });
        } else {
            onLoadError();
            this.setState({ load: 'error' });
        }
    }

    render() {
        const {
            url,
            fallbackUrl,
            className,
            alt,
            useWideStubImage,
            scrollPosition,
            useLazyLoading,
        } = this.props;

        const { load } = this.state;

        let src = load === 'url' ? url : fallbackUrl;

        if ((!url && !fallbackUrl) || load === 'error') {
            src = useWideStubImage ? stubWide : stubMobile;
        }

        const commonProps = {
            alt: alt || url,
            src,
            className: `${className} ${styles.image}`,
            onError: this.handleLoadError,
        };

        return (
            <div styleName="image-container">
                {useLazyLoading
                    ? (
                        <LazyLoadImage
                            threshold={400}
                            {...commonProps}
                            {...DEFAULT_IMG_WPRAPPER_SIZE}
                            scrollPosition={scrollPosition}
                        />
                    )
                    // eslint-disable-next-line jsx-a11y/alt-text
                    : <img {...commonProps} />
                }
            </div>
        );
    }
}


MobileDishImage.propTypes = {
    url: PropTypes.string,
    fallbackUrl: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    useWideStubImage: PropTypes.bool,

    scrollPosition: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),
    useLazyLoading: PropTypes.bool,

    onLoadError: PropTypes.func,
    onLoad: PropTypes.func,
};

MobileDishImage.defaultProps = {
    url: null,
    fallbackUrl: null,
    alt: null,
    useWideStubImage: false,

    className: '',
    scrollPosition: null,
    useLazyLoading: false,

    onLoadError: () => {},
    onLoad: null,
};
