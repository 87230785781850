/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { usePrevious } from 'app/utils/hooks';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './uiinput.scss';

import ActiveIcon from 'app/components/ui/input/buttonIcons/ActiveIcon.component.svg';
import ConfirmedIcon from 'app/components/ui/input/buttonIcons/ConfirmedIcon.component.svg';
import ErrorIcon from 'app/components/ui/input/buttonIcons/ErrorIcon.component.svg';
import SubmitIcon from 'app/components/ui/input/buttonIcons/SubmitIcon.component.svg';
import ValidationIcon from 'app/components/ui/input/buttonIcons/ValidationIcon.component.svg';
import EmptyIcon from 'app/components/ui/input/buttonIcons/None.component.svg';

const CommonStatusIconRecord = {
    error: ErrorIcon,
    validation: ValidationIcon,
    confirmed: ConfirmedIcon,
};

const SubmitStatusIconRecord = {
    default: SubmitIcon,
    active: SubmitIcon,
};

const ClearStatusIconRecord = {
    default: ActiveIcon,
    active: ActiveIcon,
};

const StatusIconRecord = {
    clear: {
        ...CommonStatusIconRecord,
        ...ClearStatusIconRecord,
    },
    submit: {
        ...CommonStatusIconRecord,
        ...SubmitStatusIconRecord,
    },
    empty: {
        default: '',
        active: '',
    },
    nohandler: {
        default: EmptyIcon,
        active: EmptyIcon,
        ...CommonStatusIconRecord,
    },
};

/**
 * @description геттер иконки состояния
 * @param {string} uiState
 * @param {string} buttonType
 * @returns компонент иконки состояния
 */
const getStatusIcon = (uiState, buttonType) => {
    const bt = StatusIconRecord[buttonType] ?? StatusIconRecord.empty;
    return bt[uiState] ?? bt.default;
};

const sterilizeEvent = (event) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
};

const UIInput = (props) => {
    const {
        buttonType,
        label,
        inputValue,
        onChangeHandler,
        onBlurHandler,
        onSubmitHandler,
        onFocusHanlder,
        type,
        heightType,
        inputRef,
        passingEvent,
    } = props;

    const uiInputRef = useRef(null);

    const [uiState, setUiState] = useState(props.uiState);
    const [timeOutId, setTimeOutId] = useState([]);

    const [trasitionState, setTrasitionState] = useState('trasition-default');

    // const StatusIcon = useMemo(() => getStatusIcon(uiState, buttonType), [buttonType, uiState]);

    const isButtonEnabled = !['active'].includes(uiState) || !props.isValueChange;
    const isInputDisabled = ['validation', 'disabled'].includes(uiState);
    const prevInputValue = usePrevious(props.inputValue);
    const prevUiState = usePrevious(props.uiState);

    const uiStatePropsRef = useRef(props.uiState);
    useEffect(() => {
        setUiState(props.uiState);
        uiStatePropsRef.current = props.uiState;
    }, [props.uiState]);

    useEffect(() => {
        if (!inputRef) return;
        inputRef.current = uiInputRef.current;
    }, [inputRef]);


    /**
     * css классы 'trasition-active', 'trasition-default' отвечают за анимации
     * 'trasition-active': label над input'ом,
     * 'trasition-default': imput скрыл, на месте input большой label
     */
    useEffect(() => {
        if (trasitionState === 'trasition-default' && props.inputValue) {
            setTrasitionState('trasition-active');
        }
        if (
            props.uiState === 'default'
            && !props.inputValue
            && ['confirmed', 'error'].includes(prevUiState)
        ) {
            setTrasitionState('trasition-default');
        }
    }, [props.inputValue, trasitionState, prevInputValue]);

    /* Styles  */
    const inputContainer = cn({
        'ui-input-container': true,
        [uiState]: true,
        [heightType]: true,
    });
    const inputContent = cn({
        'ui-input-content': true,
        [trasitionState]: true,
        [heightType]: true,
        [buttonType]: true,
    });

    const inputItem = cn({
        'ui-input-item': true,
        [trasitionState]: true,
        [heightType]: true,
        [uiState]: true,
    });

    const inputLabel = cn({
        'ui-input-label': true,
        [trasitionState]: true,
        [uiState]: true,
        [heightType]: true,
        empty: !label,
    });

    const inputButton = cn({
        'input-button-container': true,
        [uiState]: true,
        [buttonType]: true,
        [heightType]: true,
    });

    const inputIcon = cn({
        'input-icon': true,
        [uiState]: true,
        [heightType]: true,
    });


    /* Input handlers */
    const inputClickHandler = (event) => {
        sterilizeEvent(event);

        uiInputRef.current.focus();
        setUiState('active');
    };

    const inputContentClickHander = (event) => {
        sterilizeEvent(event);

        if (!inputValue) {
            uiInputRef.current.focus();
            setUiState('active');
            setTrasitionState('trasition-active');
        }
    };

    const inputBlurHandler = (event) => {
        sterilizeEvent(event);

        const tm = setTimeout(() => {
            if (uiState === 'active') {
                setUiState('default');
                console.log('setted default');
            }

            if (!inputValue) {
                setTrasitionState('trasition-default');
                setUiState('default');
                console.log('setted default 2');
            } else {
                setUiState(uiStatePropsRef.current);
                console.log(uiStatePropsRef.current, 'setted smth');
            }
            onBlurHandler(event);
            setTimeOutId([]);
        }, 300);

        setTimeOutId([...timeOutId, tm]);
    };

    /* Button handlers */
    const _submitButtonClickHadler = useCallback((event) => {
        sterilizeEvent(event);

        timeOutId.forEach((id) => clearTimeout(id));
        onSubmitHandler(event);
        setTimeOutId([]);
    }, [onSubmitHandler, timeOutId]);

    const _clearButtonClickHandler = useCallback((event) => {
        event.persist();
        onChangeHandler(passingEvent ? event : '');
        timeOutId.forEach((id) => clearTimeout(id));
        setUiState('active');
        uiInputRef.current.focus();
        setTimeOutId([]);
    }, [onChangeHandler, timeOutId]);

    /**
     * @param {String} handlerType submit || clear
     * handlerType == buttonType
     * @returns callback
     */
    const commonClickButtonHandler = useCallback((handlerType) => {
        const hadlerState = {
            submit: _submitButtonClickHadler,
            clear: _clearButtonClickHandler,
        };
        return hadlerState[handlerType];
    }, [_clearButtonClickHandler, _submitButtonClickHadler]);

    /* Render */
    return (
        <div styleName={inputContainer}>
            <div
                styleName={inputContent}
                onClick={inputContentClickHander}
                role="button"
                tabIndex={-1}
                onKeyPress={() => { }}
                disabled={isInputDisabled}
            >
                <div
                    styleName={inputLabel}
                >
                    {label}
                </div>
                <input
                    type={type}
                    styleName={inputItem}
                    ref={uiInputRef}
                    onClick={inputClickHandler}
                    value={inputValue}
                    onChange={passingEvent
                        ? (event) => onChangeHandler(event)
                        : (event) => onChangeHandler(event.target.value)}
                    tabIndex={0}
                    onBlur={inputBlurHandler}
                    disabled={isInputDisabled}
                    onFocus={onFocusHanlder}
                />
            </div>
            <button
                styleName={inputButton}
                onClick={commonClickButtonHandler(buttonType)}
                tabIndex={-1}
                type="button"
                onKeyPress={() => { }}
                disabled={isButtonEnabled}
            >
                <div styleName={`${inputIcon} ${buttonType}`}>
                    {buttonType === 'submit' && <SubmitIcon />}
                    {buttonType === 'error' && <ErrorIcon />}
                    {buttonType === 'clear' && <ActiveIcon />}
                    {buttonType === 'confirmed' && <ConfirmedIcon />}
                    {buttonType === 'validation' && <ValidationIcon />}
                </div>
            </button>
        </div>
    );
};

UIInput.propTypes = {
    uiState: PropTypes.string,
    buttonType: PropTypes.oneOf(
        ['submit', 'clear', 'empty', 'nohandler', 'validation', 'confirmed', 'error', 'none', 'default'],
    ),
    label: PropTypes.string,
    inputValue: PropTypes.string,
    type: PropTypes.string,
    onChangeHandler: PropTypes.func,
    onBlurHandler: PropTypes.func,
    onSubmitHandler: PropTypes.func,
    onFocusHanlder: PropTypes.func,
    heightType: PropTypes.oneOf(['large', 'medium', 'small']),
    isValueChange: PropTypes.bool,
    inputRef: PropTypes.shape({
        current: PropTypes.shape({}),
    }),
    passingEvent: PropTypes.bool,
};

UIInput.defaultProps = {
    /**
     * @param uiState модификатор ui состояний. Влияет на общее состояние всего компонента
     * приходит в props и ставится в локальный state.
     * uiState имеет пять возможных значений: default, validation, active, error, confirmed
     * @NOTE В будущем их может быть больше
     *
     * default: Описывает состояние, когда input смонтирован, но не в фокусе, когда с input'ом
     * не было взаимодействия, когда input вернулся в изначальное состояние
     *
     * active: Описывает состояние, когда пользователь взаимодействует с input'ом
     *
     * validation: Описывает состояние, когда идет валидация
     *
     * error: Описывает состояние ошибки
     *
     * confirmed: Описывает состояния: 200, ОК, успех, в общем все хорошо
     *
     */
    uiState: 'none',

    /**
      * @param buttonType модификатор типа кнопки.
      * buttonType 3 возможных значения: submit, clear, none
      * none - скрытие кнопки
      * @NOTE В будущем их может быть больше
      *
      * submit: Кнопка запускает submit формы и повторяет логику события onBlur.
      * clear: Кнопка удаляет весь текст внутри input
      */
    buttonType: 'submit',

    /**
      * @param heightType модификатор ui состояний
      * buttonType 3 возможных значения: large, medium, small
      */
    heightType: 'large',

    /**
      * @param label поле для placeholder'а и текста ошибки
      * @note если label пустой (не передан) input встает ровно по центру
      */
    label: '',

    /**
      * @param inputValue
      */
    inputValue: '',

    /**
      * @param type тип HTML элемента 'password, 'text', 'email' итд
      */
    type: 'text',

    isValueChange: true,

    inputRef: null,

    /* Hadler'ы */
    onChangeHandler: () => { },
    onBlurHandler: () => { },
    onSubmitHandler: () => { },
    onFocusHanlder: () => { },

    // Для того, чтобы можно было добавить инпут в компоненты, где принимаем event, а не value
    passingEvent: false,
};

export default React.memo(UIInput);
