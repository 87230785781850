import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { OverlayContextDispatch } from 'app/containers/contexts/overlay.context';


const dialogTexts = {
    date: {
        strongText: 'Изменена дата доставки',
        regularText: 'Применить ко всей подписке?',
        confirmAllText: 'Да',
        confirmOneText: 'Нет, не надо',
    },
    timeInterval: {
        strongText: 'Изменено время доставки',
        regularText: 'Применить ко всей подписке?',
        confirmAllText: 'Да',
        confirmOneText: 'Нет, не надо',
    },
};

const extraText = 'Мы не применяем это изменение к другим вашим подпискам';


/**
 * Условия отображения диалога заданы в Main.
 * Компонент ничего не рендерит, только пушит оверлей диалога по условию.
 */
const DateTimeConfirmDialogContainer = React.memo((props) => {
    const {
        isDialogRequired,
        type,
        hasExtraText,
        onConfirmOne,
        onConfirmAll,
        onReject,
    } = props;

    const { pushDialogOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);

    const dialogData = {
        ...dialogTexts[type],
        extraText: hasExtraText ? extraText : '',
        oneRowButtons: true,
        onConfirmOne,
        onConfirmAll,
        onReject,
    };

    useEffect(() => {
        if (isDialogRequired) {
            pushDialogOverlay(
                'date_time_confirm_dialog',
                dialogData,
                { onClick: onReject },
            );
        }
    }, [isDialogRequired, dialogData, pushDialogOverlay, onReject]);

    useEffect(() => {
        if (!isDialogRequired) {
            closeLastOverlays();
        }
    }, [isDialogRequired, closeLastOverlays]);

    return null;
});


export default DateTimeConfirmDialogContainer;


DateTimeConfirmDialogContainer.propTypes = {
    isDialogRequired: PropTypes.bool,
    type: PropTypes.string,
    hasExtraText: PropTypes.bool,
    onConfirmOne: PropTypes.func,
    onConfirmAll: PropTypes.func,
    onReject: PropTypes.func.isRequired,
};

DateTimeConfirmDialogContainer.defaultProps = {
    isDialogRequired: false,
    hasExtraText: false,
    type: null,
    onConfirmOne: () => {},
    onConfirmAll: () => {},
};

