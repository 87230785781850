import React, {
    useCallback,
} from 'react';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { StepLayout } from '../StepLayout/StepLayout';
import AuthCheckout from './AuthCheckout';

import { RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP, TYPE_CHECKOUT_SINGLE_STEP } from '../stepByStepCheckoutConst';

const authLocales = {
    ru: {
        headerTitle: 'Оформление заказа',
        title: 'Ваш телефон',
    },
    en: {
        headerTitle: 'Checkout',
        title: 'Your phone number',
    },
};


export function StepAuth(props) {
    const {
        renderContext,
        locale,
        headerTitle: headerTitleProp,

        authState,
        setAuthState,
        handleUserEnteredCode,
        handleOpenNextStep,
        handleCloseAllSteps,
        pushLead,
        userQuery,
        switchToSingleStepCheckout,
    } = props;

    const history = useHistory();
    const location = useLocation();

    const lsPhone = sessionStorage.getItem('phone');

    const handleChangeAuthState = useCallback((options) => {
        const { variables: { value } } = options;
        setAuthState(value);
    }, [setAuthState]);

    const handleUserAuthed = ({ userResponse }) => {
        const { data: { user } } = userResponse;
        const isNewUser = user.deliveries.length === 0 && user.successDeliveriesCount === 0;
        if (isNewUser) {
            handleOpenNextStep();
            setTimeout(() => {
                setAuthState('authResult');
            }, 400);
        } else {
            setAuthState('authResult');
            setTimeout(() => {
                switchToSingleStepCheckout(TYPE_CHECKOUT_SINGLE_STEP);
            }, 400);
        }
    };

    const handlePhoneEntered = useCallback(() => {
        history.replace({
            ...location,
            hash: 'setPhoneNumber', // используется для триггера события в аналитике
        });
    }, [history, location]);

    const isNextStepButtonVisible = authState === 'authResult';

    const headerTitle = headerTitleProp || authLocales[locale].headerTitle;

    return (
        <StepLayout
            {...props}
            handleCloseCurrentStep={handleCloseAllSteps}
            handleCloseAllSteps={null}
            isNextStepButtonVisible={isNextStepButtonVisible}
            title={headerTitle}
        >
            <AuthCheckout
                renderContext={renderContext}
                phoneTitle={authLocales[locale].title}
                authState={{ authState }}
                setAuthState={handleChangeAuthState}
                handleUserEnteredCode={handleUserEnteredCode}
                handleUserAuthed={handleUserAuthed}
                userQuery={userQuery}
                enteredPhone={lsPhone}
                pushLead={pushLead}
                phoneAutoFocus={false}
                sumbitPhoneSource="checkout_steps"
                buttonDisabledColor="primary-grey-salt"
                onPhoneEntered={handlePhoneEntered}
            />
        </StepLayout>
    );
}

StepAuth.propTypes = {
    renderContext: PropTypes.oneOf([RENDER_CONTEXT_SINGLE_STEP, RENDER_CONTEXT_STEP_BY_STEP]),
    locale: PropTypes.string.isRequired,
    headerTitle: PropTypes.string,
    handleOpenNextStep: PropTypes.func.isRequired,

    authState: PropTypes.oneOf(['telephone', 'pincode', 'authResult']).isRequired,
    setAuthState: PropTypes.func.isRequired,
    pushLead: PropTypes.func.isRequired,
    userQuery: PropTypes.shape({
        user: PropTypes.shape({}),
    }).isRequired,
    switchToSingleStepCheckout: PropTypes.func.isRequired,
    handleUserEnteredCode: PropTypes.func.isRequired,
};

StepAuth.defaultProps = {
    renderContext: RENDER_CONTEXT_STEP_BY_STEP,
    headerTitle: null,
};
