/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './toggleSwitch.scss';
import PropTypes from 'prop-types';

const ToggleSwitch = (props) => {
    const {
        onChanegHandler, isChecked, isDisabled, id,
    } = props;

    return (
        <>
            <div styleName="toggle-switch-root">
                <input
                    type="checkbox"
                    styleName="toggle-switch-checkbox"
                    // name="toggleSwitch"
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={onChanegHandler}
                    id={id}
                />
                <label
                    styleName="toggle-switch-label"
                    htmlFor={id}
                >
                    <span styleName="toggle-switch-background" />
                    <span styleName="toggle-switch-handler" />
                </label>
            </div>
        </>
    );
};

ToggleSwitch.propTypes = {
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChanegHandler: PropTypes.func,
    id: PropTypes.string,
};

ToggleSwitch.defaultProps = {
    isChecked: false,
    isDisabled: false,
    onChanegHandler: () => {},
    id: 'initial-toggle',
};


export default ToggleSwitch;
