import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { selectedPeriodVar } from 'app/apollo/reaction';
import PREVIEW_CART_FOR_SET from 'app/graphql/network/previewCartForSet';

export const useDynamicSetPrice = (setType: string): any => {
    const period = selectedPeriodVar();
    const queryVars = {
        period,
        set_type: setType,
    };

    const {
        data,
    } = useSuspenseQuery(PREVIEW_CART_FOR_SET, {
        variables: queryVars,
        context: {
            step: 'menu:init:BasketPreview',
        },
    });

    // @ts-ignore
    const hasDishesInSet = Boolean(data?.previewCartForSet?.sections.length);

    // @ts-ignore
    const previewCartForSet = data?.previewCartForSet?.totals || {};
    const { total_common_price: totalCommonPriceFromPreview } = previewCartForSet;
    return {
        priceOfSet: totalCommonPriceFromPreview,
        hasDishesInSet,
    };
};
