import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './circle.scss';


const POSITION_LEFT = 'left';
const POSITION_RIGHT = 'right';

const SIZE_BIG = 'big';

const ANIMATION_STATE_PULSING = 'pulsing';


export default class Circle extends React.Component {
    state = {
        animationState: null,
    }

    componentDidUpdate() {
        const { transitionState } = this.props;
        const { animationState } = this.state;


        if (transitionState === 'entered' && !animationState) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ animationState: ANIMATION_STATE_PULSING });
        }
    }

    render() {
        const {
            position,
            size,
            transitionState,
        } = this.props;
        const { animationState } = this.state;

        const classes = classNames({
            circle: true,
            [position]: position,
            [size]: size,
            [animationState]: animationState,
            [transitionState]: transitionState,
        });

        return (
            <div styleName={classes} onTransitionEnd={this.handleEndEnterAnimation} />
        );
    }
}

Circle.propTypes = {
    position: PropTypes.oneOf([POSITION_LEFT, POSITION_RIGHT]),
    size: PropTypes.oneOf([SIZE_BIG, null]),
    transitionState: PropTypes.string,
};

Circle.defaultProps = {
    position: POSITION_LEFT,
    size: null,
    transitionState: null,
};
