/* eslint-disable react/require-default-props */
import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { BasketCheck } from './components/BasketCheck/BasketCheck';

import './basket.scss';
import { BASKET_PAGE_CHECK_ARIA } from './graphql/basketCheckArea.graphql';
import { DELIVERY_BASKET_PAGE_CHECK_AREA } from './graphql/deliveryBasketCheckArea.graphql';


const RENDER_CONTEXT_BASKET = 'basket';
const RENDER_CONTEXT_DELIVERY_BASKET = 'delivery-basket';


export const BasketCheckArea = React.memo((props) => {
    const {
        renderContext,
        prearedBasketAreas: { list: { isEmptyBasket }, basketStyleModifier },
    } = props;

    const isDelivery = window.location.pathname.includes('/delivery');
    const query = isDelivery
        ? DELIVERY_BASKET_PAGE_CHECK_AREA
        : BASKET_PAGE_CHECK_ARIA;

    const deliveryIdFromUrl = window.location.pathname.match(/\/(\d+)\//);
    const deliveryId = props?.basket?.cart?.id || (deliveryIdFromUrl?.length ? deliveryIdFromUrl[1] : '');

    const { data, previousData } = useQuery(
        query,
        {
            fetchPolicy: 'cache-first',
            variables: isDelivery
                ? {
                    delivery_id: deliveryId,
                    clear: true,
                }
                : null,
        },
    );

    const cartField = isDelivery ? 'deliveryCart' : 'cart';
    const hasCart = data && (cartField in data);

    if (!hasCart || !data[cartField]) {
        return null;
    }

    // if (isEmptyBasket) return null;

    const BasketCheckClasses = classNames({
        'basket-check-area': true,
        [basketStyleModifier]: true,
    });

    return (
        <div styleName={BasketCheckClasses}>
            <BasketCheck
                {...props}
                basket={isDelivery ? data.deliveryCart : data.cart}
                renderContext={renderContext}
            />
        </div>
    );
});


BasketCheckArea.propTypes = {
    renderContext: PropTypes.oneOf([RENDER_CONTEXT_BASKET, RENDER_CONTEXT_DELIVERY_BASKET]),
    prearedBasketAreas: PropTypes.shape({
        list: PropTypes.shape({
            isEmptyBasket: PropTypes.bool,
        }).isRequired,
        basketStyleModifier: PropTypes.string.isRequired,
    }).isRequired,
};

BasketCheckArea.defaultProps = {
    renderContext: RENDER_CONTEXT_DELIVERY_BASKET,
};
