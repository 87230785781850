import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { GET_PROMOCODE } from 'app/graphql/client/promocode';
import { UIParagraph } from 'app/components/ui';

import { BasketCheckPrice } from './BasketCheckPrice';
import { BasketCheckPromocodeForm } from './BasketCheckPromocodeForm';

import { locales } from './basket-check.locales';

import './basket-check-rows.scss';


export const Promocode = (props) => {
    const {
        // DATA
        basket: {
            totals: { promocode_amount: promocodeAmount },
            discount_conditions: discountConditions,
        },
        locale,
        // ACTION
        applyPromocodeHandler,
    } = props;

    const l = locales[locale];

    const { data: { promocode } } = useQuery(GET_PROMOCODE);

    return (
        <>
            <div styleName="basket-check__row">
                <div>
                    <UIParagraph level="4.2" styleName="basket-check__row-text">
                        {l.promocode}
                    </UIParagraph>
                </div>
                {promocodeAmount > 0 && (
                    <div>
                        <BasketCheckPrice price={promocodeAmount} sign="-" />
                    </div>
                )}
            </div>

            <div styleName="basket-check__row basket-check__promocode-wrap">
                <BasketCheckPromocodeForm
                    promocode={promocode}
                    discountConditions={discountConditions}
                    applyPromocodeHandler={applyPromocodeHandler}
                />
            </div>
        </>
    );
};

Promocode.propTypes = {
    basket: PropTypes.shape({
        totals: PropTypes.shape({
            total_price: PropTypes.number.isRequired,
            delivery_price: PropTypes.number.isRequired,
            referral_amount: PropTypes.number.isRequired,
            total_common_price: PropTypes.number.isRequired,
            discount_amount: PropTypes.number.isRequired,
            promocode_amount: PropTypes.number.isRequired,
        }).isRequired,
        discount_conditions: PropTypes.shape({}).isRequired,
    }).isRequired,
    applyPromocodeHandler: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
};

