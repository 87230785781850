import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Heading from 'app/components/ui/Heading';

import './basket-category-header.scss';

class BasketCategoryHeader extends React.Component {
    renderText() {
        const {
            title,
        } = this.props;

        return (
            <div styleName="basket__dishes-type-name--wrap" data-test-id="basket__category-header">
                <Heading level="3.2" styleName="basket__dishes-type-heading">{title}</Heading>
            </div>
        );
    }

    render() {
        const {
            basketStyleModifier,
        } = this.props;

        const basketDishesTypeHeaderClasses = classNames({
            'basket__dishes-type-header': true,
            [basketStyleModifier]: true,
        });

        return (
            <div
                styleName={basketDishesTypeHeaderClasses}
            >
                {this.renderText()}
            </div>
        );
    }
}


BasketCategoryHeader.propTypes = {
    title: PropTypes.string.isRequired,
    basketStyleModifier: PropTypes.string.isRequired,
};

export default React.memo(BasketCategoryHeader);
