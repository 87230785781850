/* eslint-disable global-require */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useReactiveVar,
    useApolloClient,
} from '@apollo/client';
import cn from 'classnames';

import { selectedPeriodVar } from 'app/apollo/reaction';
import { DISH_IMAGE_SHIELD_FRAGMENT } from './dish-images-shield.graphql';

/* COMPOENT */
import { AbstractDishShield } from './imgV2/Shield';

/* STYLES */
import s from './dish-image-shield.scss';


// const kidsRecords = Object.values(mainDishesData).map((d) => {
//     const { metaLabel: { dishMetaIcon } } = d;

//     const rec = {
//         id: d.id,
//         check: () => true,
//         alt: d.id,
//         className: 'kids',
//         src: dishMetaIcon === 'coloring' ? kidsColoring : kidsSticker,
//     };

//     return rec;
// });

// const comboRecords = [
//     {
//         id: '4252',
//         check: () => false,
//         alt: '4252',
//         className: 'combo-3',
//         src: require('./img/combo-3.png'),
//     },
//     {
//         id: '2808',
//         check: () => true,
//         alt: '2808',
//         className: 'combo-3',
//         src: require('./img/combo-3.png'),
//     },
//     {
//         id: '3418',
//         check: () => true,
//         alt: '3418',
//         className: 'combo',
//         src: require('./img/combo-2.img.svg'),
//     },
// ];

// const hpRecords = hpDishes.map((id) => {
//     const checkHP = ({ period }) => hpPeriods.includes(period);
//     const rec = {
//         id,
//         check: checkHP,
//         alt: id,
//         className: 'hp',
//         src: require('./img/hp.png'),
//     };
//     return rec;
// });

// const chRecords = chDishes.map((id) => {
//     const check = ({ period }) => chPeriods.includes(period);
//     const rec = {
//         id,
//         check,
//         alt: id,
//         className: '',
//         src: require('./img/label-ch.png'),
//     };
//     return rec;
// });

// const loveRecords = loveDishes.map((id) => ({
//     id,
//     check: (props) => {
//         const { period } = props;
//         return lovePeriods.includes(period);
//     },
//     alt: id,
//     src: require('./img/label-love.png'),
//     className: 'love',
// }));

// const checkMaslenitsa = (props) => {
//     const { period } = props;
//     return maslenitsaPeriods.includes(period);
// };

// const maslenitsaRecord = maslenitsaDishes.map((id) => ({
//     id,
//     check: checkMaslenitsa,
//     alt: id,
//     src: require('./img/label-maslenitsa.png'),
//     className: 'love',
// }));

const records = [
    // ...kidsRecords,
    // ...comboRecords,
    // ...hpRecords,
    // ...chRecords,
    // ...loveRecords,
    // ...maslenitsaRecord,
];

const fastingRecord = {
    alt: 'fasting',
    src: require('./img/label-fasting.png'),
    className: '',

    check: (dish) => {
        const fastingTag = dish.tags.find((tag) => tag.id === '2121');
        return Boolean(fastingTag);
    },
};


const dishWithShield = new Map([
    // ['4495', { name: 'fennel', type: 'text' }],
    // ['4515', { name: 'kohlrabi', type: 'text' }],
    // ['4498', { name: 'mongold', type: 'text' }],
    // ['4510', { name: 'bokchoy', type: 'text' }],
    // ['4455', { name: 'wildgarlic', type: 'text' }],
    // ['4028', { name: 'wildgarlic', type: 'text' }],
    // ['3103', { name: 'ahmad', type: 'combined' }],
    ['3677', { name: 'ahmad', type: 'combined' }],
    ['3833', { name: 'ahmad', type: 'combined' }],
    ['4574', { name: 'olya', type: 'combined' }],
    ['4581', { name: 'olya', type: 'combined' }],
    ['4556', { name: 'olya', type: 'combined' }],
    ['4557', { name: 'olya', type: 'combined' }],
    ['4575', { name: 'olya', type: 'combined' }],

    ['4134', { name: 'pizza', type: 'upper' }],
    ['4132', { name: 'puree', type: 'upper' }],
    ['4130', { name: 'pasta', type: 'upper' }],
    ['4133', { name: 'cutlet', type: 'upper' }],
    ['4619', { name: 'cusserole', type: 'upper' }],
    ['3093', { name: 'pie', type: 'upper' }],
]);


export const DishImageShield = React.memo((props) => {
    const { dishId, isSoldOut } = props;

    const { name, type } = useMemo(() => {
        if (isSoldOut) {
            dishWithShield.set(dishId, { name: 'soldout', type: 'text' });
        }

        const shieldData = dishWithShield.get(dishId);
        return shieldData || { name: '', type: '' };
    }, [dishId, isSoldOut]);

    if (!name || !type) return null;

    return (
        <div className={s['dish-image-shield__root']}>
            <AbstractDishShield name={name} type={type} />
        </div>
    );
});


DishImageShield.propTypes = {
    dishId: PropTypes.string.isRequired,
};


/*

      const matchedShield = useMemo(() => {
        const shieldByRecord = records.find((record) => {
            const isIdMatching = record.id === dishId;
            // return isIdMatching && record.check({ period }) && !disabledHyggeDisheIds.includes(record.id);
            return isIdMatching && record.check({ period });
        });

        if (shieldByRecord) {
            return shieldByRecord;
        }


        const dishData = client.readFragment({
            id: `menuDish:${dishId}`,
            fragment: DISH_IMAGE_SHIELD_FRAGMENT,
        });

        if (fastingRecord.check(dishData)) {
            return fastingRecord;
        }

        return null;
    }, [
        client,
        dishId,
        period,
    ]);

*/
