/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Velocity from 'velocity-animate';
import Raven from 'raven-js';

import CustomizationCommentForm from './CustomizationCommentForm';


const MAX_COMMENT_LENGTH = 2000;


export default class CustomizationCommentFormAnimationWrap extends Component {
    constructor(props) {
        super(props);

        this.commentFormRef = React.createRef();
        this.commentContainerRef = React.createRef();
        this.textareaRef = React.createRef();
        this.scrollingTargetRef = React.createRef();

        this.timerId = null;

        this.state = {
            commentFormInitialStyle: null,
            commentContainerInitialStyle: null,
        };
    }

    componentDidMount() {
        const isFormElsExist = this.commentFormRef.current && this.commentContainerRef.current;
        if (isFormElsExist) this.setInitialStyles();
    }

    componentDidUpdate(prevProps) {
        const {
            isCommentAreaShown,
        } = this.props;

        const {
            isCommentAreaShown: prevIsCommentFormShown,
        } = prevProps;

        const isCommentAreaShownStateChanged = isCommentAreaShown !== prevIsCommentFormShown;

        const isCommentAreaShownStateChangedToFalse = !isCommentAreaShown && isCommentAreaShownStateChanged;
        if (isCommentAreaShownStateChangedToFalse) {
            clearInterval(this.timerId);
            this.blurOnTextarea();
            this.handleCollapseCommentForm();
        }

        const isCommentAreaShownStateChangedToTrue = isCommentAreaShown && isCommentAreaShownStateChanged;
        if (isCommentAreaShownStateChangedToTrue) {
            this.handleOpenMobileCommentForm();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
        this.resetCommentFormStyle();
    }

    onTextareaFocus = () => {
        const { commentFormOpenButtonEl } = this.props;
        const textareaEl = this.textareaRef.current;

        // there is need focus on button because you can not
        // open the soft keyboard programmatically on random input.
        // You can give focus from one touched input to required.
        // Tip #2: https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
        commentFormOpenButtonEl.focus();
        setTimeout(() => {
            textareaEl.focus();
            textareaEl.setSelectionRange(MAX_COMMENT_LENGTH, MAX_COMMENT_LENGTH); // set cursor at the comment end
        }, 100);
    }

    blurOnTextarea() {
        const textareaEl = this.textareaRef.current;
        textareaEl.blur();
    }

    setInitialStyles() {
        try {
            const { style: commentFormInitialStyle } = this.commentFormRef.current;
            const { style: commentContainerInitialStyle } = this.commentContainerRef.current;

            this.setState({
                commentFormInitialStyle,
                commentContainerInitialStyle,
            });
        } catch (e) {
            Raven.captureException(e);
        }
    }

    resetCommentFormStyle() {
        const {
            commentFormInitialStyle,
            commentContainerInitialStyle,
        } = this.state;

        const commentFormEl = this.commentFormRef.current;
        const commentContainerEl = this.commentContainerRef.current;

        if (commentFormEl) {
            commentFormEl.style = commentFormInitialStyle;
        }
        if (commentContainerEl) {
            commentContainerEl.style = commentContainerInitialStyle;
        }
    }

    animateOpeningCommentForm = async () => {
        const { commentFormOpenButtonEl: toggleEl } = this.props;
        const formEl = this.commentFormRef.current;
        const commentContainerEl = this.commentContainerRef.current;

        const {
            top, left, height, width,
        } = toggleEl.getBoundingClientRect();

        const formPositioningOptions = {
            style: {
                top,
                left,
                height,
                width,
                paddingTop: 0,
                paddingBottom: 0,
            },
            time: {
                duration: 0,
            },
        };

        const formAppearingOptions = {
            style: {
                zIndex: 6,
                opacity: 1,
            },
            time: {
                duration: 100,
            },
        };

        const formOpeningOptions = {
            style: {
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderWidth: 1,
                borderColor: '#f1f1f1',
                backgroundColor: '#ffffff',
                opacity: 1,
                paddingTop: 84,
                paddingBottom: 14,
            },
            time: {
                duration: 650,
                easing: [0.25, 0.1, 0.25, 1],
            },
        };

        const borderVanishingOptions = {
            style: {
                borderColor: '#ffffff',
            },
            time: {
                duration: 0,
            },
        };

        const commentContainerAppearingOptions = {
            style: {
                opacity: 1,
            },
            time: {
                duration: 300,
            },
        };

        formEl.style.visibility = 'visible';

        await Velocity(
            formEl,
            formPositioningOptions.style,
            formPositioningOptions.time,
        );
        await Velocity(
            formEl,
            formAppearingOptions.style,
            formAppearingOptions.time,
        );
        await Velocity(
            formEl,
            formOpeningOptions.style,
            formOpeningOptions.time,
        );
        await Velocity(
            formEl,
            borderVanishingOptions.style,
            borderVanishingOptions.time,
        );
        await Velocity(
            commentContainerEl,
            commentContainerAppearingOptions.style,
            commentContainerAppearingOptions.time,
        );
    }

    animateCollapsingCommentForm = async () => {
        const { commentFormOpenButtonEl: toggleEl } = this.props;

        const formEl = this.commentFormRef.current;
        const commentContainerEl = this.commentContainerRef.current;

        const {
            top, left, height, width,
        } = toggleEl.getBoundingClientRect();

        const commentContainerDissapearingOptions = {
            style: {
                opacity: 0,
            },
            time: {
                duration: 200,
            },
        };

        const borderAppearingOptions = {
            style: {
                borderColor: '#f1f1f1',
            },
            time: {
                duration: 0,
            },
        };

        const collapseOptions = {
            style: {
                top,
                left,
                width,
                height,
                paddingTop: 0,
                paddingBottom: 0,
                borderWidth: 1,
                borderColor: '#f1f1f1',
                backgroundColor: '#ffffff',
            },
            time: {
                duration: 650,
                easing: [0.25, 0.1, 0.25, 1],
            },
        };

        const disappearingOptions = {
            style: {
                opacity: 0,
                zIndex: 0,
            },
            time: {
                duration: 100,
            },
        };

        await Velocity(
            commentContainerEl,
            commentContainerDissapearingOptions.style,
            commentContainerDissapearingOptions.time,
        );
        await Velocity(
            formEl,
            borderAppearingOptions.style,
            borderAppearingOptions.time,
        );
        await Velocity(
            formEl,
            collapseOptions.style,
            collapseOptions.time,
        );
        await Velocity(
            formEl,
            disappearingOptions.style,
            disappearingOptions.time,
        );
    }

    handleOpenMobileCommentForm = async () => {
        const { onOpened } = this.props;

        await this.animateOpeningCommentForm();
        onOpened();
        this.onTextareaFocus();
    }

    handleCollapseCommentForm = async () => {
        const { onClosed, onCloseCommentArea } = this.props;

        await this.animateCollapsingCommentForm();
        onClosed();
        onCloseCommentArea();

        // must reset commentForm style on collapse, because Velocity changes commentForm style directly in DOM
        this.resetCommentFormStyle();
    }

    handleChangeComment = ({ target: { value } }) => {
        const { onChangeComment } = this.props;
        onChangeComment({ value });
    }

    /**
     * В ios после фокуса в инпут может произойти скролл контента при появлении клавиатуры
     * Клавиатура появляется анимированно, и не всегда за одинаковое время, поэтому
     * приходится вызывать scrollIntoView у специального div-а над textarea несколько раз,
     * чтобы контент не успевал проскроллиться при появлении клавиатуры
     */
    scrollToTopOfCommentAfterFocus = () => {
        const STEP_TIMER = 200;
        const MAX_SCROLL_TIME = 5000; // 5 seconds
        const MAX_ATTEMPTS = MAX_SCROLL_TIME / STEP_TIMER;

        let attempts = 0;

        this.timerId = setInterval(() => {
            if (attempts < MAX_ATTEMPTS) {
                window.scrollTo(0, 0);
                attempts += 1;
            } else {
                clearInterval(this.timerId);
                window.scrollTo(0, 0);
            }
        }, STEP_TIMER);
    }

    render() {
        const {
            commentValue,
            isCommentSaving,
            isCommentSaved,
            isInitialCommentValue,
            isCommentAreaShown,

            onChangeComment,
            onSubmitComment,
            locale
        } = this.props;

        return (
            <CustomizationCommentForm
                commentFormRef={this.commentFormRef}
                commentContainerRef={this.commentContainerRef}
                scrollingTargetRef={this.scrollingTargetRef}
                textareaRef={this.textareaRef}

                commentValue={commentValue}
                isCommentSaving={isCommentSaving}
                isCommentSaved={isCommentSaved}
                isInitialCommentValue={isInitialCommentValue}
                isCommentAreaShown={isCommentAreaShown}

                onChangeComment={onChangeComment}
                onFocusComment={this.scrollToTopOfCommentAfterFocus}
                onSubmitComment={onSubmitComment}
                locale={locale}
            />
        );
    }
}


CustomizationCommentFormAnimationWrap.propTypes = {
    isCommentSaving: PropTypes.bool.isRequired,
    isCommentSaved: PropTypes.bool.isRequired,
    isInitialCommentValue: PropTypes.bool.isRequired,
    isCommentAreaShown: PropTypes.bool.isRequired,

    commentValue: PropTypes.string,
    commentFormOpenButtonEl: PropTypes.instanceOf(Element),

    onSubmitComment: PropTypes.func.isRequired,
    onChangeComment: PropTypes.func.isRequired,
    onOpened: PropTypes.func.isRequired,
    onClosed: PropTypes.func.isRequired,
    onCloseCommentArea: PropTypes.func.isRequired,
};

CustomizationCommentFormAnimationWrap.defaultProps = {
    commentValue: null,
    commentFormOpenButtonEl: null,
};
