export const dishes = [
    '3871',
    '3874',
    '3873',
    '3872',
    '3878',
    '3879',
    '3880',
    '3881',
    '3881',
    '3454',
];

export const periods = [
    '2023-11-06',
    '2023-11-13',
    '2023-11-20',
    '2023-11-27',
    '2023-12-04',
    '2023-12-11',
    '2023-12-18',
    '2023-12-25',
    '2024-01-01',
    '2024-01-08',
    '2024-01-15',
    '2024-01-22',
    '2024-06-17',
];
