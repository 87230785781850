import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import { basketImageSize, getImageUrl } from 'app/utils/dish';

import formatPrice from 'app/utils/price';
import { UIRub } from 'app/components/ui';
import PortionSelect from 'app/components/PortionSelect';
import DishImage from 'app/components/DishImage';
import UIParterLogoShield, { TEFAL_DISHES_ID } from 'app/components/ui/Elements/PartnerLogoShield';

import DishTitle from '../DishTitle';

import DeleteIcon from './x_big_2.component.svg';
import './dish-item-small.scss';


const DELETING_DISH_TIMEOUT = 1300;


export default class DishItemSmall extends React.PureComponent {
    dishRootRef = React.createRef();

    isStartIntersection = false;

    state = {
        // isPriceChanged: false,
        isMounted: false,
    };

    componentDidMount() {
        this.setState({ isMounted: true });
    }

    componentDidUpdate() {
        if (this.dishRootRef.current && !this.isStartIntersection) {
            const { basketDishObserver } = this.props;
            if (basketDishObserver) {
                basketDishObserver.observe(this.dishRootRef.current);
                this.isStartIntersection = true;
            }
        }
    }

    // Handlers ================================================================

    handleEndPriceAnimation = () => {
        // this.setState({ isPriceChanged: false });
    };

    handleChangePortion = ({ value }) => {
        const { onChangePortion, dishItem: { dish: { id } } } = this.props;
        onChangePortion({ id, value });
    };

    handleClickMobileDishDetails = () => {
        const {
            onClickMobileDishDetails,
            isSubscriptionActivated,
            dishItem,
        } = this.props;
        onClickMobileDishDetails({
            item: {
                ...dishItem,
                isSubscriptionActivated,
            },
        });
    };

    handleRemoveDish = () => {
        const {
            dishItem: { dish: { id } },
            onRemoveClick,
            isOneItemInCategory,
            sectionId,
        } = this.props;

        this.setState(
            { isMounted: false },
            () => onRemoveClick(id, isOneItemInCategory, sectionId),
        );
    };

    // Render = ================================================================

    renderImage() {
        const { dishItem } = this.props;
        const { dish: { title, previewImage, id } } = dishItem;

        const imageUrl = getImageUrl({ url: previewImage, ...basketImageSize });
        return (
            <div styleName="basket__dish-item__image">
                {TEFAL_DISHES_ID.includes(id) && <UIParterLogoShield type="basket" />}
                <DishImage url={imageUrl} alt={title} />
            </div>
        );
    }

    renderImageWrapper() {
        return (
            // in chrome image inside button doesn't stretch for full button's height/width
            // eslint-disable-next-line
            <div
                type="button"
                styleName="basket__dish-item__image--container"
                arial-label="Показать детали блюда"
                onClick={this.handleClickMobileDishDetails}
            >
                {this.renderImage()}
            </div>
        );
    }

    renderTitle(dishItem) {
        const {
            locale,
            isEditEnabled,
        } = this.props;

        const {
            dish: {
                title, titleEn, caption, captionEn,
            },
        } = dishItem;

        const dishTitle = locale === 'en' ? (titleEn || title) : title;
        const dishCaption = locale === 'en' ? (captionEn || caption) : caption;

        let text = dishTitle;

        if (dishCaption) {
            text = `${dishTitle} ${dishCaption}`;
        }

        return (
            <button
                type="button"
                onClick={this.handleClickMobileDishDetails}
                arial-label="Показать детали блюда"
                styleName="basket__dish-item__title-container"
            >
                <DishTitle
                    wide={!isEditEnabled}
                    text={text}
                />
            </button>
        );
    }

    renderPortionSelect(dishItem, disabled) {
        const { portions } = dishItem;

        const {
            portionAlias,
            onClickMobilePortions,
            isEditEnabled,
            locale,
            scrollbarsRef,
        } = this.props;

        const {
            dish: {
                id,
                weight,
                portions: dishPortions,
            },
        } = dishItem;

        return (
            <PortionSelect
                portionAlias={portionAlias}
                id={id}
                portion={portions}
                portions={dishPortions}
                weight={weight}
                onChange={this.handleChangePortion}
                onClickMobilePortions={onClickMobilePortions}
                basket
                disabled={disabled || !isEditEnabled}
                dishItemType="small"
                locale={locale}
                scrollbarsRef={scrollbarsRef}
                from="basket"
            />
        );
    }

    renderPrice(dishItem, disabled) {
        // анимация отключена в этой версии диша
        // const { isPriceChanged } = this.state;
        const { isSubscriptionActivated } = this.props;
        const {
            price,
            promoDiscountPrice,
            discount_price: discountPrice,
            portions,
        } = dishItem;

        const dishPrice = isSubscriptionActivated ? discountPrice : price;

        // промо-цена всегда присутствует во всех блюдах в обычной корзине, и равна undefined в корзине доставки
        // В обычном случае промо-цена === полной цене блюда (promoDiscountPrice === discountPrice).
        // TODO: BUG: в доставках promoDiscountPrice равна undefined.
        const isDiscountPrice = price !== promoDiscountPrice && typeof promoDiscountPrice === 'number';
        const totalPricePerDish = isDiscountPrice ? promoDiscountPrice : dishPrice;
        const pricePerPortion = formatPrice(Math.ceil(totalPricePerDish / portions));

        const priceClasses = classNames({
            'basket__dish-item__price-wrapper': true,
        });

        return (
            <div styleName={priceClasses} onAnimationEnd={this.handleEndPriceAnimation}>
                <p styleName="basket__dish-item__price">
                    <span styleName="num">{pricePerPortion}</span>
                    <UIRub marginLeft="3" />
                </p>
            </div>
        );
    }

    renderDeleteButton(dishItem) {
        const { is_promo: isPromo } = dishItem;

        return (
            <button
                type="button"
                aria-label="Удалить блюдо"
                styleName="basket__dish-item__remove-container"
                onClick={isPromo ? undefined : this.handleRemoveDish}
                data-test-id="basket-dish__remove-button"
            >
                <DeleteIcon />
            </button>
        );
    }

    renderDishItem({ transitionState }) {
        const {
            dishItem,
            isEditEnabled,
            isEditable,
            isLastItem,
            basketStyleModifier,
        } = this.props;

        const {
            is_promo: isPromo,
            dish: { id, price },
        } = dishItem;

        const canDeleteDish = !(isPromo || !isEditEnabled);
        const disabled = !isEditable || !isEditEnabled || isPromo || price === 0;

        const rootClasses = classNames({
            'basket__dish-item': true,
            [transitionState]: true,
            'without-margin-after-last': isLastItem && basketStyleModifier === 'control_small_basket_without_promo',
            // 'is-last-item': isLastItem,
        });
        const multiplierClasses = classNames({
            'basket__dish-item__multiplier': true,
        });

        return (
            <li
                key={id}
                styleName={rootClasses}
                data-test-id="basket-dish-mobile-small"
                data-id={id}
                ref={this.dishRootRef}
            >
                {this.renderImageWrapper()}

                <div styleName="basket__dish-item__info--container">
                    <div styleName="basket__dish-item__row first">
                        {this.renderTitle(dishItem)}
                        {canDeleteDish && this.renderDeleteButton(dishItem)}
                    </div>

                    <div styleName="basket__dish-item__row second">
                        {this.renderPortionSelect(dishItem, disabled)}
                        <p styleName={multiplierClasses}>x</p>
                        {this.renderPrice(dishItem, disabled)}
                    </div>
                </div>
            </li>
        );
    }

    render() {
        const { isMounted } = this.state;

        return (
            <Transition
                in={isMounted}
                appear
                unmountOnExit
                timeout={DELETING_DISH_TIMEOUT}
            >
                {(transitionState) => this.renderDishItem({ transitionState })}
            </Transition>
        );
    }
}


DishItemSmall.propTypes = {
    portionAlias: PropTypes.arrayOf(PropTypes.string),
    dishItem: PropTypes.shape({
        is_promo: PropTypes.bool,
        dish: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            caption: PropTypes.string,
            weight: PropTypes.number,
            portions: PropTypes.arrayOf(PropTypes.number),
            previewImage: PropTypes.string,
            price: PropTypes.number.isRequired,
        }),
        dish_id: PropTypes.string.isRequired,
        portions: PropTypes.number,
        price: PropTypes.number,
    }).isRequired,

    basketStyleModifier: PropTypes.string.isRequired,

    // TODO: избавиться от одного из флагов
    isEditable: PropTypes.bool.isRequired,
    isEditEnabled: PropTypes.bool.isRequired,

    isSubscriptionActivated: PropTypes.bool.isRequired,
    isLastItem: PropTypes.bool,
    isOneItemInCategory: PropTypes.bool,
    sectionId: PropTypes.string.isRequired,

    scrollbarsRef: PropTypes.shape({}).isRequired,
    basketDishObserver: PropTypes.shape({
        observe: PropTypes.func.isRequired,
    }),

    onClickMobilePortions: PropTypes.func,
    onClickMobileDishDetails: PropTypes.func.isRequired,
    onChangePortion: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired,

    locale: PropTypes.string,
};

DishItemSmall.defaultProps = {
    locale: 'ru',
    portionAlias: null,
    onClickMobilePortions: null,
    isLastItem: false,
    isOneItemInCategory: false,
    basketDishObserver: null,
};
