/* eslint-disable react/require-default-props */
import React, {
    useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AuthContext } from 'app/containers/AuthContainer';
import { DesignContext } from 'app/containers/DesignProvider';

import { Desktop } from 'app/components/Responsive';

import { isDesktop } from 'app/utils/resolution';

import { SpaDesktopProfileHeader, SpaDesktopMenuHeader } from 'global/header/components/SpaDesktopHeader';
import Navigation from './components/Navigation';

import './header.scss';


const GlobalHeaderAdapter = (props) => {
    // Data
    const isProfile = window.location.pathname.includes('/profile');
    const isMenu = window.location.pathname.includes('/menu');
    const { authData: { isAuthed } } = props;

    const wrapperClassName = isProfile ? 'app-profile-header--wrapper' : 'app-header--wrapper';

    return (
        <div styleName={wrapperClassName}>
            {isProfile && <SpaDesktopProfileHeader isAuthed={isAuthed} />}
            {isMenu && <SpaDesktopMenuHeader isAuthed={isAuthed} />}
        </div>
    );
};

GlobalHeaderAdapter.defaultProps = {
    authData: {
        isAuthed: false,
    },
};

GlobalHeaderAdapter.propTypes = {
    authData: PropTypes.shape({
        isAuthed: PropTypes.bool,
    }),
};

const Header = (props) => {
    const {
        overlayIsOpen,
        isMobileNavigationOpen,
        onClickMobileNavigationButton,
        onClickMobileFilters,
        onClickBasketButton,
        onClickQuit,
        onClickBack,
        basketQuery,
        deliveryBasketQuery,
        isDeliveryMenuPage,
        isMenuLocation,
        showFilterChangedAnimation,

        mainDishesCountState,
        isSoldOutNotificationVisible,
        onClickCloseSoldOutNotification,

        setDishesUpdatingState,
        isCategoryChanging,

        onClickSubscriptionInfoOpen,

        updateBilling,
        addItemsToCartByPeriod,

    } = props;

    const authData = useContext(AuthContext);
    const {
        setHeaderHeight,
        headerHeight: savedHeaderHeight,
    } = useContext(DesignContext);

    const headerRef = useRef(null);

    useEffect(() => {
        const headerEl = headerRef.current;

        if (!headerEl) return;

        const height = headerEl.clientHeight;
        const isHeightChanged = height !== savedHeaderHeight;

        if (height && isHeightChanged) {
            setHeaderHeight(height);
        }
    });

    const spaHeaderRootClasses = classNames({
        'header__fixed-content': true,
        'profile-page-header': window.location.pathname.includes('/profile'),
    });

    // TODO: BR-1084 Profile header is here two ;))
    return (
        <div
            styleName={spaHeaderRootClasses}
            style={overlayIsOpen && isDesktop() ? { top: `${window.scrollY}px` } : {}}
            ref={headerRef}
        >
            <Desktop>
                <GlobalHeaderAdapter
                    isLinkHidden={isDeliveryMenuPage}
                    authData={authData}
                    onClickQuit={onClickQuit}
                />
            </Desktop>
            <Navigation
                authData={authData}
                onClickBack={onClickBack}
                onClickQuit={onClickQuit}
                onClickMobileFilters={onClickMobileFilters}
                onClickBasketButton={onClickBasketButton}
                onClickMobileNavigationButton={onClickMobileNavigationButton}
                isMobileNavigationOpen={isMobileNavigationOpen}
                isNavigationHidden={isDeliveryMenuPage}
                basketQuery={deliveryBasketQuery || basketQuery}
                isMenuLocation={isMenuLocation}
                showFilterChangedAnimation={showFilterChangedAnimation}
                mainDishesCountState={mainDishesCountState}
                isSoldOutNotificationVisible={isSoldOutNotificationVisible}
                onClickCloseSoldOutNotification={onClickCloseSoldOutNotification}
                setDishesUpdatingState={setDishesUpdatingState}
                isCategoryChanging={isCategoryChanging}
                onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpen}
                updateBilling={updateBilling}
                addItemsToCartByPeriod={addItemsToCartByPeriod}
            />
        </div>
    );
};

Header.propTypes = {
    overlayIsOpen: PropTypes.bool.isRequired,
    onClickMobileFilters: PropTypes.func,
    onClickBasketButton: PropTypes.func.isRequired,
    onClickQuit: PropTypes.func.isRequired,
    onClickMobileNavigationButton: PropTypes.func.isRequired,
    onClickBack: PropTypes.func.isRequired,
    isMobileNavigationOpen: PropTypes.bool.isRequired,
    isDeliveryMenuPage: PropTypes.bool,
    basketQuery: PropTypes.shape({}),
    deliveryBasketQuery: PropTypes.shape({}),
    isMenuLocation: PropTypes.bool.isRequired,
    showFilterChangedAnimation: PropTypes.bool.isRequired,

    mainDishesCountState: PropTypes.string.isRequired,
    isSoldOutNotificationVisible: PropTypes.bool,
    onClickCloseSoldOutNotification: PropTypes.func.isRequired,

    setDishesUpdatingState: PropTypes.func.isRequired,
    isCategoryChanging: PropTypes.bool.isRequired,

    onClickSubscriptionInfoOpen: PropTypes.func.isRequired,

    updateBilling: PropTypes.func.isRequired,
    addItemsToCartByPeriod: PropTypes.func.isRequired,
};

Header.defaultProps = {
    onClickMobileFilters: () => { },
    isSoldOutNotificationVisible: false,
    isDeliveryMenuPage: false,
    basketQuery: null,
    deliveryBasketQuery: null,
};

export default Header;
