import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BackButton } from 'app/components/ui/BackButton';

import './mobile-cart-header.scss';


const noop = () => {};


const MobileCartHeader = ({
    isLeftBlockShown = true,
    onClickBack = noop,
    position = 'static',
}) => {
    const rootClasses = classNames({
        'mobile-cart-header__root': true,
    });
    return (
        <div styleName={rootClasses} style={{ position }}>
            <div styleName="basket-header-back-button-wrapper">
                {isLeftBlockShown && <BackButton onClick={onClickBack} justify="start" />}
            </div>
        </div>
    );
};

MobileCartHeader.propTypes = {
    onClickBack: PropTypes.func,
    isLeftBlockShown: PropTypes.bool,
    position: PropTypes.string,
};

export default MobileCartHeader;
