import React from 'react';
import PropTypes from 'prop-types';

import './button-bottom-area.scss';


const ButtonBottomArea = ({
    children,
    zIndex,
    position,
}) => (
    <div
        styleName="button-bottom-area__root"
        style={{ zIndex, position }}
    >
        {children}
    </div>
);

ButtonBottomArea.propTypes = {
    children: PropTypes.node.isRequired,
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.string,
};

ButtonBottomArea.defaultProps = {
    zIndex: 0,
    position: null,
};

export default ButtonBottomArea;
