import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { tp } from 'app/utils/typograf';

/**
 * Находит в строке фрагмент по регулярному выражению и оборачивает в span с переданным css-классом
 * Оттипографливает переданный в children текст
 * По дефолту настроен на поиск чисел и оборачивание в класс .num
 */
const TextLine = (props) => {
    const {
        children,
        regexp,
        className,
    } = props;

    const text = tp.execute(children);
    const fragments = text.split(regexp);

    return (
        <>
            {fragments.map((fragment, index) => {
                if (regexp.test(fragment)) {
                    return (
                        <span key={index} className={className}>
                            {fragment}
                        </span>
                    );
                }
                return (
                    <Fragment key={index}>
                        {fragment}
                    </Fragment>
                );
            })}
        </>
    );
};

TextLine.propTypes = {
    children: PropTypes.string.isRequired,
    regexp: PropTypes.instanceOf(RegExp),
    className: PropTypes.string,
};

TextLine.defaultProps = {
    regexp: /(\d+(?:[:-]\d)?\d*)/,
    className: 'num',
};


export default TextLine;
