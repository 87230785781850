import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ClampLines from 'app/components/ClampLines';

import styles from './dish-title.scss';


export default function DishTitle(props) {
    const {
        text,
        strongTitle,
        title,
        caption,
        wide,
        linesCount,
    } = props;


    const classes = classNames({
        [styles['basket__dishes-type-item-title']]: true,
        [styles.wide]: wide,
    });

    return (
        <div
            className={classes}
            data-test-id="basket-dish__title"
        >
            {title
                ? (
                    <>
                        {strongTitle && <strong>{strongTitle}</strong>}
                        {`${title} ${caption}`}
                    </>
                )
                : (
                    <ClampLines
                        text={text}
                        linesCount={linesCount}
                        splitBy=""
                    />
                )
            }
        </div>
    );
}

DishTitle.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
    strongTitle: PropTypes.string,
    caption: PropTypes.string,
    wide: PropTypes.bool,
};

DishTitle.defaultProps = {
    text: '',
    title: '',
    strongTitle: '',
    caption: '',
    wide: false,
};
