/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { LOCALE_RU } from 'app/containers/LocaleProvider';

import Paragraph from 'app/components/ui/Paragraph';
import Heading from 'app/components/ui/Heading';

import './styles/trialBasketDescription.scss';
import formatPrice from 'app/utils/price';


const locales = {
    ru: {
        title: 'Что мы привезём?',
        row_1_prefixMinus: 'Уже',
        row_1_prefixPlus: 'Очищенные и',
        row_1: 'нарезанные ингредиенты',
        row_2: '+ Рецепты + Бесплатная доставка.',
        row_3: 'Вы готовите всего 5-20 мин.',
    },
    en: {
        title: 'How does it work?',
        row_1_prefixMinus: 'Cleaned',
        row_1_prefixPlus: 'Cleaned',
        row_1: 'and prepared ingredients',
        row_2: '+ Recipes + Free delivery',
        row_3: 'All dishes can be done in 5-20 min',
    },
};


const TrialBasketDescription = (props) => {
    const {
        locale, texts, TitleComponent, price, staticCommonPrice,
    } = props;

    const l = locales[locale];

    const title = texts ? texts.title : l.title;

    const row_1_prefixMinus = texts ? texts.row_1_prefixMinus : l.row_1_prefixMinus;
    const row_1_prefixPlus = texts ? texts.row_1_prefixPlus : l.row_1_prefixPlus;

    const row_1 = texts ? texts.row_1 : l.row_1;
    const row_2 = texts ? texts.row_2 : l.row_2;
    const row_3 = texts ? texts.row_3 : l.row_3;

    const isOriginalPriceExists = Boolean(staticCommonPrice);

    return (
        <div styleName="basket-trial-description-container">
            {TitleComponent || (
                <Heading level="3.2" styleName="basket-trial-description__title">
                    {title}
                </Heading>
            )}

            <Paragraph level="3.1" styleName="basket-trial-description__item">
                <span styleName="lowres--minus">{row_1_prefixMinus}</span>
                <span styleName="lowres--plus">{row_1_prefixPlus}</span>
                {' '}
                {row_1}
            </Paragraph>
            <Paragraph level="3.1" styleName="basket-trial-description__item">
                {row_2}
            </Paragraph>
            <Paragraph level="3.1" styleName="basket-trial-description__item">
                {row_3}
                &nbsp;
                {isOriginalPriceExists && (
                    <span styleName="basket-trial-description__item__crossed">
                        {formatPrice(staticCommonPrice)}
                        {' '}
                        ₽
                    </span>
                )}
            </Paragraph>
        </div>
    );
};

TrialBasketDescription.propTypes = {
    locale: PropTypes.string,
    texts: PropTypes.shape({}),
    TitleComponent: PropTypes.element,
};

TrialBasketDescription.defaultProps = {
    locale: LOCALE_RU,
    texts: {},
    TitleComponent: null,
};


export default TrialBasketDescription;
