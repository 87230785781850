import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'app/components/ui/Heading';
import './styles/setBasketCategoryHeader.scss';


const locales = {
    ru: {
        title: 'Набор 5 блюд',
    },
    en: {
        title: 'Mealkit of 5 dinners',
    },
};

const getTitle = (props) => {
    const {
        dishesCategories,
        title,
        count,
        locale,
    } = props;

    if (dishesCategories.length === 1 && dishesCategories[0].id === 'all') {
        return title;
    }

    if (dishesCategories.length === 1 && count === 5) {
        return locales[locale].title;
    }
    return title;
};

const SetsBasketCategoryHeader = (props) => {
    const currentTitle = getTitle(props);
    return (
        <div styleName="sets-basket-list-container">
            <Heading level="3.2" styleName="sets-basket-list__title">
                {currentTitle}
            </Heading>
        </div>
    );
};

export default SetsBasketCategoryHeader;
