import flatten from 'lodash/flatten';
import every from 'lodash/every';

import { NY_DESSERT, NY_DISHES } from 'app/utils/filters';


export const period = '2023-12-25';


export const extraPeriodCustomStart = period;
export const extraPeriodCustomEnd = '2023-12-28';

export const extraPeriodNYStart = '2023-12-29';
export const extraPeriodNYEnd = '2023-12-31';


export const enabledDishes = {
    // наборы на черном фоне
    set: [
        '3851',
        '3853',
        '4204',
        '4203',
        '3854',
        '3855',
        '3433',
    ],
    // остальное на белом фоне
    salads: [
        '3839',
        '57',
        '2753',
        '4208',
        '2754',
        '4209',
    ],
    main: [
        '4206',
        '3837',
        '3838',
        '4207',
        '2044',
        '3390',
        // '2042', // мандарины для моков и стабов
    ],
    dessert: [
        '1257',
        '3834',
        '4205',
        '4211',
        '2744',
    ],
    niceStuff: [
        '173',
        '9',
        'ym',
        '364',
        '1258',
        '172',
        '3386',
        '2561',
        '3433',
    ],
    gifts: [
        '3889',
        '3890',
        // '3835',
        // '3897',
    ],
};

export const dessertHP = [
    '3871',
    '3874',
    '3873',
    '3872',
];

export const nyMenuDishWithLabel = [
    '3884',
    '3888',
    '1246',
    '40',
    '1257',
    '122',
    '3834',
    '1135',
    '3835',
    '2744',
];

export const availableBasketDishIds = flatten(Object.values(enabledDishes));


/**
 * @param {string[]} basketDishesIds - An array of dish ID in the basket.
 * @return {string[]} An array of NY invoice tags.
 */
export function getInvoiceTags(basketDishesIds) {
    const isEveryDishIsDessert = every(
        basketDishesIds,
        (basketDishId) => enabledDishes.dessert.includes(basketDishId),
    );
    const isEveryDishIsNotDessert = every(
        basketDishesIds,
        (basketDishId) => !enabledDishes.dessert.includes(basketDishId),
    );
    if (isEveryDishIsDessert) {
        return [NY_DESSERT];
    }
    if (isEveryDishIsNotDessert) {
        return [NY_DISHES];
    }
    return [NY_DISHES, NY_DESSERT];
}
