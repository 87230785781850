import React from 'react';
import PropTypes from 'prop-types';

import { isStandaloneApp } from 'app/utils/browser';

import LINKS from 'app/const/links';
import Vk from './icons/vk.component.svg';
import Telegram from './icons/telegram.component.svg';
// import Messenger from './icons/messenger.component.svg';
import Phone from './icons/phone.component.svg';

import './contact-us-icons.scss';


const { SOCIETY, PHONE } = LINKS;

const linkAttrs = isStandaloneApp()
    ? ({ target: '_self' })
    : ({ target: '_blank', rel: 'noopener noreferrer' });


const iconsData = {
    vk: {
        href: SOCIETY.hrefVk,
        Icon: Vk,
        eventId: 'Outbound_Social_VK',
    },
    telegram: {
        href: SOCIETY.telegramHref,
        Icon: Telegram,
        eventId: 'Outbound_Social_Telegram',
    },
    phone: {
        href: PHONE.href,
        Icon: Phone,
        eventId: 'Button_Phone',
        itemProp: 'telephone',
    },
};


const ContactUsIcon = (props) => {
    const {
        href,
        onClickIcon,
        Icon,
        label,
        eventId,
        itemProp,
    } = props;
    return (
        <li styleName="mobile-nav__social-item">
            <a
                href={href}
                styleName={`mobile-nav__social-link ${label}`}
                aria-label={label}
                {...linkAttrs}
                data-event-id={eventId}
                onClick={onClickIcon}
                itemProp={itemProp}
            >
                <Icon />
            </a>
        </li>
    );
};
ContactUsIcon.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    eventId: PropTypes.string.isRequired,
    itemProp: PropTypes.string,
    Icon: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
    onClickIcon: PropTypes.func.isRequired,
};
ContactUsIcon.defaultProps = {
    itemProp: null,
};


const ContactUsIcons = (props) => {
    const {
        onClickIcon,
        visibleLinks,
        align,
    } = props;

    return (
        <ul styleName={`mobile-nav__social-links ${align}`}>
            {visibleLinks.map((key) => {
                const iProps = iconsData[key];
                return (
                    <ContactUsIcon
                        {...iProps}
                        key={key}
                        label={key}
                        onClickIcon={onClickIcon}
                    />
                );
            })}
        </ul>
    );
};

ContactUsIcons.propTypes = {
    onClickIcon: PropTypes.func,
    visibleLinks: PropTypes.arrayOf(PropTypes.string),
    align: PropTypes.oneOf(['left', 'center']),
};

ContactUsIcons.defaultProps = {
    onClickIcon: () => {},
    visibleLinks: ['vk', 'telegram', 'phone'],
    align: 'left',
};

export default ContactUsIcons;
