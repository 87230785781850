import React from 'react';

import css from './textShield.module.scss';

// попробуйте черемшу
import i0s from './wildgarlic/ingredients-0-s.img.svg';
import i0m from './wildgarlic/ingredients-0-m.img.svg';
import i0l from './wildgarlic/ingredients-0-l.img.svg';

// Попробуй савойскую капусту
import i1s from './cabbage/ingredients-1-s.img.svg';
import i1m from './cabbage/ingredients-1-m.img.svg';
import i1l from './cabbage/ingredients-1-l.img.svg';

// Попробуйте фенхель
import i2s from './fennel/ingredients-2-s.img.svg';
import i2m from './fennel/ingredients-2-m.img.svg';
import i2l from './fennel/ingredients-2-l.img.svg';

// Попробуй кольраби
import i3s from './kohlrabi/ingredients-3-s.img.svg';
import i3m from './kohlrabi/ingredients-3-m.img.svg';
import i3l from './kohlrabi/ingredients-3-l.img.svg';

// Попробуй мангольд
import i4s from './mongold/ingredients-4-s.img.svg';
import i4m from './mongold/ingredients-4-m.img.svg';
import i4l from './mongold/ingredients-4-l.img.svg';

// Попробуй бок-чой
import i5s from './bokchoy/ingredients-5-s.img.svg';
import i5m from './bokchoy/ingredients-5-m.img.svg';
import i5l from './bokchoy/ingredients-5-l.img.svg';

// Уже раскупили
import i6s from './soldout/soldout-s.img.svg';
import i6m from './soldout/soldout-m.img.svg';
import i6l from './soldout/soldout-l.img.svg';

// Не стал убирать импорты, чтобы не забыть, что в дев сборку эти шильды уже выгружены
// С летними травами
import herbsS from './herbs/herbs-s.img.svg';
import herbsM from './herbs/herbs-m.img.svg';
import herbsL from './herbs/herbs-l.img.svg';

// Урожай баклажанов
import eggplantsS from './eggplants/eggplants-s.img.svg';
import eggplantsM from './eggplants/eggplants-m.img.svg';
import eggplantsL from './eggplants/eggplants-l.img.svg';

// дачный урожай
import dachaS from './dacha/dacha-s.img.svg';
import dachaM from './dacha/dacha-m.img.svg';
import dachaL from './dacha/dacha-l.img.svg';

// урожай свеклы
import beetrootS from './beetroot/beetroot-s.img.svg';
import beetrootM from './beetroot/beetroot-m.img.svg';
import beetrootL from './beetroot/beetroot-l.img.svg';

// урожай цукини
import zucchiniS from './zucchini/zucchini-s.img.svg';
import zucchiniM from './zucchini/zucchini-m.img.svg';
import zucchiniL from './zucchini/zucchini-l.img.svg';


export type TShieldTextName = 'kohlrabi' | 'lentenMenu' | 'maslenitsa' | 'mongold' |
    'oktoberfets' | 'seafood' | 'setGreen' | 'setOrange' | 'setRed' | 'soldout' | 'wildgarlic' | 'women' |
    'cabbage' | 'fennel' | 'bokchoy' | 'february' | 'soldout' | 'herbs' | 'eggplants' | 'dacha' | 'beetroot' | 'zucchini'

interface ITextShieldProps {
    name: TShieldTextName,
}

const defaultPath = { s: '', m: '', l: '' };

const shieldMap = new Map<TShieldTextName, typeof defaultPath>([
    ['wildgarlic', { s: i0s, m: i0m, l: i0l }],
    ['cabbage', { s: i1s, m: i1m, l: i1l }],
    ['fennel', { s: i2s, m: i2m, l: i2l }],
    ['kohlrabi', { s: i3s, m: i3m, l: i3l }],
    ['mongold', { s: i4s, m: i4m, l: i4l }],
    ['bokchoy', { s: i5s, m: i5m, l: i5l }],
    ['soldout', { s: i6s, m: i6m, l: i6l }],
]);

export const TextShield = (props: ITextShieldProps) => {
    const { name } = props;

    const path = shieldMap.get(name) || defaultPath;

    if (!path) {
        return null;
    }

    return (
        <div
            className={css.textShieldRoot}
        >
            <div className={css.s}>
                <img className={css.textShieldImg} src={path.s} alt={name} />
            </div>
            <div className={css.m}>
                <img className={css.textShieldImg} src={path.m} alt={name} />
            </div>
            <div className={css.l}>
                <img className={css.textShieldImg} src={path.l} alt={name} />
            </div>
        </div>
    );
};
