import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UILoading from '../Loading';

import './button.scss';

const noop = () => { };

const UIButton = React.memo((props) => {
    const {
        // МОДИФИКАТОРЫ
        sizeType,
        colorType,
        elType,
        disabled,
        disabledColor,
        // HADLER
        onClick,
        // NODE
        children,
        // ATR
        loading = false,
        centerContent,
        applyNYStyles,
        ...attributes
    } = props;

    const ButtonElement = `${elType}`;

    /** STYLES  */
    const uiButtonClasses = cn({
        'ui-button': true,
        [sizeType]: true,
        [colorType]: true,
        [disabledColor]: Boolean(disabledColor),
    });

    return (
        <ButtonElement
            styleName={uiButtonClasses}
            onClick={onClick}
            disabled={disabled}
            {...attributes}
        >
            {children}
        </ButtonElement>
    );
});

UIButton.defaultProps = {
    elType: 'button',
    colorType: 'primary',
    sizeType: 'large',
    applyNYStyles: false,
    disabledColor: null,
};

UIButton.propTypes = {
    colorType: PropTypes.string,
    elType: PropTypes.string,
    sizeType: PropTypes.string,
    children: PropTypes.shape({}).isRequired,
    disabled: PropTypes.bool.isRequired,
    disabledColor: PropTypes.oneOf([null, 'primary-melon', 'primary-grey-salt']),
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    applyNYStyles: PropTypes.bool,
};

const UIButtonContent = React.memo((props) => {
    const {
        // МОДИФИКАТОРЫ
        disabled,
        disabledColor,
        sizeType,
        colorType,
        // NODE
        children,
        id,
    } = props;

    const uiButtonContentClasses = cn({
        'ui-button-content': true,
        disabled,
        [disabledColor]: Boolean(disabledColor),
        [sizeType]: true,
        [colorType]: true,
    });

    return (
        <div
            styleName="ui-button-content-conrainer"
            id={id}
        >
            <div
                styleName={uiButtonContentClasses}
            >
                {children}
            </div>
        </div>
    );
});

UIButtonContent.defaultProps = {
    colorType: 'primary',
    sizeType: 'large',
    disabledColor: null,
};

UIButtonContent.propTypes = {
    colorType: PropTypes.string,
    sizeType: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.oneOf([null]),
    ]).isRequired,
    disabled: PropTypes.bool.isRequired,
    disabledColor: PropTypes.oneOf([null, 'primary-melon', 'primary-grey-salt']),
};

const MainUiButton = (props) => {
    const {
        disabled = false,
        loading = false,
        onClick = noop,
        centerContent = null,
        applyNYStyles = false,
        className = null,
        disabledColor = null,
        href = null,
        children = null,
    } = props;

    const childeNode = useMemo(
        () => (centerContent || children), [centerContent, children],
    );

    const onClickHandler = useCallback((event) => {
        if (disabled || loading) {
            noop();
        } else {
            onClick(event);
        }
    }, [disabled, loading, onClick]);

    return (
        <UIButton
            {...props}
            href={href}
            disabled={disabled}
            className={className}
            applyNYStyles={applyNYStyles}
            disabledColor={disabledColor}
            onClick={onClickHandler}
        >
            <UIButtonContent
                {...props}
                disabledColor={disabledColor}
                disabled={disabled}
            >
                {loading ? <UILoading disabled={disabled} /> : childeNode}
            </UIButtonContent>
        </UIButton>
    );
};

MainUiButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledColor: PropTypes.oneOf([null, 'primary-melon', 'primary-grey-salt']),
    applyNYStyles: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,

    centerContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.oneOf([null]),
    ]),
};

export default MainUiButton;
