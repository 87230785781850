import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './features-content.scss';


const THEME_YELLOW = 'yellow';
const SIZE_BIG = 'big';

export default function FeatureFilters(props) {
    const { theme, size } = props;
    const containerClasses = classNames({
        feature: true,
        [`theme-${theme}`]: theme,
        [`size-${size}`]: size,
    });

    return (
        <div styleName={containerClasses}>
            <p styleName="feature__title">
                90% блюд раскупили.
                <br />
                Проверьте другие даты.
            </p>
            <p styleName="feature__content">
                Вы можете выбрать даты,
                <br />
                переключаясь между неделями
                <br />
                в разделе &laquo;Фильтры&raquo;.
            </p>
        </div>
    );
}

FeatureFilters.propTypes = {
    theme: PropTypes.oneOf([null, THEME_YELLOW]),
    size: PropTypes.oneOf([null, SIZE_BIG]),

};

FeatureFilters.defaultProps = {
    theme: null,
    size: null,
};
