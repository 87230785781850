import React from 'react';
import PropTypes from 'prop-types';

import '../inputs.scss';

const StreetInputMobile = ({
    // inputRef
    // eslint-disable-next-line react/prop-types
    value, onChange, inputRef, onBlur,
    placeholder,
}) => (
    <label htmlFor="street" styleName="input--has-float-label">
        <input
            styleName="input input--street-mobile"
            id="street-mobile"
            name="street"
            type="text"
            placeholder="Улица, дом"
            value={value}
            onChange={onChange}
            ref={inputRef}
            onBlur={onBlur}
            autoComplete="new-password"
        />
        <div styleName="input__label">{placeholder}</div>
    </label>
);

export default StreetInputMobile;

StreetInputMobile.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
};

StreetInputMobile.defaultProps = {
    placeholder: 'Улица, дом',
    onBlur: () => {},
};
