import React from 'react';
import PropTypes from 'prop-types';

import CloseIconImg from './close-icon.component.svg';

import './close-button.scss';

export function CloseButton(props) {
    const { onClick, disabled } = props;
    return (
        <button
            styleName="back-button__container"
            data-test-id="back-close"
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <CloseIconImg styleName="back-button__img" />
        </button>
    );
}

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

CloseButton.defaultProps = {
    disabled: false,
};
