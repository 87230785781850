import { gql } from '@apollo/client';
import { MenuDish } from '../fragments/dish.fragment';
import { Tag } from '../fragments/tag.fragment';
import { promocodeConditions } from '../fragments/promocode.fragment';

export default gql`
    query Basket(
        $period: String!
        $delivery_date: String
        $clear: Boolean
        $trial_scenario: trialScenario
    ) {
        cart(
            period: $period
            delivery_date: $delivery_date
            clear: $clear
            trial_scenario: $trial_scenario
        ) {
            period
            typeOfSet
            haveDishesFromTrial
            id
            type
            # primetiveSection @client
            sections {
                id
                title
                gift_category
                cartType
                items {
                    dish_id
                    type
                    portions
                    price
                    discount_price
                    promoDiscountPrice
                    is_promo
                    dish {
                        ...menuDishFragment,
                        parentId
                        tags { ...tagFragment }
                    }
                }

            }
            totals {
                # FRNT-2607
                # Легаси поля, от которых нужно отказаться
                total_price
                total_discount_price
                total_common_price
                delivery_price
                promocode_applied
                promocode_amount
                discount_amount
                static_discount_price
                static_common_price
                referral_amount

                # FRNT-2607
                # Новые поля, на которые нужно перейти и отказаться от использования полей выше
                dishesPrice
                deliveryPrice
                subscriptionDiscountAmount
                promocodeDiscountAmount
                specialDiscountAmount
                subscriptionPrice
                onlinePrice
                foodCardPrice
                cashPrice
            }
            discount_conditions {
                ...discountCondtionFragment
            }
        }

    }
    ${MenuDish}
    ${Tag}
    ${promocodeConditions}
`;
