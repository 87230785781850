/* eslint-disable camelcase */
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { analyticService } from 'global/services';

/* UTILS AND CONTEXT */
import { LOADER_APPEARING_TIME, loaderDispatchContext } from 'app/containers/contexts/loader.context';
import { isBasketFilledVar } from 'app/apollo/reaction';
import { isStandaloneApp } from 'app/utils/browser';

import {
    ADD_ITEMS_TO_CART_BY_SCHEMA,
} from 'app/graphql/network/basket/basketMutations';

/* HOOKS AND COMPOENTS */
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { useMutation } from '@apollo/client';
import { parseCategories, parseMenuType, parseTags } from 'app/utils/basketSchema';
import {
    useBasketPreviewAside,
    useFirstAvailableSpecificPeriod,
    useSliderCardsChain,
} from './hooks';
import { ISetsItem, TCardKey, cardLinkDataMap } from './sets.record';
import ABSets5Dishes from './ABSets5Dishes/ABSets5Dishes';
import { deleteTagReaction } from 'app/apollo/reactions/filters';

interface ISetsMobileSliderProps {
    isBasketEmpty: boolean,
    // eslint-disable-next-line no-unused-vars
    setTemporaryBasketPeriod: (I: boolean) => {},
    onSetPeriod: (startPeriod: string, value: true) => Promise<void>,
    basketQuery: any,
    openBasket: () => void,
}

const loaderTexts = {
    ru: { mainText: 'Заполняем корзину' },
    en: { mainText: 'Filling the cart' },
};

// ROOT
export const SetsMobileSlider = React.memo((props: ISetsMobileSliderProps) => {
    const {
        isBasketEmpty = false,
        basketQuery,
        openBasket,
    } = props;

    const loaderDispatch = useContext(loaderDispatchContext);
    const cardsChain = useSliderCardsChain();
    const history = useHistory();
    const {
        state: {
            eeAvailableDates,
            selectedFilterPeriod,
            filterPeriod,
        },
    } = useContext(menuDatesState);

    /* DA */
    const [mutateBasketByScema] = useMutation(ADD_ITEMS_TO_CART_BY_SCHEMA);

    /* HANDLERS */
    const handleFillBasketBy = async (cardKey: TCardKey) => {
        const data = cardLinkDataMap.get(cardKey);
        sessionStorage.setItem('gotBasketFromPreview', 'true');

        const currentSearchParams = new URLSearchParams(window.location.search);
        currentSearchParams.delete('filter_tag');

        // @ts-ignore
        window.history.replaceState(null, null, `?${currentSearchParams.toString()}`);

        // ПОКАЗЫВАЕТ ЛОДЕР
        // @ts-ignore
        loaderDispatch({
            type: 'show',
            payload: { autoHide: true, ...loaderTexts.ru },
        });

        const searchParams = data?.href.replace('/basket/', '');

        const menuType = parseMenuType({ search: searchParams });
        const tags = parseTags({ search: searchParams });
        // const categories = parseCategories(window.location);
        // @ts-ignore
        const params = new URLSearchParams(searchParams);
        params.delete('filter_tag');

        deleteTagReaction({ tagId: '1220', isUserChangesTags: true });

        mutateBasketByScema({
            variables: {
                period: filterPeriod,
                menuType,
                tags: tags || [],
                schema: [{ category_id: 3, count: 10 }],
            },
            onCompleted: () => {
                // УХОДИМ В БАСКЕТ В БАСКЕТ
                setTimeout(() => {
                    analyticService.push({
                        // @ts-ignore
                        setName: data.trackId,
                        eventName: 'Track_Click_Best_Set',
                    });
                    // @ts-ignore
                    history.push(`/basket/?${params}`);
                }, LOADER_APPEARING_TIME);
            },
            context: {
                step: 'app:mutate:SetsMobileSlider',
            },
        });
    };

    const openBasketPreviewAsidePanel = useBasketPreviewAside({
        openBasket,
        handleFillBasketBy,
        basketQuery,
        isBasketEmpty,
    });

    const handleClickLanding = (e: MouseEvent, data: ISetsItem) => {
        e.preventDefault();
        analyticService.push({
            setName: data.trackId,
            eventName: 'Track_Click_Best_Set',
        });
        const isApp = isStandaloneApp();

        if (isApp) {
            window.location.href = data.href;
            return;
        }

        window.open(data.href, '_blank');
    };

    const handleClickToSliderCard = (e: MouseEvent, cardKey: TCardKey) => {
        const data = cardLinkDataMap.get(cardKey);
        // @ts-ignore
        const isBasketUrl = data.href.includes('basket');

        if (!isBasketUrl) {
            // @ts-ignore
            handleClickLanding(e, data);
        } else {
            // @ts-ignore
            openBasketPreviewAsidePanel(e, data);
        }
    };

    /* SELECTORS */
    const isMenuEmpty = useMemo(
        () => (selectedFilterPeriod === filterPeriod) && !eeAvailableDates,
        [selectedFilterPeriod, filterPeriod, eeAvailableDates],
    );

    return (
        <>
            {/* new_sets */}
            <ABSets5Dishes
                isMenuEmpty={isMenuEmpty}
                cardsChain={cardsChain}
                handleClickToSliderCard={handleClickToSliderCard}
            />
            {/* old_sets */}
            {/* <BestSetsMobileSlider
                isMenuEmpty={isMenuEmpty}
                cardsChain={cardsChain}
                handleClickToSliderCard={handleClickToSliderCard}
            /> */}
        </>
    );
});
