import React from 'react';
import {
    useMutation, useReactiveVar, useApolloClient,
} from '@apollo/client';
import { useHistory, useLocation } from 'react-router';

/* */
import { getTrialScenarioFromUrl } from 'app/utils/trial-scenario';
import { analyticService } from 'global/services';
import { removeSchemaParams } from 'app/utils/basketSchema';

/* FETCH */
import {
    checkoutDeliveryDateVar,
    selectedPeriodVar,
    subscriptionTypeVar,
} from 'app/apollo/reaction';
import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
import {
    MUTATE_CART_FROM_MENU,
    DISH_FIELDS_FOR_GA_FRAGMENT,
} from './grapql/useAddToBasketMutation.graphql';


type handleAddToBasketMutationProps = {
    dishId: string
    portion: number
}

type TonBasketUpdatedAction = {
    dishId: string
}

const useOnBasketUpdatedAction = () => {
    const subscriptionType = useReactiveVar(subscriptionTypeVar);
    const client = useApolloClient();
    const history = useHistory();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const onBasketUpdatedAction = ({ dishId }: TonBasketUpdatedAction) => {
        const dishDataForGA = client.readFragment({
            id: `menuDish:${dishId}`,
            fragment: DISH_FIELDS_FOR_GA_FRAGMENT,
        }) || {};

        // Удаляет query параметры dish=xxxx.xxxx из url, если они есть
        if (urlParams.get('dishes')) {
            const search = removeSchemaParams(location.search);
            history.push({
                ...location,
                search,
            });
        }

        analyticService
            .push({
                eventName: 'add_to_cart',
                dishes: [dishDataForGA],
                source: 'menu',
                subscriptionType,
                typeOfSet: 'regular',
            })
            .push({
                eventName: 'add_to_card_legacy',
            });
    };
    return { onBasketUpdatedAction };
};

export function useAddToBasketMutation() {
    const { onBasketUpdatedAction } = useOnBasketUpdatedAction();
    const selectedPeriod = useReactiveVar(selectedPeriodVar);

    const [mutateCart, { loading: basketLoading }] = useMutation(MUTATE_CART_FROM_MENU, {
        variables: {
            period: selectedPeriod,
        },
        context: {
            step: 'menu:mutate:useAddToBasketMutation',
        },
        awaitRefetchQueries: true,
        // refetchQueries: [
        //     {
        //         query: BASKET_QUERY,
        //         variables: {
        //             period: selectedPeriod,
        //             delivery_date: checkoutDeliveryDate,
        //             trial_scenario: getTrialScenarioFromUrl(),

        //         },
        //         context: {
        //             step: 'menu:refetch:useAddToBasketMutation',
        //         },
        //     },
        // ],
    });

    const handleAddToBasketMutation = (
        { dishId, portion }: handleAddToBasketMutationProps,
    ) => {
        mutateCart({
            variables: {
                dish_id: dishId,
                portions: portion,
            },
            onCompleted: () => {
                onBasketUpdatedAction({ dishId });
            },
        });
    };

    return { handleAddToBasketMutation, basketLoading };
}
