/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { FETCH_DELIVERY_ADDRESS_SESSION } from 'app/graphql/network/deliveryAddress.query';
import { isSubscription } from 'app/const/subscription';

import { isDesktop } from 'app/utils/resolution';
import { BasketCategoriesContainer } from './components/BasketCategories';
import BasketWithCustomization from './BasketWithCustomization/index';
import { BASKET_PAGE_CART_SECTIONS_WITH_DISHES } from './graphql/basketListArea.graphql';

const BasketDishesList = (props) => {
    const {
        localeContext: { locale },
        // basketQuery: { cart: { sections } },
        selectedFilters: { selectedPeriod },
        menuDatesContext: { state: menuDatesState },
        invisibleCategories,
        invisibleDishes,
        showAddMoreButton,
        isEditEnabled,
        subscriptionType,
        preparedBasketAreas,
        // ACTIONS
        handleChangePortion,
        handleRemoveDish,
        handleOpenMobileDishDetails,
        handleAddMoreButtonClick,
        onClickMobilePortions,
        sections = { sections },
        // openDishDetails,
    } = props;

    const { data } = useQuery(FETCH_DELIVERY_ADDRESS_SESSION);

    const isSubscriptionActivated = useMemo(
        () => isSubscription(subscriptionType),
        [subscriptionType],
    );

    if (!data) return null;

    const { deliveryAddress: { address: { country_subdivision: subdivision } } } = data;

    return (
        <BasketCategoriesContainer
            subdivision={subdivision}
            preparedBasketAreas={preparedBasketAreas}
            invisibleCategories={invisibleCategories}
            invisibleDishes={invisibleDishes}
            showAddMoreButton={showAddMoreButton}
            isEditEnabled={isEditEnabled}
            isSubscriptionActivated={isSubscriptionActivated}
            categories={sections}
            locale={locale}
            selectedPeriod={selectedPeriod}
            subscriptionType={subscriptionType}
            menuDatesState={menuDatesState}
            // ACTIONS
            onChangePortion={handleChangePortion}
            onClickRemoveDish={handleRemoveDish}
            onClickMobilePortions={onClickMobilePortions}
            onClickMobileDishDetails={handleOpenMobileDishDetails}
            onClickAddMoreButton={handleAddMoreButtonClick}
        />
    );
};

const makePrimetiveSection = (sections) => {
    if (!sections) {
        return [];
    }

    const flatSection = sections.reduce((acc, section) => [...acc, ...section.items], []);
    // eslint-disable-next-line no-underscore-dangle
    const primetiveSection = flatSection.map((item) => `${item.__typename}:${item.dish_id}`);
    return primetiveSection;
};

const BasketListArea = (props) => {
    const {
        preparedBasketAreas: {
            list: { isBasketDishesList },
        },
        localeContext: { locale },
        handleAddMoreButtonClick,
        handleChangePortion,
        handleRemoveDish,
        onClickMobilePortions,
        mutateItemInCart,
        isCartUpdating,
    } = props;

    const { data } = useQuery(BASKET_PAGE_CART_SECTIONS_WITH_DISHES, {
        fetchPolicy: 'cache-first',
    });

    const d = makePrimetiveSection(data?.cart?.sections || []);
    const isDesktopResolution = isDesktop();

    if (!isDesktopResolution) {
        return (
            <BasketWithCustomization
                handleAddMoreButtonClick={handleAddMoreButtonClick}
                handleChangePortion={handleChangePortion}
                handleRemoveDish={handleRemoveDish}
                onClickMobilePortions={onClickMobilePortions}
                primetiveSection={d}
                mutateItemInCart={mutateItemInCart}
                isCartUpdating={isCartUpdating}
            />
        );
    }

    return (
        <>
            {isBasketDishesList && (
                <BasketDishesList
                    {...props}
                    sections={data?.cart?.sections || []}
                />
            )}
            {/* {isEmptyBasket && } */}
        </>
    );
};


export default React.memo(BasketListArea);
