import React, {
    useContext, useLayoutEffect, useMemo, useRef, useState, MutableRefObject,
} from 'react';
import classNames from 'classnames';
import compose from 'lodash/flowRight';
import { BR1137Dialog } from 'app/components/Dialog/BR1137Dialog';
import { UIButton, UIRadioIcon } from 'app/components/ui';
import { SelectDropdown } from 'app/components/ui/SelectDropdown';
import { MobileCitySelectModal } from 'global/header/components/MobileCitySelectModal/MobileCitySelectModal';

import { LocationContext } from 'app/containers/LocationProvider';
import withAvailableDeliveryDates from 'app/containers/withAvailableDeliveryDates';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { DIFFS, ONE_DAYS_IN_UTC_FORMAT } from 'app/containers/contexts/menuDates.consts';
import { formatPeriodMobile } from 'app/utils/date';
import { selectedPeriodVar } from 'app/apollo/reaction';
import { analyticService } from 'global/services';
import { citiesLocales as cities } from 'app/const/location';
import { TypeCities } from 'global/const/types/location';
import css from './mobileDeliveryDateSelectPopup.module.scss';

const addressPartRecord = {
    MSK: 'г Москва',
    SPB: 'г Санкт-Петербург',
    NIZ: 'г Нижний Новгород',
    KZN: 'г Казань',
};

function MobileDeliveryDateSelectPopup(props: any) {
    const { setIsOpen, deliveryDates } = props;

    const {
        locationKey,
        mutateAddreessHandler,
    } = useContext(LocationContext);

    const { filterQuery: { menuFilter: { periods } } } = useContext<any>(FiltersContext);
    const selectedPeriod = selectedPeriodVar();
    const [uiSelectedPeriod, setUiSelectedPeriod] = useState(selectedPeriod);
    const [isMobileSelectOpenned, setIsMobileSelectOpenned] = useState<boolean>(false);

    const {
        state: menuDatesContext,
        onSelectDate,
    } = useContext(menuDatesState);

    const [selectedRegion, setSelectedRegion] = useState(locationKey);
    const [hasScroll, setHasScroll] = useState(false);
    const [isFullyScrolled, setIsFullyScrolled] = useState<boolean>(false);
    const intervalsRef = useRef<HTMLDivElement>(null);

    const [selectedDate, setSelectedDate] = useState(menuDatesContext.dateFilterStatus);

    const selectedTimeEntityRef = useRef<any>(null);

    const options: Record<string, string>[] = useMemo(
        () => cities.map((e) => ({ ...e, title: e.value })) || {},
        [],
    );
    const [selectedOption, setSelectedOption] = useState(options.find((e) => e.id === locationKey) || {});

    const onSelectClickHandler = () => {
        setIsMobileSelectOpenned((prev) => !prev);
    };

    useLayoutEffect(() => {
        const c = intervalsRef.current;

        if (!c) return;

        const hs = c.clientHeight < c.scrollHeight;
        setHasScroll(hs);
    }, []);

    useLayoutEffect(() => {
        const c = intervalsRef.current;
        if (!c) return undefined;
        const handleScroll = () => {
            const fullyScrolled = c.scrollTop + c.clientHeight >= c.scrollHeight;
            setIsFullyScrolled(fullyScrolled);
        };
        c.addEventListener('scroll', handleScroll);
        return (): void => {
            c.removeEventListener('scroll', handleScroll);
        };
    }, []);

    /* REGION SELECT V3 */
    // const deliveryDateSelectCityButtonClasses = classNames({
    //     [css.deliveryDateSelectCityButton]: true,
    // });

    // const deliveryDateSelectCityButtunMaskClasses = classNames({
    //     [css.spb]: selectedRegion === 'SPB',
    //     [css.msk]: selectedRegion === 'MSK',
    //     [css.deliveryDateSelectCityButtonMask]: true,
    // });

    // -------------------------------------------------------------------------
    const daysPeriodSection = useMemo(() => {
        const daysPeriodItems = deliveryDates.map(
            (dd: any) => {
                const {
                    xValue,
                    day,
                    date,
                    month,
                } = dd;

                const isPeriodWithDaysSelected = uiSelectedPeriod === menuDatesContext.filterPeriod;
                const isSelectedDay = xValue === selectedDate;
                const selected = isPeriodWithDaysSelected && isSelectedDay;

                const dataTestId = xValue === ONE_DAYS_IN_UTC_FORMAT
                    ? 'tomorrow__mobile'
                    : 'x+day__mobile';

                const dayText = xValue === ONE_DAYS_IN_UTC_FORMAT ? 'Завтра' : day;

                return {
                    id: xValue,
                    selected,
                    buttonProps: {
                        onClick: () => {
                            setUiSelectedPeriod(menuDatesContext.filterPeriod);
                            setSelectedDate(xValue);
                            selectedTimeEntityRef.current = { lastEntityModified: 'day' };

                            if (!selectedTimeEntityRef.current?.dateModified) {
                                selectedTimeEntityRef.current.dateModified = true;
                            }
                        },
                        name: xValue,
                        'data-test-id': dataTestId,
                    },
                    dateText: `${dayText}, ${date} ${month}`,
                };
            });

        return {
            id: 'daysPeriodSection',
            items: daysPeriodItems,
        };
    }, [
        deliveryDates,
        menuDatesContext.filterPeriod,
        selectedDate,
        uiSelectedPeriod,
    ]);

    const weekPeriodsSection = useMemo(() => {
        const visibleFuturePeriods = periods
            .filter(({ isVisibleInFilter }: any) => isVisibleInFilter === 'visible')
            .filter((p: any) => p.start !== menuDatesContext.filterPeriod);

        const weekPeriodsItems = visibleFuturePeriods.map((period: any) => {
            // @ts-ignore
            const { start, end } = formatPeriodMobile(period, uiSelectedPeriod);

            const isPeriodWithDaysSelected = period.start === uiSelectedPeriod;
            const selected = isPeriodWithDaysSelected;

            return {
                id: period.end,
                selected,
                buttonProps: {
                    onClick: () => {
                        setUiSelectedPeriod(period.start);
                        selectedTimeEntityRef.current = { lastEntityModified: 'period' };

                        if (!selectedTimeEntityRef.current?.dateModified) {
                            selectedTimeEntityRef.current.dateModified = true;
                        }
                    },

                    'data-test-id': 'period__mobile',
                },
                dateText: `${start.day} ${start.month} — ${end.day} ${end.month}`,
            };
        }).filter((i: any) => Boolean(i));

        return {
            id: 'weekPeriodsSection',
            items: weekPeriodsItems,
        };
    },
        [periods, menuDatesContext.filterPeriod, uiSelectedPeriod],
    );


    const handleChangeRegion = (region: TypeCities): void => {
        setSelectedRegion(region);
        const selectedOptionItem = options.find((e) => e.id === region);

        if (selectedOptionItem) {
            setSelectedOption(selectedOptionItem);
        }

        if (selectedTimeEntityRef.current && !('regionModified' in selectedTimeEntityRef.current)) {
            selectedTimeEntityRef.current.regionModified = true;
            return;
        }
        selectedTimeEntityRef.current = { regionModified: true };
    };

    const submitForm = () => {
        // @ts-ignore
        const address = addressPartRecord[selectedRegion];
        mutateAddreessHandler({ address });
        setIsOpen(false);
        onSelectDate(selectedDate, menuDatesContext.filterPeriod);
        selectedPeriodVar(uiSelectedPeriod);

        const selectedTimeEntity = selectedTimeEntityRef.current;
        const dateModified = selectedTimeEntity?.dateModified ?? false;
        const regionModified = selectedTimeEntity?.regionModified ?? false;
        const itemKey = selectedTimeEntityRef.current.lastEntityModified;

        const analyticsEventsToSend = [
            {
                eventName: 'Filter_Change_City',
                filter_value: selectedRegion,
                check: regionModified,
            },
            {
                eventName: 'Filter_Change_Week',
                itemKey,
                action: 'Change_Week',
                periodIndex: periods.findIndex((p: any) => p.start === uiSelectedPeriod),
                index: DIFFS.indexOf(selectedDate),
                check: dateModified,
            },
        ];
        analyticsEventsToSend
            .filter((event) => Boolean(event.check))
            .forEach((event) => {
                analyticService.push(event);
            });
    };

    const itemsList = [...daysPeriodSection.items, ...weekPeriodsSection.items];

    return (
        // @ts-ignore
        <BR1137Dialog
            handleClose={() => setIsOpen(false)}
        >
            <div className={css.deliveryDateSelectRoot}>
                {/*  */}
                <div className={css.deliveryDateSelectTitle}>
                    Город доставки
                </div>
                {/*  */}
                <div
                    className={css.selectCityDropdownWrapper}
                    onClick={onSelectClickHandler}
                    role="none"
                >
                    <SelectDropdown<TypeCities>
                        options={cities}
                        callback={() => { }}
                        selectedOptionProp={selectedOption}
                    />
                    <MobileCitySelectModal
                        isOpen={isMobileSelectOpenned}
                        setIsOpen={setIsMobileSelectOpenned}
                        setCityCallback={(region: any) => handleChangeRegion(region)}
                        cities={cities}
                    />
                </div>
                <div className={css.deliveryDateSelectTitle}>
                    Дата доставки
                </div>
                <div
                    className={css.deliveryDateSelectListScrollWrapper}
                    ref={intervalsRef}
                >
                    <div
                        className={css.deliveryDateSelectList}
                        onTouchMove={(e) => e.stopPropagation()}
                    >
                        {itemsList.map((item) => (
                            <button
                                className={css.deliveryDateSelectListItem}
                                onClick={item.buttonProps.onClick}
                                type="button"
                                key={item.id}
                            >
                                <div className={classNames(
                                    css.deliveryDateSelectListItemText,
                                    item.selected ? css.selected : '',
                                )}
                                >
                                    {item.dateText}
                                </div>
                                <div className={css.deliveryDateSelectListItemCheckbox}>
                                    <UIRadioIcon checked={item.selected} />
                                </div>
                            </button>
                        ))}
                    </div>
                    <div
                        style={{
                            opacity: (!isFullyScrolled && hasScroll) ? 1 : 0,
                        }}
                        className={css.dateSelectListContainerShadow}
                    />
                </div>
            </div>
            <div className={css.deliveryDateSelectSubmitWrapper}>
                <UIButton onClick={submitForm}>
                    Готово
                </UIButton>
            </div>
        </BR1137Dialog>
    );
}

/* <div
    onClick={() => handleChangeRegion('MSK')}
    className={`${css.deliveryCitiesTab} ${selectedRegion === 'MSK' && css.activeTab}`}
>
    <div className={css.deliveryCitiesTabContentWrapper}>
        <div>Москва</div>
        <div className={css.subfield}>и область</div>
    </div>
</div> */


export default compose(
    withAvailableDeliveryDates,
)(MobileDeliveryDateSelectPopup);
