
const EVERYDAY_SET = {
    title: 'Выгодный',
    description: 'шок цена \n за порцию 315₽',
    price: '3 150',
    iconClass: 'profitable',
    isDynamicPrice: false,
    setType: 'tomorrow',
};

const PREMIUM_SET_GREEN_VERSION = {
    title: 'Green',
    description: 'легкие премиум ингредиенты',
    price: '4 716',
    iconClass: 'green',
    isDynamicPrice: false,
    setType: 'green',
};

const OUR_SET = {
    title: 'Топ 5',
    description: 'средняя \n оценка 5.0',
    price: '3 600',
    iconClass: 'top',
    isDynamicPrice: true,
    setType: 'our_set',
};


export const sliderCardContentMapV2 = new Map([
    ['EVERYDAY_SET', EVERYDAY_SET],
    ['PREMIUM_SET_GREEN_VERSION', PREMIUM_SET_GREEN_VERSION],
    ['OUR_SET', OUR_SET],
]);
