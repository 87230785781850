import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Velocity from 'velocity-animate';

import { usePrevious } from 'app/utils/hooks';
import MenuFilterOption from '../MenuFilterOption';
import Arrow from './arrow.svg';

import './menu-filter-option-list.scss';


const ANIMATION_OPTIONS = {
    duration: 400,
    easing: 'ease-in-out',
};

const SKIP_ANIMTION_OPTIONS = {
    duration: 0,
};

function animateExpand(el, options = {}) {
    Velocity(
        el,
        'slideDown',
        {
            ...ANIMATION_OPTIONS,
            ...options,
        },
    );
}

function animateCollapse(el, options = {}) {
    Velocity(
        el,
        'slideUp',
        {
            ...ANIMATION_OPTIONS,
            ...options,
        },
    );
}


export default function MenuFilterOptionList(props) {
    const {
        expandKey,
        title,
        options,
        isExpanded,
        isArrowHidden,
        isExpandAnimationEnabled,
        isCollapseAnimationEnabled,
        onClickExpand,
        onAdd,
        onRemove,
        locale
    } = props;

    const optionListRef = useRef();
    const prevIsExanded = usePrevious(isExpanded);

    useEffect(() => {
        const el = optionListRef.current;
        const skipAnimationOptions = { duration: 0 };

        if (prevIsExanded === undefined && el) {
            if (isExpanded) {
                animateExpand(el, skipAnimationOptions);
            } else {
                animateCollapse(el, skipAnimationOptions);
            }
            return;
        }

        const isExpandStateChanged = isExpanded !== prevIsExanded;
        if (!isExpandStateChanged) return;

        if (isExpanded) {
            const animationOptions = isExpandAnimationEnabled ? {} : SKIP_ANIMTION_OPTIONS;
            animateExpand(el, animationOptions);
        } else {
            const animationOptions = isCollapseAnimationEnabled ? {} : SKIP_ANIMTION_OPTIONS;
            animateCollapse(el, animationOptions);
        }
    });

    const sectionClasses = classNames({
        'menu-filter-section': true,
        'is-expanded': isExpanded,
    });

    const arrowClasses = classNames({
        'menu-filter-option__arrow': true,
        'is-expanded': isExpanded,
    });

    return (
        <div styleName={sectionClasses}>
            <button
                styleName="menu-filter-option__expand"
                type="button"
                onClick={() => onClickExpand({ key: expandKey, isExpanded: !isExpanded })}
            >
                <span>
                    {title}
                </span>
                {!isArrowHidden && (<Arrow styleName={arrowClasses} />)}
            </button>
            <div ref={optionListRef}>
                {options.map(option => (
                    <MenuFilterOption
                        key={option.id}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        locale={locale}
                        {...option}
                    />
                ))}
            </div>
        </div>
    );
}

MenuFilterOptionList.propTypes = {
    expandKey: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
    })).isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isArrowHidden: PropTypes.bool,
    isExpandAnimationEnabled: PropTypes.bool.isRequired,
    isCollapseAnimationEnabled: PropTypes.bool.isRequired,
    onClickExpand: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

MenuFilterOptionList.defaultProps = {
    isArrowHidden: false,
};
