import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { basketImageSize, getImageUrl } from 'app/utils/dish';
import DishImage from 'app/components/DishImage';
import Link, { LINK_TYPE_NAV } from 'app/components/Link';

import './dish-gift.scss';


const PRICE_PATTERN = /[0-9 ]+₽/;

const hansaLink = 'https://drive.google.com/file/d/1IyFEi80GSSA1dVx2Q9GPuRiWZmD3oJNX/view?usp=drive_link';

export default class DishGift extends React.Component {
    renderConten() {
        const {
            dishItem: {
                dish: {
                    id,
                    title,
                    caption,
                    previewImage,
                },
            },
        } = this.props;

        // const title = 'Мы учли 1 500 ₽ в итоговой сумме вашего заказа';
        const [discountPrice] = title.match(PRICE_PATTERN) || [];

        const isDiscountGift = Boolean(discountPrice);

        let titlePrefix;
        let titleSuffix;
        if (isDiscountGift) {
            [titlePrefix, titleSuffix] = title.split(discountPrice);
        }

        const imageUrl = getImageUrl({ url: previewImage, ...basketImageSize });

        const dishGiftClasses = classNames({
            'dish-gift': true,
            'dish-item-type-small': true,
            'dish-gift--discount': isDiscountGift,
        });

        const imageWrapClasses = classNames({
            'dish-gift__image-wrap': true,
            'dish-item-type-small': true,
            'dish-gift__image-wrap--discount': isDiscountGift,
        });

        const discountImageClasses = classNames({
            'dish-item-type-small': true,
            'dish-gift__discount-image': true,
        });

        return (
            <div styleName={dishGiftClasses}>
                <div styleName="dish-gift__content-wrap">
                    <div styleName={imageWrapClasses}>
                        <div styleName="dish-gift__image">
                            {isDiscountGift ? (
                                <div styleName={discountImageClasses}>
                                    <span styleName="price">
                                        +
                                        {discountPrice.slice(0, -1)}
                                    </span>
                                    <span styleName="price-rouble">
                                        &nbsp;₽
                                    </span>
                                </div>
                            ) : (
                                <DishImage
                                    url={imageUrl}
                                    alt={title}
                                    fit={id === 'barilla' ? 'contain' : 'cover'}
                                />
                            )}
                        </div>
                    </div>

                    <div styleName="dish-gift__info-wrap">
                        {!isDiscountGift ? (
                            <>
                                {id !== 'hansa' ? (
                                    <p styleName="dish-gift__info-content-title">
                                        Подарок в корзине!
                                    </p>
                                ) : (
                                    <p styleName="dish-gift__info-content-title">
                                        {title}
                                    </p>
                                )}

                                <p styleName="dish-gift__info-dish-title">
                                    {id !== 'hansa' && Boolean(title) && (
                                        <>
                                            {title}
                                            <br />
                                        </>
                                    )}
                                    {caption}

                                    {id === 'hansa' && (
                                        <>
                                            <br />
                                            Участвуют доставки
                                            <br />
                                            2-8 октября 2023.
                                            {' '}
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={hansaLink}
                                            >
                                                Подробнее
                                            </a>
                                        </>
                                    )}
                                </p>
                            </>
                        ) : (
                            <>
                                <p styleName="dish-gift__info-dish-title">
                                    {isDiscountGift ? (
                                        <>
                                            {titlePrefix}
                                            <span styleName="price">
                                                {discountPrice.slice(0, -1)}
                                            </span>
                                            <span styleName="price-rouble">
                                                &nbsp;₽
                                            </span>
                                            {titleSuffix}
                                        </>
                                    ) : (title)}
                                </p>
                                {caption && (
                                    <p styleName="dish-gift__info-dish-title">
                                        {caption}
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { onClick, linkTo } = this.props;

        return (
            <li styleName="dish-gift__wrap">
                {linkTo ? (
                    <Link href={linkTo} onClick={onClick} type={LINK_TYPE_NAV}>
                        {this.renderConten()}
                    </Link>
                ) : (
                    this.renderConten()
                )}
            </li>
        );
    }
}

DishGift.propTypes = {
    preparedBasketAreas: PropTypes.shape({
        basketStyleModifier: PropTypes.string.isRequired,
    }).isRequired,
    dishItem: PropTypes.shape({
        dish: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            caption: PropTypes.string,
            previewImage: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    linkTo: PropTypes.string,
    onClick: PropTypes.func,
};

DishGift.defaultProps = {
    linkTo: null,
    onClick: () => { },
};
