import { errorLink } from './errorLink';
import { retryLink } from './retryLink';
import { operationMapLink } from './operationMapLink';
import { authLink } from './authLink';
import { httpLink } from './httpLink';
import { userInterceptors } from './userInterceptors';
import { ultimaOperationLogerLink } from './ultimaOperationLoger';

export const linksAndInterceptorsChain = [
    // ultimaOperationLogerLink,
    userInterceptors, errorLink, retryLink, operationMapLink, authLink, httpLink,
];
