/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Responsive, { Desktop } from 'app/components/Responsive';
import { UIParagraph, UIHeading } from 'app/components/ui';

import CheckIcon2 from 'assets/svg/check-green2.component.svg';
import Dots from 'assets/svg/dots-long.component.svg';

import LoadingIcon from 'app/components/LoadingIcon';
import CodeInput from 'app/components/Form/Code';
import Timer from 'app/components/Authorization/Timer';
import Plate from 'app/components/Plate';
import ReactCodeInput from 'react-code-input';

import '../authorization.scss';
import './comfirmPincodeMobile.scss';


const v303Mod = 'v303';


function Confirm(props) {
    const {
        title,
        subtitle,
        timerSeconds,
        onTimerTick,
        resendingText,
        errorText,
        phone,
        confirmationCode,
        canResendPin,
        pincodeValidation,
        onChangeCode,
        onBlurCode,
        onClickBack, // TODO: rename
        setCodeInputRef,
        onTimerElapsed,
        onClickChangePhone,
        needDisplayPlate,
    } = props;

    const hasError = pincodeValidation === 'short' || pincodeValidation === 'wrong';

    const mainContainer = classNames({
        'mobile-auth__content-container': true,
        'need-display-plate': needDisplayPlate,
    });

    const mobileAuthTitleWrap = classNames({
        'mobile-auth__title-wrap': true,
        'with-subtitle': !!subtitle,
    });

    const resendingBtnStyle = classNames({
        'mobile-auth__link-button': true,
        'with-timer': true,
        disabled: !canResendPin,
    });

    return (
        <div styleName="mobile-auth__root-container">
            <div styleName={mainContainer}>
                <div styleName="mobile-auth__plate-container">
                    <Desktop>
                        <div styleName="thanks-plate">
                            <Plate size="compact" />
                        </div>
                    </Desktop>
                </div>

                <div styleName="mobile-auth__form-container">
                    <div styleName={mobileAuthTitleWrap}>
                        <h2 styleName="mobile-auth__title">{title}</h2>
                        {subtitle && (
                            <div styleName="mobile-auth__subtitle">
                                {subtitle}
                            </div>
                        )}
                    </div>
                    {/* <div styleName="mobile-auth__pincode-input"> */}
                    <div>
                        <form
                            styleName="mobile-auth__pincode-input"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <CodeInput
                                value={confirmationCode}
                                onChange={onChangeCode}
                                onBlur={onBlurCode}
                                inputRef={setCodeInputRef}
                                hasError={hasError}
                                errorText={errorText}
                                viewMod={v303Mod}
                            />
                            <div styleName="mobile-auth__input-icons">
                                {pincodeValidation === 'loading' && (
                                    <div styleName="mobile-auth__input-loading-icon">
                                        <LoadingIcon />
                                    </div>
                                )}
                                {pincodeValidation === 'correct' && (
                                    <div styleName="mobile-auth__input-correct-icon">
                                        <CheckIcon2 />
                                    </div>
                                )}
                            </div>
                            <input type="submit" value="pinCodeSubmit" style={{ visibility: 'hidden', position: 'fixed' }} />
                        </form>
                    </div>

                    <div styleName="mobile-auth__button--container">
                        <button
                            type="button"
                            aria-label={resendingText}
                            styleName={resendingBtnStyle}
                            onClick={onClickBack}
                            disabled={!canResendPin}
                        >
                            {`${resendingText} `}
                            {!canResendPin && (
                                <Timer
                                    timerSeconds={timerSeconds}
                                    onTimerTick={onTimerTick}
                                    onTimerElapsed={onTimerElapsed}
                                />
                            )}
                            {canResendPin && <Dots styleName="mobile-auth__dots" />}
                        </button>
                    </div>

                    <div styleName="mobile-auth__button--container">
                        {onClickChangePhone && (
                            <button
                                type="button"
                                aria-label="Изменить номер"
                                styleName="mobile-auth__link-button"
                                onClick={onClickChangePhone}
                            >
                                Изменить номер
                                <span styleName="mobile-auth__phone-number">{phone}</span>
                                <Dots styleName="mobile-auth__dots" />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const PincodeInput = (props) => {
    const {
        onChangeCode, confirmationCode, pincodeValidation, resetInputKey,
    } = props;

    const containerRef = useRef(null);

    const inputStyles = {
        inputStyle: {
            MozAppearance: 'textfield',
            borderRadius: '8px',
            height: 'inherit',
            backgroundColor: 'transparent',
            boxSizing: 'border-box',
            outline: 'none',
        },
    };

    // NOTE: Для совместимости с легаси
    const onChange = (value) => {
        onChangeCode({ target: { value } });
    };

    const onClickFocusButton = () => {
        containerRef.current.querySelector('input').focus();
    };

    return (
        <div
            styleName="pincode-input-root"
            ref={containerRef}
        >
            <div styleName="pincode-input-mask">
                <ReactCodeInput
                    type="tel"
                    fields={4}
                    {...inputStyles}
                    className={`pincode-input-mask-lib ${pincodeValidation}`}
                    inputMode="numeric"
                    onChange={onChange}
                    value={confirmationCode}
                    key={resetInputKey}
                    autoComplete="one-time-code"
                    autoFocus
                />
                {confirmationCode.length === 0 && (
                    <>
                        {/* eslint-disable-next-line */}
                        <button
                            type="button"
                            onClick={onClickFocusButton}
                            styleName="pincode-input-focus-button"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

const ConfirmPincodeMobile = (props) => {
    const [transitionState, setTransitionState] = useState('default');
    const {
        phone,
        confirmationCode,
        pincodeValidation,
        resendingText,
        canResendPin,
        timerSeconds,
        onTimerTick,
        onTimerElapsed,
        onChangeCode,
        onClickChangePhone,
        handleGetAnotherPincode,
        title,
        resetInputKey,
        hideBackButton,
    } = props;

    // action

    useEffect(() => {
        setTransitionState('entered');
    }, []);

    useEffect(() => {
        if (pincodeValidation === 'unmount') {
            setTransitionState('');
        }
    }, [pincodeValidation]);

    const confirmRootClasses = classNames({
        'auth-confirm-pincode': true,
        [transitionState]: true,
        [pincodeValidation]: true,
    });

    const pageTitle = pincodeValidation === 'wrong' ? 'Неверный код' : title;
    const isDisabled = ['loading'].includes(pincodeValidation);

    return (
        <div styleName={confirmRootClasses}>
            <div styleName="auth-confirm-pincode-title">
                <UIHeading level="3.2">
                    {pageTitle}
                </UIHeading>
            </div>
            <div styleName="auth-confirm-pincode-input">
                <PincodeInput
                    onChangeCode={onChangeCode}
                    confirmationCode={confirmationCode}
                    pincodeValidation={pincodeValidation}
                    resetInputKey={resetInputKey}
                />
            </div>
            {/* P4.2 */}
            <div styleName="auth-confirm-pincode-action">
                <button
                    type="button"
                    aria-label={resendingText}
                    onClick={handleGetAnotherPincode}
                    styleName="auth-confirm-pincode-action__item-wrapper"
                    disabled={!canResendPin || isDisabled}
                >
                    <UIParagraph
                        level="4.2"
                        styleName="auth-confirm-pincode-action__item"
                    >
                        {`${resendingText} `}
                        {!canResendPin && (
                            <Timer
                                timerSeconds={timerSeconds}
                                onTimerTick={onTimerTick}
                                onTimerElapsed={onTimerElapsed}
                            />
                        )}
                    </UIParagraph>
                </button>

                {!hideBackButton
                    && (
                        <button
                            type="button"
                            onClick={onClickChangePhone}
                            styleName="auth-confirm-pincode-action__item-wrapper"
                            disabled={isDisabled}
                        >
                            <UIParagraph
                                level="4.2"
                                styleName="auth-confirm-pincode-action__item"
                            >
                                Изменить&nbsp;
                                {phone}
                            </UIParagraph>
                        </button>
                    )
                }
            </div>
        </div>
    );
};


const forceMobileRenderContexts = [
    'step-by-step-checkout',
    'single-step-checkout',
];

const ConfirmResponsiveContainer = (props) => {
    const {
        renderContext,
    } = props;

    if (forceMobileRenderContexts.includes(renderContext)) {
        return <ConfirmPincodeMobile {...props} />;
    }

    return (
        <Responsive
            mobile={(<ConfirmPincodeMobile {...props} />)}
            desktop={(<Confirm {...props} />)}
        />
    );
};

ConfirmResponsiveContainer.propTypes = {
    renderContext: PropTypes.oneOf([
        'single-step-checkout',
        'step-by-step-checkout',
        'default',
    ]),
};

ConfirmResponsiveContainer.defaultProps = {
    renderContext: 'default',
};

export default ConfirmResponsiveContainer;


Confirm.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    resendingText: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    phone: PropTypes.string.isRequired,
    confirmationCode: PropTypes.string,
    canResendPin: PropTypes.bool,
    pincodeValidation: PropTypes.string,
    needDisplayPlate: PropTypes.bool,

    timerSeconds: PropTypes.number.isRequired,
    onTimerTick: PropTypes.func.isRequired,
    onTimerElapsed: PropTypes.func,

    onChangeCode: PropTypes.func.isRequired,
    onBlurCode: PropTypes.func.isRequired,
    onClickBack: PropTypes.func.isRequired,
    setCodeInputRef: PropTypes.func,
    onClickChangePhone: PropTypes.func,
};

Confirm.defaultProps = {
    confirmationCode: '',
    canResendPin: false,
    pincodeValidation: null,
    errorText: null,
    subtitle: null,
    needDisplayPlate: false,

    setCodeInputRef: () => { },
    onTimerElapsed: () => { },
    onClickChangePhone: null,
};
