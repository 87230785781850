/* eslint-disable import/order */
import React, { useContext, useMemo, useState } from 'react';
import { MobilePeriodSelectContainer } from 'app/components/Select';
import { LocaleContext } from 'app/containers/LocaleProvider';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { getDateData } from 'app/utils/date';
// simport { useSelectedFilters } from 'app/connect/connectToSelectedFilters';
import { selectedPeriodVar } from 'app/apollo/reaction';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { ONE_DAYS_IN_UTC_FORMAT } from 'app/containers/contexts/menuDates.consts';
import { AbstractSelectTag } from './SelectTag';

/* TYPES */
import { IFiltersContext } from 'app/containers/contexts/filters.context/filtersContext.type';

const formatPeriodToTagTitle = (start: string, end: string, locale: string) => {
    const firstDateMeta = getDateData(start, locale);
    const secondDateMeta = getDateData(end, locale);

    const monthShortNameForDate = firstDateMeta?.monthShortNameForDate === secondDateMeta?.monthShortNameForDate
        ? ''
        : firstDateMeta?.monthShortNameForDate;

    const fisrtDateString = `${firstDateMeta?.rawData?.date} ${monthShortNameForDate}`;
    const secondDateString = `${secondDateMeta?.rawData?.date} ${secondDateMeta?.monthShortNameForDate}`;
    return `${fisrtDateString} — ${secondDateString}`;
};

const generateDateDataFromDiff = (date: string, weekDayName: string) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const currentDate = d.getTime() / 1000;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayD = today.getTime() / 1000;


    const isTomorrow = `${(currentDate - todayD)}` === ONE_DAYS_IN_UTC_FORMAT;

    return isTomorrow ? 'завтра' : weekDayName;
};

export function DateSelectTag() {
    const [isDateSelectOpen, setIsDateSelectOpen] = useState(false);

    // Берем заначение языка
    const { locale } = useContext(LocaleContext);

    const { filterQuery: { menuFilter: { periods } } } = useContext<IFiltersContext>(FiltersContext);

    // Берем значение из проклятой задачи
    const {
        state: {
            selectedDate,
            filterPeriod,
        },
    } = useContext(menuDatesState);


    const selectedPeriod = selectedPeriodVar();

    const period = periods.find((p: any) => p.start === selectedPeriod);

    const {
        start: selectedPeriodStart,
        end: selectedPeriodEndRaw,
        // tags,
    } = period;

    /* SELECTORS | ИНТЕРПРАЙЗ ВЫЧЕСЛЕНИЯ */
    // Функция отдает разные данные по дате
    // TODO: когда нибудь ее надо переписать на TS, этой функции это очень нужно
    // TODO: этого не достаточно чтобы отобразить дату, нужно проверить еще и период
    const dateMeta = useMemo(
        () => getDateData(selectedDate, locale),
        [selectedDate, locale],
    );

    const { weekDayName } = dateMeta;

    const text = useMemo(() => {
        // Тут мы проверяем в одинаковых ли переодах код из проклятой задачи и период из url`а
        // Нужно для того чтобы отобразить либо интервал дат либо день
        const isFilterPeriodSelected = filterPeriod === selectedPeriod;
        return isFilterPeriodSelected
            ? generateDateDataFromDiff(dateMeta.rawData.dateValue, weekDayName)
            : formatPeriodToTagTitle(selectedPeriodStart, selectedPeriodEndRaw, locale);
    }, [
        weekDayName,
        filterPeriod,
        selectedPeriod,
        selectedPeriodStart,
        selectedPeriodEndRaw,
        locale,
        dateMeta.rawData.dateValue,
    ]);

    return (
        <>
            <AbstractSelectTag
                placeHolder="доставка"
                text={text}
                onClickHandler={() => setIsDateSelectOpen(!isDateSelectOpen)}
            />
            <MobilePeriodSelectContainer
                isSelectOpened={isDateSelectOpen}
                onClickPeriod={(nextSelectedPeriod: string) => { selectedPeriodVar(nextSelectedPeriod); }}
                onClickClose={() => setIsDateSelectOpen(false)}
            />
        </>
    );
}
