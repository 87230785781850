import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import plate from './img/plate.png';

import './empty-basket.scss';


const APPEARING_TIMEOUT = 1;


const locales = {
    ru: {
        title: 'В корзине пусто.',
        subTitle: 'Заполним ее вкусными блюдами?',

    },
    en: {
        title: 'Yor cart is empty',
        subTitle: 'Fill your basket with delicious dishes.',
    },
};


const EmptyBasket = (props) => {
    const { price, locale } = props;
    const isDeliveryEmptyBasket = !!price;

    const { title, subTitle } = locales[locale];

    return (
        <Transition
            in
            timeout={APPEARING_TIMEOUT}
            appear
        >
            {(state) => (
                <div styleName="basket__empty">
                    <div styleName={`basket__empty-text-container ${state}`}>
                        <div styleName="basket__empty-title" data-test-id="basket__empty-title">
                            {title}
                        </div>

                        {!isDeliveryEmptyBasket && (
                            <div styleName="basket__empty-subtitle">{subTitle}</div>
                        )}
                    </div>

                    <img src={plate} styleName={`plate-pic ${state}`} alt="тарелка картинка" />
                </div>
            )}
        </Transition>
    );
};

EmptyBasket.propTypes = {
    locale: PropTypes.string.isRequired,
    price: PropTypes.number,
};

EmptyBasket.defaultProps = {
    price: null,
};

export default EmptyBasket;
