import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './streetinput.scss';

export function AddressSuggestions(props) {
    const {
        suggestions,
        highlightedIndex,
        onClickSuggestion,
    } = props;

    return (
        <>
            {suggestions.map((suggestion, i) => {
                const {
                    data: {
                        country,
                        city,
                        city_with_type: cityWithType,
                        region,
                        region_with_type: regionWithType,
                    },
                    value,
                } = suggestion;

                let address = value.replace(`${cityWithType}, `, '');
                address = address.replace(`${regionWithType}, `, '');

                let cityAddress = `${regionWithType}`;
                if (city !== null && region !== city) {
                    cityAddress += `, ${city}`;
                }

                const itemClasses = classNames({
                    'street-item': true,
                    highlight: i === highlightedIndex,
                });

                return (
                    <button
                        key={value}
                        type="button"
                        styleName={itemClasses}
                        onClick={() => onClickSuggestion(suggestion)}
                        id="suggestion"
                    >
                        {address}
                        <div styleName="street-item__footer">{cityAddress}</div>
                    </button>
                );
            })}
        </>
    );
}

AddressSuggestions.propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        data: PropTypes.shape({
            country: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            city_with_type: PropTypes.string.isRequired,
            region: PropTypes.string.isRequired,
            region_with_type: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,

    highlightedIndex: PropTypes.number,

    onClickSuggestion: PropTypes.func.isRequired,
};

AddressSuggestions.defaultProps = {
    highlightedIndex: null,
};

