import React, {
    Suspense, useMemo, useReducer,
} from 'react';
import cn from 'classnames';

import LINKS from 'app/const/links';
import Link from 'app/components/Link';
import { useDynamicSetPrice } from 'app/components/SetsMobileSlider/ABSets5Dishes/hooks/useDynamicSetPrice';
import { cardLinkDataMap } from 'app/components/SetsMobileSlider/sets.record';
import { BurgerMenuReducer, initialBurgerMenuState } from './burgerMenuV2.store';
import { OrdinaryLink } from '../MobileSiteNavOrdinaryLinks/MobileSiteNavOrdinaryLink';
import { forSpecialOccasionsLinks, getAboutServiceLinks } from './linkv2.record';
import { BurgerMenuCardFrame } from './layout';

// IMGS
import arrowIconPath from './img/arrow.img.svg';
import callIconPath from './img/callIcon.img.svg';
import tgIconPath from './img/tgIcon.img.svg';
import vkIconPath from './img/vkIcon.img.svg';
import ToProfileIconPath from './img/profile.img.svg';
import LoginIconPath from './img/deliveries.img.svg';
// STYLES
import css from './burgerMenuV2.module.scss';

const { SPA_MENU, TRIAL_FIRST_ORDER_SET } = LINKS;

const ToProfileIcon = () => (
    <div className={css.burgerLoginRowIconFrame}>
        <img src={ToProfileIconPath} alt="toProfel" className={css.burgerLoginRowIconItem} />
    </div>
);

const LoginIcon = () => (
    <div className={css.burgerLoginRowIconFrame}>
        <img src={LoginIconPath} alt="login" className={css.burgerLoginRowIconItem} />
    </div>
);

interface ILoginRowProps {
    isAuthed: boolean
    onClickLogin: () => void
}

const LoginRow = (props: ILoginRowProps) => {
    const { isAuthed, onClickLogin } = props;

    const { icon, text } = useMemo(() => ({
        icon: isAuthed ? <LoginIcon /> : <ToProfileIcon />,
        text: isAuthed ? 'Мои доставки' : 'Войти',
    }), [isAuthed]);

    return (
        <BurgerMenuCardFrame>
            <button
                type="button"
                onClick={onClickLogin}
                className={css.burgerLoginRowRoot}
            >

                <div className={css.burgerLoginRowIcon}>
                    {icon}
                </div>
                <div className={css.burgerLoginRowText}>
                    {text}
                </div>
            </button>
        </BurgerMenuCardFrame>
    );
};

interface ISetBannerRowProps {
    isNewbie: boolean
}

const SetBannerRow = (props: ISetBannerRowProps) => {
    const { isNewbie } = props;

    if (!isNewbie) {
        return null;
    }

    return (
        <BurgerMenuCardFrame>

            <Link
                href={TRIAL_FIRST_ORDER_SET.hrefBasket}
                className={css.setBannerRowRoot}
            >
                <div className={css.setBannerRowBackground} />
            </Link>
        </BurgerMenuCardFrame>
    );
};

const FiveSetRow = (props: any) => {
    const { setTypeVar, cardKey } = props;

    const title = {
        EVERYDAY_SET: 'Выгодный',
        PREMIUM_SET_GREEN_VERSION: 'Green. Лёгкие ингредиенты',
        OUR_SET: 'Топ 5 лучших блюд',
    };

    const { hasDishesInSet } = useDynamicSetPrice(setTypeVar);

    if (!hasDishesInSet) {
        return null;
    }

    return (
        <div
            className={css.foldingMenuListItem}
        >
            {/* @ts-ignore */}
            <OrdinaryLink {...props} title={title[cardKey]} />
        </div>
    );
};

const FiveSetRowFallback = (props: any) => {
    const { cardKey } = props;

    const title = {
        EVERYDAY_SET: 'Выгодный',
        PREMIUM_SET: 'Green. Лёгкие ингредиенты',
        OUR_SET: 'Топ 5 лучших блюд',
    };

    // @ts-ignore
    const text = title[cardKey];

    return (
        <div
            className={css.foldingMenuListItem}
        >
            {text}
        </div>
    );
};

interface IFoldingMenuRow {
    locale?: 'ru' | 'en'
    //  eslint-disable-next-line no-unused-vars
    onClickNavLink?: (e: MouseEvent) => void
    isSetRowOpen: boolean,
    isSpecialRowOpen: boolean
    dispatch: any
}

const FoldingMenuRow = ({
    onClickNavLink = () => {},
    locale = 'ru',
    isSetRowOpen = false,
    isSpecialRowOpen = false,
    dispatch = () => {},
}: IFoldingMenuRow) => {
    /* SELECTORS */

    const iconTwoState = useMemo(
        () => (isSetRowOpen ? 'open' : 'close'),
        [isSetRowOpen],
    );

    const iconThreeState = useMemo(
        () => (isSpecialRowOpen ? 'open' : 'close'),
        [isSpecialRowOpen],
    );

    const fiveSetOrder = useMemo(
        () => ['EVERYDAY_SET', 'OUR_SET', 'PREMIUM_SET_GREEN_VERSION'],
        [],
    );

    const specialOccasionsLinks = useMemo(
        () => forSpecialOccasionsLinks({ locale, onClickNavLink }),
        [onClickNavLink, locale],
    );

    const fiveSetLinks = useMemo(
        () => {
            const fiveSetObj = fiveSetOrder.map((key) => {
                const obj = cardLinkDataMap.get(key);
                return {
                    ...obj,
                };
            });
            return fiveSetObj;
        },
        [fiveSetOrder],
    );

    /* STYLES */
    const rowTwoIconClasses = cn({
        [css.foldingMenuTitleButtonIcon]: true,
        [css[iconTwoState]]: true,
    });

    const rowThreeIconClasses = cn({
        [css.foldingMenuTitleButtonIcon]: true,
        [css[iconThreeState]]: true,
    });

    const spaLink = {
        titleTrKey: 'mobileNav.menuDishes',
        href: SPA_MENU.href,
        handler: onClickNavLink,
        type: 'navLink',
    };

    return (
        <BurgerMenuCardFrame>
            <div className={css.foldingMenuRowRoot}>
                <div className={css.foldingMenuItemsContainer}>
                    <div className={css.foldingMenuDividerWrapper}>
                        <div
                            className={css.foldingMenuTitleButton}
                        >
                            <OrdinaryLink {...spaLink} />
                            <img
                                src={arrowIconPath}
                                alt="arrow"
                                className={css.foldingMenuTitleButtonIcon}
                            />
                        </div>
                    </div>
                    <div className={css.foldingMenuDividerWrapper}>
                        <button
                            className={css.foldingMenuTitleButton}
                            type="button"
                            onClick={() => {
                                dispatch({
                                    type: 'TOGGLE_FOLDING_ROW',
                                    payload: { isSetRowOpen: !isSetRowOpen },
                                });
                            }}
                        >
                            <div className={css.foldingMenuTitleButtonText}>
                                Сеты 5 блюд
                            </div>
                            <img
                                src={arrowIconPath}
                                alt="arrow"
                                className={rowTwoIconClasses}
                            />
                        </button>
                        {iconTwoState === 'open' && (
                            <div className={css.foldingMenuList}>
                                {fiveSetLinks.map((item) => (
                                    <div
                                        key={item.trackId}
                                        className={css.foldingMenuListItem}
                                    >
                                        <Suspense fallback={<FiveSetRowFallback {...item} />}>
                                            <FiveSetRow {...item} />
                                        </Suspense>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {/*  Для особых случаев */}
                    <div className={css.foldingMenuDividerWrapper}>
                        <button
                            className={css.foldingMenuTitleButton}
                            type="button"
                            onClick={() => {
                                dispatch({
                                    type: 'TOGGLE_FOLDING_ROW',
                                    payload: { isSpecialRowOpen: !isSpecialRowOpen },
                                });
                            }}
                        >
                            <div className={css.foldingMenuTitleButtonText}>
                                Для особых случаев
                            </div>
                            <img
                                src={arrowIconPath}
                                alt="arrow"
                                className={rowThreeIconClasses}
                            />
                        </button>
                        {iconThreeState === 'open' && (
                            <div className={css.foldingMenuList}>
                                {specialOccasionsLinks.map((item) => (
                                    <div
                                        key={item.id}
                                        className={css.foldingMenuListItem}
                                    >
                                        <OrdinaryLink {...item.data} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </BurgerMenuCardFrame>
    );
};

interface IAboutRowProps {
    locale?: 'ru' | 'en'
    //  eslint-disable-next-line no-unused-vars
    onClickNavLink?: (e: MouseEvent) => void
    isAboutRowOpen: boolean
    dispatch: any
}

const AboutRow = ({
    locale = 'ru',
    onClickNavLink = () => {},
    isAboutRowOpen = false,
    dispatch = () => {},
}: IAboutRowProps) => {
    /* SELECTORS */

    const aboutServiceLinks = useMemo(
        () => getAboutServiceLinks({ locale, onClickNavLink }),
        [onClickNavLink, locale],
    );

    const iconAboutState = useMemo(
        () => (isAboutRowOpen ? 'open' : 'close'),
        [isAboutRowOpen],
    );

    /* STYLES */

    const rowAboutIconClasses = cn({
        [css.foldingMenuTitleButtonIcon]: true,
        [css[iconAboutState]]: true,
    });

    /* RENDER */

    return (
        <BurgerMenuCardFrame>
            <div className={css.aboutRowRoot}>
                <button
                    className={css.aboutRowButton}
                    type="button"
                    onClick={() => {
                        dispatch({
                            type: 'TOGGLE_FOLDING_ROW',
                            payload: { isAboutRowOpen: !isAboutRowOpen },
                        });
                    }}
                >
                    <div className={css.aboutRowText}>
                        О сервисе
                    </div>
                    <img
                        src={arrowIconPath}
                        alt="arrow"
                        className={rowAboutIconClasses}
                    />
                </button>
                {iconAboutState === 'open' && (
                    <div className={css.foldingMenuList}>
                        {aboutServiceLinks.map((item) => (
                            <div
                                key={item.id}
                                className={css.foldingMenuListItem}
                            >
                                <OrdinaryLink {...item.data} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </BurgerMenuCardFrame>
    );
};

interface IBurgerMenuV2Props {
    isAuthed?: boolean,
    locale?: 'ru' | 'en'
    //  eslint-disable-next-line no-unused-vars
    onClickNavLink?: (e: MouseEvent) => void
    onClickLogin?: () => void
    isNewbie?: boolean
}

export function BurgerMenuV2({
    isAuthed = false,
    onClickNavLink = () => {},
    onClickLogin = () => {},
    locale = 'ru',
    isNewbie = false,
}: IBurgerMenuV2Props,
) {
    const [burgerMenuState, dispatch] = useReducer(BurgerMenuReducer, initialBurgerMenuState);

    return (
        <div
            className={css.burgerMenuV2Root}
            aria-label="burger-menu-v2-root"
        >
            <LoginRow
                isAuthed={isAuthed}
                onClickLogin={onClickLogin}
            />
            <SetBannerRow
                // onClickNavLink={onClickNavLink}
                isNewbie={isNewbie}
            />
            <FoldingMenuRow
                onClickNavLink={onClickNavLink}
                locale={locale}
                isSetRowOpen={burgerMenuState.isSetRowOpen}
                isSpecialRowOpen={burgerMenuState.isSpecialRowOpen}
                dispatch={dispatch}
            />
            <AboutRow
                onClickNavLink={onClickNavLink}
                locale={locale}
                isAboutRowOpen={burgerMenuState.isAboutRowOpen}
                dispatch={dispatch}
            />
            <div className={css.burgerMenuV2FootRoot}>
                <div className={css.burgerMenuV2TimeOpen}>
                    Ежедневно
                    <br />
                    с 6:00 до 23:00
                </div>
                <div className={css.burgerFootSocialLinkWrapper}>
                    <a
                        href="https://vk.me/elementaree"
                        className={css.burgerFootSocialLinkItem}
                    >
                        <img src={vkIconPath} alt="" className="" />
                    </a>
                    <a
                        href="tel:+78005504612"
                        className={css.burgerFootSocialLinkItem}
                    >
                        <img src={callIconPath} alt="" className="" />
                    </a>
                    <a
                        href="https://t.me/elementaree_bot"
                        className={css.burgerFootSocialLinkItem}
                    >
                        <img src={tgIconPath} alt="" className="" />
                    </a>
                </div>
            </div>
        </div>
    );
}
