import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { isDesktop } from 'app/utils/resolution';

import stubMobile from './stub-image/stub-m.jpg';
import stubDesktop from './stub-image/stub-d.jpg';
import stubWide from './stub-image/stub-wide.jpg';

import styles from './dish-image.scss';


const DEFAULT_IMG_WPRAPPER_SIZE = {
    width: 1,
    height: 1,
};


export default class DishImage extends React.Component {
    static propTypes = {
        url: PropTypes.string,
        fallbackUrl: PropTypes.string,
        alt: PropTypes.string,
        className: PropTypes.string,
        useWideStubImage: PropTypes.bool,

        scrollPosition: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        useLazyLoading: PropTypes.bool,

        onLoadError: PropTypes.func,
        onLoad: PropTypes.func,

        fit: PropTypes.oneOf(['contain', 'cover']),
    }

    static defaultProps = {
        url: null,
        fallbackUrl: null,
        alt: null,
        useWideStubImage: false,

        className: '',
        scrollPosition: null,
        useLazyLoading: false,

        onLoadError: () => {},
        onLoad: null,
        fit: 'cover',
    }

    state = {
        load: 'url', // fallbackUrl, error
    };

    handleLoadError = () => {
        const { fallbackUrl, onLoadError } = this.props;
        const { load } = this.state;

        if (load === 'url' && fallbackUrl) {
            this.setState({ load: 'fallbackUrl' });
        } else {
            onLoadError();
            this.setState({ load: 'error' });
        }
    };

    render() {
        const {
            url,
            fallbackUrl,
            className,
            alt,
            useWideStubImage,
            scrollPosition,
            useLazyLoading,
            fit,
        } = this.props;

        const { load } = this.state;

        let src = load === 'url'
            ? url || fallbackUrl
            : fallbackUrl || url;

        if ((!url && !fallbackUrl) || load === 'error') {
            const stubSrc = isDesktop() ? stubDesktop : stubMobile;
            src = useWideStubImage ? stubWide : stubSrc;
        }

        const commonProps = {
            alt: alt || url,
            src,
            className: `${className} ${styles.image} ${styles[fit]}`,
            onError: this.handleLoadError,
        };

        return (
            <div styleName="image-container">
                {useLazyLoading
                    ? (
                        <LazyLoadImage
                            threshold={400}
                            {...commonProps}
                            {...DEFAULT_IMG_WPRAPPER_SIZE}
                            scrollPosition={scrollPosition}
                        />
                    )
                    // eslint-disable-next-line jsx-a11y/alt-text
                    : <img {...commonProps} />
                }
            </div>
        );
    }
}
