import React, { FC } from 'react';
import css from './BasketNewUpsell.module.scss';
import BasketCategoriesList from './components/BasketCategoriesList';
import BasketUpsellSelections from './components/BasketUpsellSelections';
import BasketGreenSelection from './components/BasketGreenSelection';


const BasketNewUpsell: FC = () => (
    <div className={css.basketNewUpsellRoot}>
        <BasketCategoriesList />
        <BasketUpsellSelections />
        <BasketGreenSelection />
    </div>
);

export default BasketNewUpsell;
