import React, { FC } from 'react';
import css from './BasketUpsellSelectionsCard.module.scss';
import { ISelection } from '../../types';

interface ISelectionCard extends Pick<ISelection, 'title' | 'iconPath'> {
    onClickHandler: () => void
}
const BasketUpsellSelectionsCard: FC<ISelectionCard> = (props: ISelectionCard) => {
    const { title, iconPath, onClickHandler } = props;
    const fullIconPath = `${require(`../../assets/selections/${iconPath}.jpg`)}`;
    return (
        <div
            className={css.basketUpsellSelectionsCardRoot}
            onClick={onClickHandler}
            role="none"
        >
            <img
                className={css.basketUpsellSelectionsIconWrapper}
                src={fullIconPath}
                alt="selection_icon"
            />
            <span>{title}</span>
        </div>
    );
};

export default BasketUpsellSelectionsCard;
