import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';


export default class PushRedirect extends React.Component {
    componentDidMount() {
        const {
            path,
            useUrlFromQuery,
        } = this.props;

        const { to } = queryString.parse(window.location.search);
        const href = useUrlFromQuery ? decodeURIComponent(to) : `https://elementaree.ru${path}`;

        setTimeout(() => {
            // window.location.href = 'https://elementaree.ru/2020';
            window.location.href = href;
        }, 1000);
    }

    render() {
        return <div />;
    }
}

PushRedirect.propTypes = {
    path: PropTypes.string,
    useUrlFromQuery: PropTypes.bool,
};

PushRedirect.defaultProps = {
    path: '',
    useUrlFromQuery: false,
};
