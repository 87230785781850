import React, { FC, useEffect } from 'react';
import classNames from 'classnames';

import formatPrice from 'app/utils/price';
import { useDynamicSetPrice } from './hooks/useDynamicSetPrice';

import { ISetCard } from './types';
import { TCardKey } from '../sets.record';

import SetSmallShieldIcon from './img/set320.component.svg';
import SetMediumShieldIcon from './img/set375.component.svg';

import css from './sets5dishes.module.scss';


const cardShildColorMap: Record<TCardKey, any> = {
    TRIAL_FIRST_ORDER_SET: {
        bg: 'green',
        text: 'green',
    },
    EVERYDAY_SET: {
        bg: '#FE645B',
        text: '#FFD8CC',
    },
    PREMIUM_SET_GREEN_VERSION: {
        bg: '#91D579',
        text: '#CFF9C8',
    },
    OUR_SET: {
        bg: '#FF9371',
        text: '#FFD8CC',
    },
};

const Sets5DishesCardItem: FC<ISetCard> = ({
    isDynamicPrice,
    title = '',
    description = '',
    price = '',
    disabled = false,
    iconClass = null,
    onClick = () => { },
    cardKey,
}: ISetCard) => {
    const formattedDescription = description.split('\n').map((item) => (
        <div key={item}>
            {item}
            <br />
        </div>
    ));

    const cardClass = classNames({
        [css.sliderCard]: true,
        [css[iconClass || '']]: Boolean(iconClass),
        mock: !iconClass,
    });

    const smallShieldClasses = classNames({
        [css.cardShield]: true,
        [css.cardShieldSmall]: true,
    });

    const mediumShieldClasses = classNames({
        [css.cardShield]: true,
        [css.cardShieldMedium]: true,
    });

    return (
        <button
            className={cardClass}
            onClick={onClick}
            type="button"
            disabled={disabled}
        >

            <div className={css.infoTextContainer}>
                <div className={css.infoTitle}>
                    {title}
                </div>
                <div className={css.infoDescription}>
                    {formattedDescription}
                </div>
            </div>
            {cardKey && (
                <>
                    <div className={smallShieldClasses}>
                        <SetSmallShieldIcon
                            fill={cardShildColorMap[cardKey].bg}
                            style={{ color: cardShildColorMap[cardKey].text }}
                        />
                    </div>
                    <div className={mediumShieldClasses}>
                        <SetMediumShieldIcon
                            fill={cardShildColorMap[cardKey].bg}
                            style={{ color: cardShildColorMap[cardKey].text }}
                        />
                    </div>
                </>
            )}
            <div className={css.cutoutText}>
                <div className={css.cutoutTextWrapper}>
                    <div className={css.priceText}>
                        {(isDynamicPrice)
                            ? formatPrice(price)
                            : price}
                    </div>
                </div>
            </div>
        </button>
    );
};

const Sets5DishesCardWrapper: FC<ISetCard> = (props: ISetCard) => {
    const {
        setType, isDynamicPrice, price, setHasNoDishes = () => {},
    } = props;
    const { priceOfSet, hasDishesInSet } = useDynamicSetPrice(setType);

    useEffect(
        () => {
            if (!hasDishesInSet) {
                setHasNoDishes(true);
                return;
            }
            setHasNoDishes(false);
        },
        [hasDishesInSet, setHasNoDishes],
    );

    if (!hasDishesInSet) {
        return null;
    }

    return (
        <Sets5DishesCardItem
            {...props}
            price={
                isDynamicPrice
                    ? priceOfSet
                    : price
            }
        />
    );
};

export { Sets5DishesCardWrapper as Sets5DishesCard };
