import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import formatPrice from 'app/utils/price';

import './check-price-line.scss';



export const CheckPriceLine = (props) => {
    const {
        sign,
        price,
        type,
    } = props;

    const formatted = formatPrice(price);

    const className = cn({
        'basket-check__row-nowrap': true,
        'basket-check__row-text': type === 'regular',
        'basket-check__row-total-text': type === 'total',
    });

    return (
        <p styleName={className}>
            <span styleName="num">
                {price !== 0 && sign}
                {formatted}
            </span>
            <span styleName="rub">
                ₽
            </span>
        </p>
    );
};

CheckPriceLine.propTypes = {
    sign: PropTypes.string,
    price: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['regular', 'total']),
};

CheckPriceLine.defaultProps = {
    sign: null,
    type: 'regular',
};
