import React, { FC } from 'react';
import classNames from 'classnames';
import css from './DishDetailsSetDescription.module.scss';
import { ISetDescription, IDescriptionBlock } from './types';
import SetDescriptionCollection from './setDescription.record';

interface IProps {
    id: string
}

export const DishDetailsSetDescription: FC = ({ id }: IProps) => {
    const descriptionRecordItem: ISetDescription = SetDescriptionCollection[id];
    const { descriptionContent: { uiCards } } = descriptionRecordItem;
    return (
        <div className={css.dishDetailsSetDescriptionRoot}>
            {uiCards.map((item: IDescriptionBlock) => {
                const isDescriptionInList = item.listType === 'list';
                const iconClasses = classNames({
                    [css.dishDetailsSetDescriptionItemImageContainer]: true,
                    [css.dishDetailsSetDescriptionItemImageContainerLarge]: item.layoutType === 'large',
                    [css[item.iconClassName]]: true,
                });
                return (
                    <div
                        key={item.iconClassName}
                        className={css.dishDetailSetDescriptionItem}
                    >
                        <div className={css.dishDetailsSetDescriptionItemTitle}>{item.title}</div>
                        <div className={iconClasses} />
                        {Boolean(item?.descTitle) && (
                            <div className={css.dishDetailsSetDescriptionDescTitle}>
                                {item.descTitle}
                            </div>
                        )}
                        {isDescriptionInList
                            ? (
                                <div className={css.dishDetailsSetDescriptionList}>
                                    {item.descriptionList.map((str) => (
                                        <div
                                            key={str}
                                            className={css.dishDetailsSetDescriptionListItem}
                                        >
                                            <div className={css.dishDetailsSetDescriptionListItemBullet} />
                                            <div className={css.dishDetailsSetDescriptionListItemText}>{str}</div>
                                        </div>
                                    ))}
                                </div>
                            )
                            : <div className={css.dishDetailsSetDescriptionParagraph}>{item.descriptionList[0]}</div>
                        }
                    </div>
                );
            })}
        </div>
    );
};


