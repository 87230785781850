import { graphql } from '@apollo/client/react/hoc';
import Raven from 'raven-js';
import compose from 'lodash/flowRight';
import queryString from 'query-string';

/* NETWORK */
import USER_QUERY from 'app/graphql/network/auth/userQuery';
import {
    ADD_ITEM_TO_CART,
    ADD_ITEMS_TO_CART_BY_ID_LIST,
    ADD_ITEMS_TO_CART_BY_SCHEMA,
    ADD_ITEMS_TO_CART_BY_PERIOD,
} from 'app/graphql/network/basket/basketMutations';
import billingMutation from 'app/graphql/network/billing';

/* CLIENT */
import { GET_SUBSCRIPTION_TYPE } from 'app/graphql/client/subscription';
import { GET_PAYMENT_METHOD } from 'app/graphql/client/payment';
import { GET_PROMOCODE } from 'app/graphql/client/promocode';
import { BASKET_FILLED_STATUS } from 'app/graphql/client/basket';

/* HOCS */
// import client from 'app/apollo/client';

import { withAuthContext } from 'app/containers/AuthContainer';
import { getBillingPaymentMethod } from 'app/containers/withBillingParams';
import { withFiltersContext } from 'app/containers/contexts/filters.context/filters.context';

import { removeSchemaParams } from 'app/utils/basketSchema';
import { withBasketQuery } from './connectToBasket';
import { setSubscriptionTypeReaction } from '../apollo/reactions/subscription';
import { setPromocodeReaction } from '../apollo/reactions/promocode';
import { withSelectedFilters } from './connectToSelectedFilters';


/*  UTILS  */

const reportError = (env) => (error) => {
    const { title, payload = null } = env;
    Raven.captureMessage(title, {
        extra: {
            error,
            scope: 'mainConnect.js',
            payload,
        },
        tags: {
            type: 'Response error',
        },
    });
};


const onUserFetchedBehaviorRecord = [
    {
        eventName: 'initUserSentryContext',
        check: (data) => data.user,
        /**
        * @description Ставит данные о юзере в sentry для более удобной навигации
        * Ошибки будет ассоциированы с пользователем
        */
        emit: (data) => {
            const {
                user: {
                    id, phone, email, region,
                },
            } = data;
            Raven.setUserContext({
                id, phone, email, region,
            });
        },
    },
    {
        eventName: 'initUserPersonalPromo',
        check: (data, props) => {
            const { location: { search, pathname } } = props;

            const queryObject = queryString.parse(search);

            const isPosiblePathnameRange = ['/menu/', '/basket/', '/profile/'].includes(pathname);
            const isPersonalPromo = Boolean(data.user?.personalPromo?.linkParams);
            return ![isPersonalPromo, !queryObject?.promo_code, isPosiblePathnameRange].includes(false);
        },

        /**
        * @description Ставит персональное промо, если оно есть
        * @see Jira:FRNT-2408
        */
        emit: async (data) => {
            // Приходят из запроса
            const {
                promo_code: promocode,
                payment,
            } = queryString.parse(data.user.personalPromo.linkParams);

            setPromocodeReaction({ promocode });

            if (payment === 'online') {
                // note: вы этом блоке не нужен вызов мутации updateBilling так как эта мутация вызывается
                // в withBillingParams
                setSubscriptionTypeReaction({ type: 'singlePay' });
            }
        },
    },
];

/**
  * @description Инициирует состояния связанное userQuery. Выполняется после первого запроса User'a
  * @example Выставляет персональное промо если оно есть, выставляет userContext в sentry
 */
const initUserDeps = (props) => (data) => {
    if (!data.user) {
        return null;
    }
    onUserFetchedBehaviorRecord
        .filter((event) => event.check(data, props))
        .forEach((event) => { event.emit(data, props); });
    return '';
};

/* CONNECT */

const connect = (Component) => compose(
    graphql(BASKET_FILLED_STATUS, { name: 'basketFilledStatus' }),
    withSelectedFilters,
    graphql(GET_SUBSCRIPTION_TYPE, { name: 'subscriptionTypeQuery' }),
    graphql(GET_PAYMENT_METHOD, { name: 'paymentMethod' }),
    graphql(GET_PROMOCODE, { name: 'promocode' }),
    graphql(ADD_ITEM_TO_CART, {
        name: 'addToBasket',
        options: (props) => {
            if (props.match.params.deliveryId) return {};
            return {
                onCompleted: () => {
                    const {
                        history,
                        location,
                    } = props;

                    const search = removeSchemaParams(location.search);
                    history.push({
                        ...location,
                        search,
                    });
                },
            };
        },
    }),
    graphql(ADD_ITEMS_TO_CART_BY_ID_LIST, { name: 'addItemsToCartByIdList' }),
    graphql(ADD_ITEMS_TO_CART_BY_SCHEMA, { name: 'addItemsToCartBySchema' }),
    graphql(ADD_ITEMS_TO_CART_BY_PERIOD, { name: 'addItemsToCartByPeriod' }),

    withFiltersContext,

    graphql(USER_QUERY, {
        name: 'userQuery',
        options: (props) => ({
            onError: reportError({ title: '[Auth]: userQuery auth error' }),
            onCompleted: initUserDeps(props),
            context: {
                step: 'app:init:mainConnect',
            },
        }),
        onCompleted: () => {
            // console.log('main connect data', data);
        },
    }),

    withBasketQuery,
    graphql(billingMutation, {
        name: 'updateBilling',
        options: (props) => {
            /*
                Добавляет дефолтные значения параметров, если они не переданы в мутацию явно
            */
            const {
                subscriptionTypeQuery: { subscriptionType },
                paymentMethod: { paymentMethod },
                selectedFilters: { selectedPeriod },
                promocode: { promocode },
            } = props;

            const { billingPaymentMethod } = getBillingPaymentMethod({
                statePaymentMethod: paymentMethod,
                subscriptionType,
            });

            return {
                variables: {
                    promocode,
                    period: selectedPeriod,
                    payment_method: billingPaymentMethod,
                },
            };
        },
    }),
    withAuthContext,
)(Component);

export default connect;
