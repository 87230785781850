import React from 'react';

import Dialog from './Dialog';
import { DialogResponsive, DialogProps } from './DialogResponsive/DialogResponsive';
import { DialogAnimationWrapper } from './DialogAnimationWrapper';

// UNION TYPE FOR FUTURE CUSTOM DIALOGS
type DialogModificators = 'profile_notifications' | '' | ''


interface DialogAnimatedProps extends DialogProps {
    isOpen: boolean,
    responisve?: boolean,
    buttonsReversed?: boolean,
    modeList?: DialogModificators
}


export function DialogAnimated({
    isOpen,
    responisve = false,
    notifyOnly = false,
    buttonsReversed = false,
    onReject,
    onConfirm,
    ...restProps
}: DialogAnimatedProps) {
    const rejectHandler = notifyOnly ? onConfirm : onReject;

    return (
        <DialogAnimationWrapper
            isOpen={isOpen}
            onReject={rejectHandler}
        >
            {responisve ? (
                <DialogResponsive
                    notifyOnly={notifyOnly}
                    onConfirm={onConfirm}
                    onReject={onReject}
                    buttonsReversed={buttonsReversed}
                    {...restProps}
                />
            ) : (
                // @ts-ignore
                <Dialog
                    notifyOnly={notifyOnly}
                    onConfirm={onConfirm}
                    onReject={onReject}
                    {...restProps}
                />
            )}
        </DialogAnimationWrapper>
    );
}
