import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UIHeading } from 'app/components/ui';

import './mobile-filter-item.scss';


const FilterItem = (props) => {
    const {
        title,
        text,
        className,
        children,
    } = props;

    const classes = `filter-container ${className}`;

    const titleClasses = classNames({
        'filter-title': true,
        'small-offset': className !== 'period-container', // для уменьшения отсутпа от заголовка до списка тегов
    });

    return (
        <div styleName={classes}>
            {title && (
                <UIHeading level="3.2" styleName={titleClasses}>
                    {title}
                </UIHeading>
            )}

            <div styleName="filter-content">
                {children}
            </div>

            {text && (
                <div styleName="filter-text">
                    {text}
                </div>
            )}
        </div>
    );
};

export default FilterItem;

FilterItem.propTypes = {
    title: PropTypes.string,
    text: PropTypes.shape({}),
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
};

FilterItem.defaultProps = {
    title: null,
    text: <></>,
    className: null,
};
