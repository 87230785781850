import queryString from 'query-string';
import { errorService } from 'global/services';

export const SUBSCRIPTION_TYPES = {
    singlePay: 'singlePay',
    subscription: 'subscription',
};

export const DEFAULT_SUBSCRIPTION_TYPE = SUBSCRIPTION_TYPES.subscription;

export const isSubscription = (subscriptionType) => SUBSCRIPTION_TYPES.subscription === subscriptionType;

export const getInitilaSubsctiptionType = () => {
    try {
        // const { href } = window.location;
        // const { payment } = queryString.parse(href);

        const urlParams = new URLSearchParams(window.location.search);
        const payment = urlParams.get('payment');

        switch (payment) {
            case 'online':
                return SUBSCRIPTION_TYPES.singlePay;
            case 'cash':
                return SUBSCRIPTION_TYPES.singlePay;
            // FRNT-3237 Deprecated
            // case 'foodcard':
            //     return SUBSCRIPTION_TYPES.singlePay;
            default:
                return SUBSCRIPTION_TYPES.subscription;
        }
    } catch (error) {
        errorService.log({
            text: 'get initila SubsctiptionType error',
            source: 'client',
            error,
        });
        return SUBSCRIPTION_TYPES.subscription;
    }
};
