/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { func, string, bool } from 'prop-types';
import { UIInput } from 'app/components/ui';
import './stepPayEmailSection.scss';


const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

const getEmailInputUIState = (text) => {
    if (text.length === 0) {
        return 'default';
    }

    if (EMAIL_REGEXP.test(text)) {
        return 'confirmed';
    }

    return 'error';
};

const StepPayEmailSection = (props) => {
    const [emailCheckInputUiState, setEmailCheckInputUiState] = useState('default');

    const {
        handleBlurEmail,
        handleChangeEmail,
        email,
        emailValid,
    } = props;

    useEffect(() => {
        const normalizedText = email || '';

        const nextUiState = getEmailInputUIState(normalizedText);
        setEmailCheckInputUiState(nextUiState);
    }, []);


    const handleChangeEmailCHeck = useCallback((value) => {
        handleChangeEmail({ target: { value } });
    }, [handleChangeEmail]);

    const handleBrulEmailCheck = useCallback((e) => {
        const nextUiState = getEmailInputUIState(e.target.value);

        handleBlurEmail();
        setEmailCheckInputUiState(nextUiState);
    }, [emailValid, handleBlurEmail]);

    const label = emailCheckInputUiState === 'error' ? 'Некорректный email' : 'Email';

    return (
        <div styleName="email-check-root">
            <div styleName="email-check-container">
                <div styleName="email-check-input">
                    <UIInput
                        buttonType="nohandler"
                        label={label}
                        onBlurHandler={handleBrulEmailCheck}
                        onChangeHandler={handleChangeEmailCHeck}
                        inputValue={email || ''}
                        uiState={emailCheckInputUiState}
                    />
                </div>
                <div styleName="email-check-title">
                    Чек мы пришлём на этот email
                </div>
            </div>
        </div>
    );
};

StepPayEmailSection.propTypes = {
    handleBlurEmail: func.isRequired,
    handleChangeEmail: func.isRequired,
    email: string,
    emailValid: bool,
};

StepPayEmailSection.defaultProps = {
    email: '',
    emailValid: true,
};

export default React.memo(StepPayEmailSection);
