import React, {
    useCallback, useState, useContext, useMemo, useEffect,
} from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { analyticService } from 'global/services';
import { LocaleContext } from 'app/containers/LocaleProvider';

import { useQuery } from '@apollo/client';
import USER_QUERY from 'app/graphql/network/auth/userQuery';
import { getConfirmCustomizationLocales } from 'app/views/Basket/components/BasketCustomizationTags/basket-customization-locales';
import { DialogAnimated } from 'app/components/Dialog';
import { useCustomContradictionDefine } from 'app/views/Basket/hooks/useCustomContradictionDefine';
import { StepLayout } from '../StepLayout/StepLayout';
import { SingleStepDeliveryInfoArea } from './components/SingleStepDeliveryInfoArea';
import { SingleStepCheckArea } from './components/SingleStepCheckArea';
import { StepPayButtonContent } from '../StepPayment/components/StepPayButtonContent';
import { PaperRecipeTogleRow } from './components/PaperRecipeRow';
import { CheckoutStoreContext } from '../../store/checkoutStore.context';

import { StepAuth } from '../StepAuth/StepAuth';
import { StepAddress } from '../StepAddress/StepAddress';
import { StepDatetime } from '../StepDatetime/StepDatetime';
import { StepPayment } from '../StepPayment/StepPayment';

import {
    STEP_PHONE,
    STEP_ADDRESS,
    STEP_DATETIME,
    STEP_PAYMENT,
    RENDER_CONTEXT_SINGLE_STEP,
    EVENTS,
} from '../stepByStepCheckoutConst';
import { useAuthState } from '../stepByStepCheckoutHooks';
import { locales } from './single-step-checkout-locales';

import '../StepByStepCheckout/step-by-step-checkout.scss';

const STEPS_COMPONENTS = {
    [STEP_PHONE]: StepAuth,
    [STEP_ADDRESS]: StepAddress,
    [STEP_DATETIME]: StepDatetime,
    [STEP_PAYMENT]: StepPayment,
};


const EDIT_OPEN_TIMEOUT = 600;


const useEditingStepState = () => {
    const [editingStep, setEditingStep] = useState(null);
    const [isStepEditing, setIsStepEditing] = useState(false);

    const handleOpenStep = useCallback((step) => {
        setEditingStep(step);
        setIsStepEditing(true);
    }, []);

    const handleCloseStep = useCallback(() => {
        setIsStepEditing(false);
        setTimeout(() => {
            setEditingStep(null);
        }, EDIT_OPEN_TIMEOUT);
    }, []);

    return {
        editingStep,
        isStepEditing,
        handleOpenStep,
        handleCloseStep,
    };
};

export function SingleStepCheckout(props) {
    const {
        closeCheckout,
        [STEP_PAYMENT]: paymentProps,
        togglePaperRecipeSwitch,
        setIsAddressChanged,
        isAddressPopupNeeded,
        handleOpenAddressInfoPopup,
        setIsCustomizationIgnored,
    } = props;

    const { dispatch } = useContext(CheckoutStoreContext);

    const userQuery = useQuery(USER_QUERY, {
        onCompleted: () => {
            setIsAddressChanged(true);
        },
        fetchPolicy: 'cache-first',
        context: {
            step: 'checkout:refetch:SingleStepCheckout',
        },
    });

    const history = useHistory();
    const [isCustomizationDialogClosed, setIsCustomizationDialogClosed] = useState(false);

    const {
        isCustomizationDialogShown,
        setIsCustomizationDialogShown,
        dialogIngridient,
    } = useCustomContradictionDefine({ userQuery, cart: paymentProps.cart });


    const [isAddressPopupShown, setIsAddressPopupShown] = useState(false);

    const {
        cart,
        paymentMethod,
        handlePay,
    } = paymentProps;

    const isTimeIntervalsDataFetched = useMemo(() => {
        const {
            [STEP_DATETIME]: {
                timeIntervals,
                selectDay,
            },
        } = props;

        const hasData = timeIntervals?.length > 0 && Boolean(selectDay);

        return hasData;
    }, [props]);

    const { locale } = useContext(LocaleContext);
    const l = locales[locale];

    const [isNextStepLoading, setIsNextStepLoading] = useState(false);

    const {
        editingStep,
        isStepEditing,
        handleOpenStep,
        handleCloseStep,
    } = useEditingStepState();

    const [authState, setAuthState] = useAuthState(userQuery);

    /* HANDLERS  */

    const handleCloseAllSteps = useCallback(() => {
        analyticService.push({ eventName: 'Checkout_Close_Onepager' });
        closeCheckout();
    }, [closeCheckout]);

    const handleSubmit = useCallback(() => {
        handlePay();
        setIsNextStepLoading(true);
    }, [handlePay]);

    const handleCloseStepWithPushEvent = useCallback(() => {
        analyticService.push({ eventName: EVENTS[editingStep].backFromEditing });
        handleCloseStep();
    }, [editingStep, handleCloseStep]);

    /* */

    const Component = STEPS_COMPONENTS[editingStep];
    const stepProps = props[editingStep]; // eslint-disable-line

    const childProps = {
        // common props
        renderContext: RENDER_CONTEXT_SINGLE_STEP,
        handleOpenNextStep: handleCloseStep,
        handleCloseCurrentStep: handleCloseStepWithPushEvent,
        isNextStepButtonVisible: true,
        headerTitle: l[editingStep],

        ...stepProps,

        // authStepProps
        authState,
        setAuthState,
        handleUserEnteredCode: () => { },
        switchToSingleStepCheckout: handleCloseStep,

        // PaymentStepProps
        nextStepButtonType: 'regular',
    };

    const layoutContainerClasses = cn({
        'single-step__container': true,
        'is-step-editing': isStepEditing,
    });

    useEffect(() => {
        if (isAddressPopupShown) return;
        if (isAddressPopupNeeded) {
            handleOpenAddressInfoPopup();
            handleOpenStep(STEP_ADDRESS);
            setIsAddressPopupShown(true);
            // DID MOUNT
        }
    }, [isAddressPopupNeeded, isAddressPopupShown, handleOpenAddressInfoPopup, handleOpenStep]);

    if (userQuery.loading) {
        return null;
    }

    const dialogTexts = dialogIngridient
        ? getConfirmCustomizationLocales(dialogIngridient[locale])[locale].dialog.success
        : null;

    return (
        <>
            <div styleName={layoutContainerClasses}>
                <StepLayout
                    fillContent={false}
                    isNextStepButtonDisabled={!isTimeIntervalsDataFetched || isNextStepLoading}
                    isNextStepButtonVisible
                    isNextStepLoading={isNextStepLoading}
                    title={l.headerTitle}
                    currentStepNumber={2}
                    handleCloseCurrentStep={handleCloseAllSteps}
                    handleOpenNextStep={handleSubmit}
                    nextStepButtonContent={(
                        <StepPayButtonContent
                            cart={cart}
                            paymentMethod={paymentMethod}
                        />
                    )}
                >
                    <SingleStepDeliveryInfoArea
                        {...props}
                        handleOpenStep={handleOpenStep}
                    />
                    <PaperRecipeTogleRow
                        typeOfSet={cart.typeOfSet}
                        togglePaperRecipeSwitch={togglePaperRecipeSwitch}
                    />
                    <SingleStepCheckArea
                        locale={locale}
                        paymentProps={paymentProps}
                    />
                </StepLayout>
            </div>

            <Transition
                in={isStepEditing}
                timeout={EDIT_OPEN_TIMEOUT}
            >
                {(transitionState) => {
                    const containerClasses = cn({
                        'step-by-step__transition': true,
                        [transitionState]: true,
                    });
                    return (
                        <div styleName={containerClasses}>
                            {transitionState !== 'exited' && Component && (
                                <Component
                                    {...childProps}
                                    transitionState={transitionState}
                                />
                            )}
                        </div>
                    );
                }}
            </Transition>

            {dialogTexts && !isCustomizationDialogClosed && (
                <Portal>
                    <DialogAnimated
                        {...dialogTexts}
                        isOpen={Boolean(isCustomizationDialogShown)}
                        onConfirm={() => {
                            console.log('confirm');
                            setIsCustomizationIgnored(true);
                            setIsCustomizationDialogShown(false);
                            setIsCustomizationDialogClosed(true);
                            dispatch({
                                type: 'SET_IGNORE_DATES_FILTER',
                                payload: { isNeedToIgnoreDatesFilter: true },
                            });
                        }}
                        onReject={() => {
                            console.log('reject');
                            setIsCustomizationIgnored(false);
                            setIsCustomizationDialogShown(false);
                            setIsCustomizationDialogClosed(true);
                            history.push('/basket');
                        }}
                    />
                </Portal>
            )}
        </>
    );
}

SingleStepCheckout.propTypes = {
    // userQuery: PropTypes.shape({}).isRequired,
    closeCheckout: PropTypes.func.isRequired,
    togglePaperRecipeSwitch: PropTypes.func.isRequired,
};
