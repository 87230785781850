import React from 'react';
import { func } from 'prop-types';
import { UIButton } from 'app/components/ui';

import './addMoreDishes.scss';

const AddMoreDishesButton = (props) => {
    const { handleAddMoreButtonClick } = props;

    return (
        <div styleName="add-more-button-root">
            <div styleName="add-more-button-wrapper">
                <UIButton
                    centerContent="Добавить блюда"
                    onClick={() => handleAddMoreButtonClick('3')}
                    aria-label="Получить код"
                    sizeType="large"
                    colorType="secondary"
                />
            </div>
        </div>
    );
};

AddMoreDishesButton.propTypes = {
    handleAddMoreButtonClick: func.isRequired,
};

export default React.memo(AddMoreDishesButton);
