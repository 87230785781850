import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Arrow from './Arrow.component.svg';

import '../input/uiinput.scss';


const colorsTypes = {
    light: 'colors-light',
    medium: 'colors-medium',
    dark: 'colors-dark',
};


export default function UISelect(props) {
    const {
        colorsType: colorsTypeProp,
        uiState,
        title,
        label,
        value,
        heightType,
        disabled,
        onClick,
    } = props;

    const colorsType = colorsTypes[colorsTypeProp];

    const [trasitionState, setTrasitionState] = useState('trasition-default');

    useEffect(() => {
        if (trasitionState === 'trasition-default' && value) {
            setTrasitionState('trasition-active');
        }
    }, [value, trasitionState]);


    const text = value || title;


    const selectContainer = cn({
        'ui-input-container': true,
        [uiState]: true,
        [heightType]: true,
        [colorsType]: true,
        disabled,
    });

    const selectContent = cn({
        'ui-input-content': true,
        [trasitionState]: true,
        [heightType]: true,
    });

    const inputLabel = cn({
        'ui-input-label': true,
        [trasitionState]: true,
        [uiState]: true,
        [heightType]: true,
        empty: !label,
    });

    const inputItem = cn({
        'ui-input-item': true,
        [trasitionState]: true,
        [heightType]: true,
        [colorsType]: true,
        disabled,
    });

    const buttonType = 'arrow-select';
    const inputButton = cn({
        'input-button-container': true,
        [uiState]: true,
        [buttonType]: true,
        [heightType]: true,
    });

    const inputIcon = cn({
        'input-icon': true,
        [uiState]: true,
        [heightType]: true,
    });

    const iconSvgClasses = cn({
        'input-icon-svg': true,
        [colorsType]: true,
        [uiState]: true,
        disabled,
    });

    return (
        <button
            type="button"
            onClick={onClick}
            styleName={selectContainer}
            disabled={disabled}
        >
            <div styleName={selectContent}>
                <div
                    styleName={inputLabel}
                >
                    {label}
                </div>
                <div styleName={inputItem}>
                    {text}
                </div>
            </div>

            <div styleName={inputButton}>
                <div styleName={inputIcon}>
                    <Arrow styleName={iconSvgClasses} />
                </div>
            </div>
        </button>
    );
}

UISelect.propTypes = {
    colorsType: PropTypes.oneOf(['light', 'medium', 'dark']),
    uiState: PropTypes.oneOf(['default', 'active', 'validation', 'error', 'confirmed']),
    label: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    heightType: PropTypes.oneOf(['large', 'medium', 'small']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

UISelect.defaultProps = {
    colorsType: 'dark', // Жанна Дарк
    uiState: 'default',
    label: '',
    title: '',
    value: '',
    heightType: 'large',
    disabled: false,
};
