import {
    period as nyPeriodStart,
    extraPeriodCustomStart,
    extraPeriodCustomEnd,
    extraPeriodNYStart,
    extraPeriodNYEnd,
} from 'global/const/ny';

import { NY_DISHES, NY_DESSERT } from 'app/utils/filters';
import {
    PAYMENT_PARAM_RECURRENT,
    PAYMENT_PARAM_ONLINE,
    PAYMENT_PARAM_CASH,
} from 'app/const/billing-payment-methods';


const extraPeriodCustom = {
    start: extraPeriodCustomStart,
    end: extraPeriodCustomEnd,
    excludeTags: [NY_DISHES],
    clearAfterSwitchFrom: true,
    hiddenPaymentMethods: [PAYMENT_PARAM_CASH],
    defaultPaymentMethod: PAYMENT_PARAM_ONLINE,
};

const extraPeriodNY = {
    // start: extraPeriodNYStart,
    start: extraPeriodCustomStart,
    end: extraPeriodNYEnd,
    includeTagsOnly: [NY_DISHES, NY_DESSERT],
    clearAfterSwitchFrom: true,
    hiddenPaymentMethods: [PAYMENT_PARAM_RECURRENT, PAYMENT_PARAM_CASH],
    defaultPaymentMethod: PAYMENT_PARAM_ONLINE,
    title: {
        ru: 'На новый год',
        en: 'New Year',
    },
};


export const patchQueryPeriods = (periods) => {
    const nyPeriod = periods.find((p) => p.start === nyPeriodStart);
    const restPeriods = periods.filter((p) => p.start !== nyPeriodStart);

    if (!nyPeriod) return periods;

    const patchedPeriods = [
        ...restPeriods,
        // {
        //     ...extraPeriodCustom,
        //     tags: nyPeriod.tags,
        //     categories: nyPeriod.categories,
        //     isVisibleInFilter: nyPeriod.isVisibleInFilter,
        // },
        {
            ...extraPeriodNY,
            tags: nyPeriod.tags,
            categories: nyPeriod.categories,
            isVisibleInFilter: nyPeriod.isVisibleInFilter,
        },
    ].sort((p1, p2) => {
        const d1 = new Date(p1.start);
        const d2 = new Date(p2.start);
        return d1 - d2;
    });

    return patchedPeriods;
};
