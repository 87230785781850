import React, {
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';

import { analyticService } from 'global/services';
// import { period as nyPeriod } from 'global/const/ny';

import withAvailableDeliveryDates from 'app/containers/withAvailableDeliveryDates';
import { LocaleContext } from 'app/containers/LocaleProvider';

import { formatPeriodMobile, formatDateToISO } from 'app/utils/date';

import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { MenuDispatchContext, TOP } from 'app/containers/contexts/menu.context';
import { OverlayContextDispatch, OVERLAY_ANIMATION_TIME } from 'app/containers/contexts/overlay.context';
import {
    ONE_DAYS_IN_UTC_FORMAT,
    DIFFS,
} from 'app/containers/contexts/menuDates.consts';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';


const locales = {
    ru: { delivery: 'Дата доставки' },
    en: { delivery: 'Delivery date' },
};


const empty = [];


function MobilePeriodSelectContainer({
    isSelectOpened,
    onClickClose,
    onClickPeriod,
    deliveryDates = empty,
    deliveryDatesEE = empty,
}) {
    const { pushSelectMobileOverlay, closeLastOverlays } = useContext(OverlayContextDispatch);
    const { filterQuery: { menuFilter: { periods } } } = useContext(FiltersContext);

    const { selectedPeriod } = useSelectedFilters();

    const {
        state: menuDatesContext,
        onSelectDate,
    } = useContext(menuDatesState);
    const { locale } = useContext(LocaleContext);
    const menuDispatch = useContext(MenuDispatchContext);

    const handleClose = useCallback(() => {
        closeLastOverlays();
        onClickClose();
    }, [closeLastOverlays, onClickClose]);


    const handleClickDeliveryDate = useCallback((e) => {
        closeLastOverlays();
        const dayDiff = e.currentTarget.name;

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'day',
            action: 'Button',
            index: DIFFS.indexOf(dayDiff),
        });

        menuDispatch({
            type: 'scrollTo',
            payload: { target: TOP },
        });

        setTimeout(() => {
            handleClose();
            onSelectDate(dayDiff, menuDatesContext.filterPeriod);
            onClickPeriod(menuDatesContext.filterPeriod);
        }, OVERLAY_ANIMATION_TIME);
    }, [closeLastOverlays, menuDispatch, menuDatesContext, onSelectDate, onClickPeriod, handleClose]);

    const handleClickPeriod = useCallback(async (periodStart) => {
        closeLastOverlays();
        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'period',
            action: 'Button',
            periodIndex: periods.findIndex((p) => p.start === periodStart),
        });

        menuDispatch({
            type: 'scrollTo',
            payload: { target: TOP },
        });

        setTimeout(() => {
            handleClose();

            const isCurrentPeriod = menuDatesContext.filterPeriod === periodStart;
            const [firstEEdate] = deliveryDatesEE;
            const firstEEdateISO = firstEEdate && formatDateToISO(firstEEdate.dateObj);

            const dateForSelect = isCurrentPeriod ? firstEEdateISO : periodStart;

            const epochPeriodStart = new Date(dateForSelect).getTime() / 1000;
            const dayDiff = epochPeriodStart - (menuDatesContext.currentDate / 1000);

            onSelectDate(dayDiff, dateForSelect);
            onClickPeriod(periodStart);
        }, OVERLAY_ANIMATION_TIME);
    }, [
        deliveryDatesEE, menuDatesContext.currentDate, menuDatesContext.filterPeriod, periods,
        closeLastOverlays, handleClose, menuDispatch, onClickPeriod, onSelectDate,
    ]);

    const daysPeriodSection = useMemo(() => {
        const daysPeriodItems = deliveryDates
            .filter((dd) => {
                const { date, month } = dd;
                const hiddenDecemberDates = [29, 30, 31];

                if (month === 'декабря' && hiddenDecemberDates.includes(date)) {
                    return false;
                }

                return true;
            })
            .map((dd) => {
                const {
                    xValue,
                    day,
                    date,
                    month,
                } = dd;

                const isPeriodWithDaysSelected = selectedPeriod === menuDatesContext.filterPeriod;
                const isSelectedDay = xValue === menuDatesContext.dateFilterStatus;
                const selected = isPeriodWithDaysSelected && isSelectedDay;

                const dataTestId = xValue === ONE_DAYS_IN_UTC_FORMAT
                    ? 'tomorrow__mobile'
                    : 'x+day__mobile';

                return {
                    id: xValue,
                    selected,
                    buttonProps: {
                        onClick: handleClickDeliveryDate,
                        name: xValue,
                        'data-test-id': dataTestId,
                    },
                    TextCmp: (
                        <>
                            <strong>
                                {day}
                                .
                            </strong>

                            <span className="num">{date}</span>
                            &nbsp;
                            {month}
                        </>
                    ),
                };
            });

        return {
            id: 'daysPeriodSection',
            items: daysPeriodItems,
        };
    }, [
        deliveryDates,
        handleClickDeliveryDate,
        menuDatesContext.dateFilterStatus,
        menuDatesContext.filterPeriod,
        selectedPeriod,
    ]);

    const weekPeriodsSection = useMemo(() => {
        const visibleFuturePeriods = periods
            .filter(({ isVisibleInFilter }) => isVisibleInFilter === 'visible')
            .filter((p) => p.start !== menuDatesContext.filterPeriod);

        const weekPeriodsItems = visibleFuturePeriods.map((period) => {
            const { start, end } = formatPeriodMobile(period, selectedPeriod);

            const isPeriodWithDaysSelected = period.start === menuDatesContext.selectedFilterPeriod;
            const selected = isPeriodWithDaysSelected;

            // if (period.start === nyPeriod) {
            //     return {
            //         id: period.end,
            //         selected,
            //         buttonProps: {
            //             onClick: () => {
            //                 window.location.href = '/blyuda-dlya-novogodnego-stola/';
            //             },
            //             'data-test-id': 'period__mobile',
            //         },
            //         TextCmp: 'На новый год',
            //     };
            // }

            return {
                id: period.end,
                selected,
                buttonProps: {
                    onClick: (e) => handleClickPeriod(period.start, e),
                    'data-test-id': 'period__mobile',
                },
                TextCmp: period.title
                    ? period.title[locale]
                    : (
                        <>
                            <span className="num">{start.day}</span>
                            &nbsp;
                            {start.month}
                            &nbsp;-&nbsp;
                            <span className="num">{end.day}</span>
                            &nbsp;
                            {end.month}
                        </>
                    ),
            };
        }).filter((i) => Boolean(i));

        return {
            id: 'weekPeriodsSection',
            items: weekPeriodsItems,
        };
    }, [
        locale, handleClickPeriod, selectedPeriod, periods, menuDatesContext.selectedFilterPeriod,
        menuDatesContext.filterPeriod,
    ]);

    useEffect(() => {
        if (isSelectOpened) {
            const selectData = {
                headerText: locales[locale].delivery,
                sections: [daysPeriodSection, weekPeriodsSection],
                onClose: handleClose,
            };

            pushSelectMobileOverlay('mobile_pediod_select', selectData, { onClick: handleClose });
        }
    }, [
        isSelectOpened,
        locale,
        daysPeriodSection,
        weekPeriodsSection,
        pushSelectMobileOverlay,
        closeLastOverlays,
        handleClose,
    ]);

    return null;
}


export default compose(withAvailableDeliveryDates)(MobilePeriodSelectContainer);

MobilePeriodSelectContainer.propTypes = {
    isSelectOpened: PropTypes.bool.isRequired,
    onClickClose: PropTypes.func.isRequired,
    onClickPeriod: PropTypes.func.isRequired,
    deliveryDates: PropTypes.arrayOf(PropTypes.shape({})),
    deliveryDatesEE: PropTypes.arrayOf(PropTypes.shape({})),
};
