import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import formatPrice from 'app/utils/price';

import BaksetIcon2 from 'assets/svg/basket2.svg';
import ArrowRightIcon from 'assets/svg/arrow_right2.svg';

import './basket-button.scss';


export default class BasketButton extends React.PureComponent {
    render() {
        const {
            locale,
            title, price, count, hidden, className, icon, isDelivery,
            onClick,
        } = this.props;

        const handleClick = () => {
            if (hidden) return;
            onClick();
        };

        const isBasketEmpty = count === 0;

        // change-1, change-2 for animation on change count
        const buttonClasses = classNames({
            basketmini: true,
            'change-1': count % 2 === 0,
            'change-2': count % 2 !== 0,
            'is-hidden': hidden,
        });

        const iconClasses = classNames({
            basketmini__icon: true,
            empty: isBasketEmpty,
            'change-1': count % 2 === 0,
            'change-2': count % 2 !== 0,
        });
        const titleClasses = classNames({
            basketmini__title: true,
            delivery: isDelivery,
        });

        return (
            <button
                type="button"
                aria-label="Открыть корзину"
                styleName={buttonClasses}
                className={className}
                onClick={handleClick}
                data-test-id="basketmini"
            >
                <div styleName="basketmini--wrap">
                    <div styleName={titleClasses}>{title}</div>
                    <div>
                        {locale === 'en' && (
                            <>
                                <span styleName="basketmini__currency" />
                                &nbsp;
                            </>
                        )}
                        <span styleName="basketmini__price">{formatPrice(price)}</span>
                        {locale === 'ru' && <span styleName="basketmini__currency" />}
                    </div>
                </div>
                <div styleName={iconClasses} data-animation="navigation-basket">
                    {icon === 'basket' && (
                        <>
                            <div styleName="basketmini__basket-icon">
                                <BaksetIcon2 />
                            </div>
                            <div styleName="basketmini__count">{count}</div>
                        </>
                    )}
                    {icon === 'arrow' && (
                        <div styleName="basketmini__arrow-icon">
                            <ArrowRightIcon />
                        </div>
                    )}
                </div>
            </button>
        );
    }
}

BasketButton.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    price: PropTypes.number,
    count: PropTypes.number,
    hidden: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string,
    isDelivery: PropTypes.bool,
};


BasketButton.defaultProps = {
    onClick: () => {},
    title: 'Общая стоимость:',
    price: 0,
    count: 0,
    hidden: false,
    className: '',
    icon: 'basket',
    isDelivery: false,
};
