import React from 'react';
import PropTypes from 'prop-types';

import './separator.scss';


const Separator = ({ children }) => (
    <div styleName="separator__root">
        <div styleName="separator__line" />
        <div styleName="separator__text-container">
            <div styleName="separator__text">
                {children}
            </div>
        </div>
    </div>
);

export default Separator;


Separator.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

Separator.defaultProps = {
    children: null,
};
