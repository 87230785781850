/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import {
    shape, func, string, bool,
} from 'prop-types';

import { analyticService } from 'global/services';
import { cartType, cartInitialType } from 'types';

import { SUBSCRIPTION_TYPES } from 'app/const/subscription';
import { UIRadioIcon } from 'app/components/ui';

import { CheckPriceLine } from './components';
import './stepPayPaymentMethodsSectoin.scss';

const StepPayPaymentMethodsSection = (props) => {
    const {
        handleSelectPayment,
        paymentMethod,
        totals,
        hideFoodcard,
        hideOnlinePayment,
        hideCashPayment,
        subscriptionTypeQuery: {
            subscriptionType,
        },
    } = props;

    /* STATE */

    const isSubSelected = useMemo(() => subscriptionType === 'subscription', [subscriptionType]);

    const onLinePaymentPrice = useMemo(() => {
        const { static_common_price, static_discount_price } = totals;
        return isSubSelected ? static_discount_price : static_common_price;
    }, [isSubSelected, totals]);

    /* HANDLERS */

    const selectPaymentMethodHandler = useCallback((buttonName) => {
        if (paymentMethod === buttonName) return;
        handleSelectPayment(buttonName, SUBSCRIPTION_TYPES.singlePay);
        analyticService.push({
            eventName: 'Checkout_Payment_Radiobuttons',
            button_name: buttonName,
        });
    }, [handleSelectPayment, paymentMethod]);

    /* STYLES */

    const rootWrapperCash = cn({
        'payment-method-button': true,
        [subscriptionType]: true,
    });
    const rootWrapperFoodcard = cn({
        'payment-method-button': true,
        [subscriptionType]: true,
    });
    const rootWrapperOnline = cn({
        'payment-method-button': true,

    });

    return (
        <div styleName="payment-method-root">
            <div styleName="payment-method-buttons">
                {/* ROW 1 */}
                {!hideCashPayment && (
                    <button
                        disabled={isSubSelected}
                        styleName={rootWrapperCash}
                        type="button"
                        onClick={() => { selectPaymentMethodHandler('cash'); }}
                    >
                        <div styleName="payment-method-button-left">
                            <UIRadioIcon checked={paymentMethod === 'cash'} />
                            <div styleName="payment-method-button__text">
                                Наличными курьеру

                            </div>
                        </div>
                        <div styleName="payment-method-button__price payment-method-button-right">
                            <CheckPriceLine price={totals.static_common_price} />
                        </div>
                    </button>
                )}
                {/* ROW 2 */}
                {!hideOnlinePayment && (
                    <button
                        styleName={rootWrapperOnline}
                        type="button"
                        onClick={() => { selectPaymentMethodHandler('online'); }}
                    >
                        <div styleName="payment-method-button-left">
                            <UIRadioIcon checked={paymentMethod === 'online'} />
                            <div styleName="payment-method-button__text">
                                Оплатить картой
                            </div>
                        </div>
                        <div styleName="payment-method-button__price">
                            <CheckPriceLine price={onLinePaymentPrice} />
                        </div>
                    </button>
                )}
                {/* ROW 3 */}
                {/* FRNT-3237 Deprecated */}
                {/* {!hideFoodcard && (
                    <button
                        disabled={isSubSelected}
                        styleName={rootWrapperFoodcard}
                        type="button"
                        onClick={() => { selectPaymentMethodHandler('foodcard'); }}
                    >
                        <div styleName="payment-method-button-left">
                            <UIRadioIcon checked={paymentMethod === 'foodcard'} />
                            <div styleName="payment-method-button__text">
                                Картой FoodCard
                            </div>
                        </div>
                        <div styleName="payment-method-button__price">
                            <CheckPriceLine price={totals.static_common_price} />
                        </div>
                    </button>
                )} */}
            </div>
        </div>
    );
};

StepPayPaymentMethodsSection.propTypes = {
    totals: cartType.totals,
    paymentMethod: string,
    hideFoodcard: bool.isRequired,
    hideOnlinePayment: bool.isRequired,
    hideCashPayment: bool.isRequired,
    handleSelectPayment: func.isRequired,
    subscriptionTypeQuery: shape({
        subscriptionType: string.isRequired,
    }).isRequired,
};
StepPayPaymentMethodsSection.defaultProps = {
    totals: cartInitialType.totals,
    paymentMethod: '',
};


export default React.memo(StepPayPaymentMethodsSection);
